import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabList = ({ children, className, ...props }) => {
  const tabListClassNames = classNames('tabs__tab-list', {
    [`${className}`]: className,
  });

  return (
    <ul role="tablist" className={tabListClassNames} {...props}>
      {children}
    </ul>
  );
};

TabList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TabList;
