import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';

export async function getInsuranceByClientId(clientId) {
  const url = interpolate(config.endpoints.insuranceMapping, { clientId });

  const { data } = await http.get(url);

  return data;
}

export async function getInsuranceCategories() {
  const url = config.endpoints.insuranceCategories;

  const { data } = await http.get(url);

  return data;
}

export async function updateInsuranceData(clientId, updatedData) {
  const url = interpolate(config.endpoints.insuranceMapping, { clientId });

  const { data } = await http.put(url, {
    client_id: clientId,
    updates: updatedData,
  });

  return data;
}

export async function downloadReports(clientId) {
  const url = interpolate(config.endpoints.exportInsurance, { clientId });

  const { data } = await http.get(url, {
    responseType: 'blob',
  });

  return data;
}
