import http from 'utils/http';

import * as interceptors from './interceptors';

function initInterceptors() {
  http.interceptors.request.use(interceptors.authorizationInterceptor);
  http.interceptors.response.use((response) => response);
}

export default function init() {
  initInterceptors();
}
