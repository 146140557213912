import { refresh, logOut } from 'services/auth';
import * as tokenService from 'services/token';
import * as storageService from 'utils/storage';

import { LOGIN } from 'constants/routes';

import { USERNAME, PERMISSIONS, ROLE, USER_ID } from 'constants/storage';
import parseJwt from 'utils/jwt';
import config from './config';

const AUTHORIZATION_HEADER = 'Authorization';
const SESSION_EXPIRED_MESSAGE = 'Session Expired';

const UNAUTHORIZED_STATUS_CODE = 401;

const INTERCEPTOR_EXCLUDED_URLS = [
  config.endpoints.refresh,
  config.endpoints.login,
];

/**
 * Build authorization header
 *
 * @param {string} accessToken
 * @returns {string}
 */
function getAuthorizationHeader(accessToken) {
  return `Bearer ${accessToken}`;
}

/**
 * Interceptor to add Access Token header for all requests.
 *
 * @param {Request} request
 * @returns {Request}
 */
export async function authorizationInterceptor(request) {
  const accessToken = tokenService.getAccessToken();

  if (
    accessToken &&
    !request.headers[AUTHORIZATION_HEADER] &&
    !INTERCEPTOR_EXCLUDED_URLS.includes(request.url)
  ) {
    const { exp: accessTokenExp } = parseJwt(accessToken);
    const isAccessTokenExpired = tokenService.isTokenExpired(accessTokenExp);

    if (isAccessTokenExpired) {
      const refreshToken = tokenService.getRefreshToken();
      const { exp: refreshTokenExp } = parseJwt(refreshToken);

      const isRefreshTokenExpired =
        tokenService.isTokenExpired(refreshTokenExp);

      if (isRefreshTokenExpired) {
        // Add an event listener for the beforeunload event
        window.addEventListener('beforeunload', logOut);

        // Redirect to the login page
        // The setTimeout is used to ensure that the event listener
        // is properly set up before the page is redirected.
        setTimeout(() => {
          window.location.replace(LOGIN);
        }, 100);

        // Create an error object with a response property
        const error = new Error(SESSION_EXPIRED_MESSAGE);
        error.response = {
          status: UNAUTHORIZED_STATUS_CODE,
          data: {
            detail: SESSION_EXPIRED_MESSAGE,
          },
        };

        // Reject the promise with the error object
        return Promise.reject(error);
      }

      const { data } = await refresh(refreshToken);

      const access_token = data.access;
      tokenService.setAccessToken(access_token);
      const { role, permissions, user_id } = parseJwt(access_token);
      storageService.set(USERNAME, data.username);
      storageService.set(PERMISSIONS, permissions || []);
      storageService.set(ROLE, role || '');
      storageService.set(USER_ID, user_id);
    }
    request.headers[AUTHORIZATION_HEADER] = getAuthorizationHeader(
      tokenService.getAccessToken()
    );
  }

  return request;
}
