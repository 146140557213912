import * as storage from 'utils/storage';

import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/storage';

/**
 * Persist token to storage.
 *
 * @param {{accessToken, refresToken}} params
 */
export function persist({ accessToken, refreshToken }) {
  storage.set(ACCESS_TOKEN, accessToken);
  storage.set(REFRESH_TOKEN, refreshToken);
}

/**
 * Get access token from storage.
 *
 * @returns {string}
 */
export function getAccessToken() {
  return storage.get(ACCESS_TOKEN);
}

/**
 * Set access token to storage.
 *
 * @param {string} accessToken
 */
export function setAccessToken(accessToken) {
  storage.set(ACCESS_TOKEN, accessToken);
}

/**
 * Set access token and refresh Token to storage.
 *
 * @param {string} accessToken
 * @param {string} refreshToken
 */
export function setTokens(accessToken, refreshToken) {
  storage.set(ACCESS_TOKEN, accessToken);
  storage.set(REFRESH_TOKEN, refreshToken);
}

/**
 * Get refresh token from storage.
 *
 * @returns {string}
 */
export function getRefreshToken() {
  return storage.get(REFRESH_TOKEN);
}

/**
 * Clear tokens.
 */
export function clear() {
  storage.remove(ACCESS_TOKEN);
  storage.remove(REFRESH_TOKEN);
}

/**
 * Function to check if the token is expired
 * @param {number} expiresIn token expiration time in seconds since epoch
 * @returns {boolean} true if the token is expired, false otherwise
 */
export const isTokenExpired = (expiresIn) => {
  return Date.now() >= expiresIn * 1000;
};
