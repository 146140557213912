import * as Yup from 'yup';

export const createUserSchema = Yup.object().shape({
  uname: Yup.string().required('Username is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  fname: Yup.string().required('First name is required'),
  lname: Yup.string().required('Last name is required'),
  manager_id: Yup.number().nullable(),
  role_id: Yup.number().integer().positive().required('Role ID is required'),
  client_ids: Yup.array().of(Yup.number().required()).optional(),
});

export const updateUserSchema = Yup.object().shape({
  uname: Yup.string().optional(),
  email: Yup.string().email('Invalid email format').optional(),
  password: Yup.string().optional(),
  confirmPassword: Yup.string()
    .when('password', {
      is: (password) => password && password.length > 0,
      then: (schema) =>
        schema
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password is required'),
      otherwise: (schema) => schema.optional(),
    })
    .optional(),
  fname: Yup.string().optional(),
  lname: Yup.string().optional(),
  manager_id: Yup.number().nullable().optional(),
  role_id: Yup.number().integer().positive().optional(),
  client_ids: Yup.array().of(Yup.number()).optional(),
});
