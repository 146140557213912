import React from 'react';

import { createRoot } from 'react-dom/client';

class CustomEditModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <button className="btn-edit text-underline">Edit</button>;
  }
}

export const editModalFormatter = (cell, formatterParams, onRendered) => {
  const container = document.createElement('div');
  container.classList.add('actions-btn');
  const root = createRoot(container);

  root.render(
    <CustomEditModal
      cell={cell}
      formatterParams={formatterParams}
      onRendered={onRendered}
    />
  );

  return container;
};

export default CustomEditModal;
