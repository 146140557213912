export const LOGOUT = 'LOGOUT';
export const UPDATE_CLIENT_ID = 'UPDATE_CLIENT_ID';
export const UPDATE_CLIENT_LIST = 'UPDATE_CLIENT_LIST';
export const UPDATE_ACCOUNT_NUMBER = 'UPDATE_ACCOUNT_NUMBER';
export const CLEAR_SELECTED_PROJECT = 'CLEAR_SELECTED_PROJECT';
export const UPDATE_SELECTED_PROJECT = 'UPDATE_SELECTED_PROJECT';
export const UPDATE_SELECTED_PROJECT_BASE_ID =
  'UPDATE_SELECTED_PROJECT_BASE_ID';
export const UPDATE_ACCOUNT_FINANCIAL_TAB_INDEX =
  'UPDATE_ACCOUNT_FINANCIAL_TAB_INDEX';

export function updateClientID(clientId) {
  return {
    type: UPDATE_CLIENT_ID,
    payload: clientId,
  };
}

export function updateClientList(clientList) {
  return {
    type: UPDATE_CLIENT_LIST,
    payload: clientList,
  };
}

export function updateAccountNumber(accountNumber) {
  return {
    type: UPDATE_ACCOUNT_NUMBER,
    payload: accountNumber,
  };
}

export function updateSelectedProject(selectedProject) {
  return {
    type: UPDATE_SELECTED_PROJECT,
    payload: selectedProject,
  };
}

export function clearSelectedProject() {
  return {
    type: CLEAR_SELECTED_PROJECT,
  };
}

export function updateSelectedProjectRuleBaseId(ruleBaseId) {
  return {
    type: UPDATE_SELECTED_PROJECT_BASE_ID,
    payload: ruleBaseId,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function updateAccountFinancialTabIndex(tabIndex) {
  return {
    type: UPDATE_ACCOUNT_FINANCIAL_TAB_INDEX,
    payload: tabIndex,
  };
}
