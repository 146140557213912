import * as Yup from 'yup';

export const workTrackerSchema = Yup.object().shape({
  major_action_id: Yup.number()
    .typeError('Major action must be a number')
    .required('Major action is required'),
  minor_action_id: Yup.number()
    .typeError('Minor action must be a number')
    .required('Minor action is required'),
  user_note: Yup.string().required('User note is required'),
  transaction_combo_id: Yup.number().nullable(),
  writeoff_amount: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Write off amount must be a valid number')
    .nullable(),
  posting_id: Yup.string().nullable(),
  post_date: Yup.date()
    .typeError('Please enter valid date')
    .required('Post date is required'),
});
