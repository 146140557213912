export const DATA_HINTS_FORM_FIELDS = {
  rule_name: 'Name',
  rule_category: 'Category',
  rule_definition: 'Data Hints Definition',
  client: 'Client',
};

export const DATA_HINTS_RULE_TYPE = {
  ALL: 'all',
  GENERIC: 'generic',
  SPECIFIC: 'specific',
};

export const DATA_HINTS_FILTER = [
  {
    LABEL: 'All Data Hints',
    FIELD: 'total_count',
    VALUE: DATA_HINTS_RULE_TYPE.ALL,
  },
  {
    LABEL: 'Generic Data Hints',
    FIELD: 'generic_count',
    VALUE: DATA_HINTS_RULE_TYPE.GENERIC,
  },
  {
    LABEL: 'Specific Data Hints',
    FIELD: 'specific_count',
    VALUE: DATA_HINTS_RULE_TYPE.SPECIFIC,
  },
];
