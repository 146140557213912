export const USER_FORM_FIELDS = {
  uname: 'Username',
  email: 'Email',
  password: 'Password',
  fname: 'First Name',
  lname: 'Last Name',
  manager_id: 'Manager',
  role_id: 'Role',
  client_ids: 'Clients',
};

const ADMIN = 'admin';
const MANAGER = 'manager';
const STAFF = 'staff';
const CROWN_GLOBAL = 'Crown Global';
const CROWN_ADMIN = 'Crown Admin';
const ROLE_WEIGHTS = {
  [ADMIN]: 2,
  [CROWN_ADMIN]: 2,
  [MANAGER]: 1,
  [STAFF]: 0,
  [CROWN_GLOBAL]: 0,
};

export {
  ADMIN,
  MANAGER,
  STAFF,
  ROLE_WEIGHTS
};
