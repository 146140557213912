import classNames from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { colors } from 'constants/colors';
import { USER_MANAGEMENT } from 'constants/routes';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import Icon from 'components/common/icons/Icons';
import Modal from 'components/common/modal/Modal';
import Toast from 'components/common/toast/Toast';

import { createUser } from 'services/users';

import { convertToAddUserPayload } from 'utils/payload';

import UserForm from './UserForm';

import { createUserSchema } from './schema';

export function AddUser() {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toast, setToast] = useState({ hasError: false, message: '' });

  const [modal, setModal] = useState({
    visible: false,
    firstName: '',
    role: '',
  });

  const [formData, setFormData] = useState({
    uname: '',
    email: '',
    password: '',
    fname: '',
    lname: '',
    manager_id: null,
    role_id: null,
    client_ids: [],
    confirmPassword: '',
  });

  // submit form data to API
  const handleAdd = async () => {
    setIsSubmitting(true);
    try {
      // convert the form data to payload required by API
      const body = convertToAddUserPayload(formData);
      await createUser(body);

      navigate(USER_MANAGEMENT);
    } catch (err) {
      setToast({
        hasError: true,
        message: err.response.data.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsSubmitting(false);
      handleModalClose();
    }
  };

  // handle add button click on form to show modal
  const handleSubmit = async (data, roleName = '') => {
    setFormData(data);
    setModal({ visible: true, firstName: data.fname, role: roleName });
  };

  const handleModalClose = () => {
    if (!isSubmitting) {
      setModal((prevState) => ({ ...prevState, visible: false }));
    }
  };

  return (
    <div className="add-user-form">
      <div className="d-flex align-items-center mt-6x">
        <button
          className="btn user-form__back mr-8x"
          onClick={() => navigate(-1)}
        >
          <span>
            <Icon
              icon="arrowLeft"
              viewBox="0 0 16 16"
              size={12}
              color={colors.primary.base}
              className="mr-2x"
            />
          </span>
          Back
        </button>
        <h1>Add User</h1>
      </div>
      <UserForm
        initialData={formData}
        onSubmit={handleSubmit}
        validationSchema={createUserSchema}
      />
      {toast.message && (
        <Toast
          title={toast.message}
          hasError={toast.hasError}
          handleClose={() => setToast({ message: '', hasError: false })}
        />
      )}
      {modal.visible && (
        <Modal>
          <div className="mb-3x">
            <h3>
              Do you want to add {modal.firstName} as {modal.role}?
            </h3>
            <p className="color-grey--80">
              This action cannot be reverted back.
            </p>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn color-primary--base"
              onClick={handleModalClose}
            >
              Go Back
            </button>
            <button
              className={classNames('btn btn-primary', {
                'has-loader': isSubmitting,
              })}
              onClick={handleAdd}
              disabled={isSubmitting}
            >
              Add
              {isSubmitting && <span className="spinner" />}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
