// job statuses
export const JOB_FAILED = 'JOB_FAILED';
export const JOB_SUBMITTED = 'JOB_SUBMITTED';
export const JOB_COMPLETED = 'JOB_COMPLETED';
export const NO_JOB_STARTED = 'NO_JOB_STARTED';

// job status display
export const ERROR = 'Error';
export const ANALYSING = 'Analysing';

// job names
export const JOB_QA = 'QA';
export const JOB_DATA_CHOOSER = 'DATA_CHOOSER';
export const JOB_VALIDATION = 'VALIDATION_CHECKER';
export const JOB_DATA_HINT_VALIDATION = 'DATA_HINT_VALIDATION';
