import { customFormatTypes } from 'constants/formatConfig';

export const accountInfo = [
  {
    source: 'account_number',
    label: 'Account No.',
  },
  {
    source: 'account_id',
    label: 'Account ID',
  },
  {
    source: 'patient_type_name',
    label: 'Patient Type',
    joins: ['patient_type_name', 'patient_type_code'],
  },
  {
    source: 'patient_class_name',
    label: 'Patient Class',
    joins: ['patient_class_name', 'patient_class_code'],
  },
  {
    source: 'current_financial_class_name',
    label: 'Current FC',
    joins: ['current_financial_class_name', 'current_financial_class_code'],
  },
  {
    source: 'location_name',
    label: 'Location',
    joins: ['location_name', 'location_code', 'state'],
  },
  {
    source: 'statute_days',
    label: 'Statute Days',
    joins: ['statute_days', 'statute_days_state'],
  },
];

export const accountTotals = [
  {
    source: 'total_charges',
    label: 'Total Charges',
  },
  {
    source: 'total_payments',
    label: 'Total Payments',
  },
  {
    source: 'total_adjustments',
    label: 'Total Adjustments',
  },
  {
    source: 'total_account_balance',
    label: 'Total Balance',
  },
  {
    source: 'calc_total_account_balance',
    label: 'Calc Total Balance',
    format: customFormatTypes.currency,
  },
];

export const accountDates = [
  {
    source: 'admission_date',
    label: 'Admitted On',
    format: customFormatTypes.date,
  },
  {
    source: 'discharge_date',
    label: 'Discharged On',
    format: customFormatTypes.date,
  },
  {
    source: 'bill_date_original',
    label: 'Billed Originally',
    format: customFormatTypes.date,
  },
  {
    source: 'bill_date_most_recent',
    label: 'Bill Date Recent',
    format: customFormatTypes.date,
  },
  {
    source: 'last_payment_date',
    label: 'Last PMT Date',
    format: customFormatTypes.date,
  },
];

export const crownComputedDates = [
  {
    source: 'last_transaction_date',
    label: 'Last Transaction Date',
    format: customFormatTypes.date,
  },
  {
    source: 'last_payment_date',
    label: 'Last Payment Date',
    format: customFormatTypes.date,
  },
  {
    source: 'last_payment_patient_date',
    label: 'Last Patient Pay Date',
    format: customFormatTypes.date,
  },
  {
    source: 'last_payment_commercial_date',
    label: 'Last Payment Comm',
    format: customFormatTypes.date,
  },
  {
    source: 'calc_aging_days',
    label: 'Calc Aging',
    format: customFormatTypes.noFormat,
  },
];

export const crownComputedTotals = [
  {
    source: 'calc_total_government_payments',
    label: 'Calc Gov Pay',
    format: customFormatTypes.currency,
  },
  {
    source: 'calc_total_commercial_payments',
    label: 'Calc Comm Pay',
    format: customFormatTypes.currency,
  },
  {
    source: 'calc_total_adjustments',
    label: 'Calc Total Adjustments',
    format: customFormatTypes.currency,
  },
  {
    source: 'calc_total_payments',
    label: 'Calc Total Payments',
    format: customFormatTypes.currency,
  },
  {
    source: 'calc_total_patient_payments',
    label: 'Calc Patient Pay',
    format: customFormatTypes.currency,
  },
  {
    source: 'nrp_amount',
    label: 'Calc NRP',
    format: customFormatTypes.currency,
  },
];

export const crownComputedStatistics = [
  {
    source: 'calc_group_balance_count',
    label: 'Calc Group Balance Count',
    format: customFormatTypes.numericWithCommas,
  },
  {
    source: 'calc_group_charges_count',
    label: 'Calc Group Charges Count',
    format: customFormatTypes.numericWithCommas,
  },
];
