export const NO_PASSWORD = 'Please enter Password.';
export const MISSING_INPUT = ':name is a required input.';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong.';
export const PASSWORDS_DO_NOT_MATCH = 'Passwords do not match.';
export const NO_REENTERED_PASSWORD = 'Please Re-Enter Password.';
export const REFRESH_PAGE_ERROR_MESSAGE = 'Please refresh the page.';
export const ACCOUNT_INFO_NO_CLIENT_SELECTED = 'Please select client.';
export const INVALID_ACCOUNT_NUMBER = 'Please enter valid Account Number.';
export const ACCOUNT_INFO_NO_ACCOUNT_NUMBER = 'Please enter Account Number.';
export const DATAHINT_UPDATE_FAILURE_MESSAGE = 'Unable to update datahint.';
export const DATAHINT_EXECUTION_FAILURE_MESSAGE = 'Unable to execute datahint.';
export const DATAHINT_UPDATE_RESET = 'Data Hint Definition reset.';
export const CLIENT_DATA_OVERVIEW_FAILURE =
  'Unable to fetch client dashboard overview.';
export const MAPPING_FAILURE = 'Unable to fetch unmapped data.';
export const WORK_TRACKER_AGGREGATION_FAILURE =
  'Unable to fetch work tracker data.';

export const ETL_FILE_LOAD_FAILURE = 'Unable to fetch ETL file load data.';
export const ENGINE_EXECUTION_TRIGGERED =
  'Engine triggered. Please check ETL Jobs logs.';
export const RULES_ENGINE_FAILURE = 'Unable to trigger rules engine.';
