import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/common/icons/Icons';

const ContinueButton = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await props.onClick();
    setIsLoading(false);
  };

  return (
      <button className="btn-continue w-100 px-7x has-loader" onClick={handleClick} disabled={isLoading}>
        {!isLoading ? <Icon
          className="mt-4x mr-5x"
          width={42}
          icon="longArrow"
          color="#055D54"
        /> : <span className="spinner mr-5x" />}
        Continue to next step
      </button>
  );
};

ContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ContinueButton;
