import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabPanel = ({
  children,
  value,
  index,
  hasLoaded,
  className,
  ...props
}) => {
  const isActive = value === index;

  const panelClassNames = classNames('', {
    [`${className}`]: className,
  });

  return (
    <div
      role="tabpanel"
      hidden={!isActive}
      className={panelClassNames}
      {...props}
    >
      {hasLoaded ? children : null}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  hasLoaded: PropTypes.bool,
  className: PropTypes.string,
};

export default TabPanel;
