import http from 'utils/http';
import config from '../config';
import { interpolate } from 'utils/common/string';

export async function getAccountQueue(clientId, params) {
  const url = interpolate(config.endpoints.accountQueue, { clientId });
  const { data } = await http.post(url, params || {});

  return data;
}
