import { customFormatTypes } from 'constants/formatConfig';
import { DATE_SORTER, NUMERIC_SORTER } from 'components/common/table/constants';

import * as tableConstants from 'components/common/table/constants';

export const ETL_FILELOAD_ID = 'etl_fileload_id';
export const PROJECT_ID = 'project_id';

export const columnConfig = [
  {
    field: tableConstants.CHECKBOX_COLUMN,
    isShownByDefault: true,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      tooltip: false,
      headerTooltip: false,
      formatter: tableConstants.ROW_SELECTION_FORMATTER,
      titleFormatter: tableConstants.ROW_SELECTION_FORMATTER,
      hozAlign: tableConstants.CELL_ALIGN_CENTER,
      headerHozAlign: tableConstants.CELL_ALIGN_CENTER,
      headerSort: false,
      width: 50,
      minWidth: 50,
    },
  },
  {
    field: ETL_FILELOAD_ID,
    title: 'ETL Fileload Id',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'client_id',
    title: 'Client Id',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'file_name',
    title: 'File Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'file_type',
    title: 'File Type',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'status',
    title: 'Status',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'row_count',
    title: 'Row Count',
    isShownByDefault: true,
    sorter: NUMERIC_SORTER,
    formatOfData: customFormatTypes.numericWithCommas,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'import_user',
    title: 'Import User',
    isShownByDefault: false,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'create_date',
    title: 'Import Date',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'is_archived',
    title: 'Is Archived',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.boolean,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'project_name',
    title: 'Project Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
];
