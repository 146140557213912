import config from 'config';

import http from 'utils/http';
import { removeEmptyPayloadValues } from 'utils/payload';
import { getAPIDateString } from 'utils/common/formatter';
import {
  interpolate,
  removeCommasFromNumber,
  convertCurrencyToNumber,
} from 'utils/common/string';

export async function getStatementCategories() {
  const url = config.endpoints.statementCategories;
  const { data } = await http.get(url);

  return data.data;
}

export async function getStatementData(projectId) {
  const url = interpolate(config.endpoints.getProjectStatement, {
    projectId,
  });
  const { data } = await http.get(url);

  return data.data;
}

export async function updateStatementData(projectId, statementData) {
  const url = interpolate(config.endpoints.getProjectStatement, {
    projectId,
  });
  const payload = {
    client_id: statementData.clientId,
    statement_name: statementData.statementName,
    total_balance: removeCommasFromNumber(statementData.totalBalance, false),
    initial_invoiced_amount: convertCurrencyToNumber(
      statementData.initialInvoicedAmount
    ),
    statement_timeperiod: statementData.statementTimeperiod,
    status: statementData.status,
    client_receipt_date: getAPIDateString(statementData.clientReceiptDate),
    invoice_number: statementData.invoiceNumber,
    statement_notes: statementData.statementNotes,
  };

  const { data } = await http.put(url, removeEmptyPayloadValues(payload, true));

  return data.data;
}
