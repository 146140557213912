import { PRIORITY_FIELD, RULE_BASE_ID } from './columnConfig';
import { ASCENDING_ORDER } from 'components/common/table/constants';

export const tableConfig = {
  pagination: false,
  index: RULE_BASE_ID,
  selectable: 1,
  initialSort: [
    {
      column: PRIORITY_FIELD,
      dir: ASCENDING_ORDER,
    },
  ],
};
