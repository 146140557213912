import { CREATE_DATE, RULE_BASE_ID } from './columnConfig';
import { DESCENDING_ORDER } from 'components/common/table/constants';

export const tableConfig = {
  pagination: false,
  index: RULE_BASE_ID,
  selectable: 1,
  initialSort: [
    {
      column: CREATE_DATE,
      dir: DESCENDING_ORDER,
    },
  ],
};
