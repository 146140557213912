import Router from './Router';
import React, { Fragment } from 'react';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

import 'assets/sass/style.scss';

const defaultToastContainerProps = {
  hideProgressBar: true,
  icon: false,
  closeButton: false,
  theme: 'colored',
  closeOnClick: true,
};

const App = () => {
  return (
    <Fragment>
      <ToastContainer {...defaultToastContainerProps} />
      <Router />
    </Fragment>
  );
};

export default App;
