import React from 'react';
import propTypes from 'prop-types';
import Sidebar from 'components/sidebar/Sidebar';
import classNames from 'classnames';

const Layout = ({ children, className }) => {
  const mainClassName = classNames({
    [`${className}`]: className,
  });

  return (
    <>
      <main className={mainClassName}>
        <Sidebar />
        <div className="main-content px-5x pb-5x d-flex flex-direction-column">
          {children}
        </div>
      </main>
    </>
  );
};

Layout.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
};

export default Layout;
