import { React } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updateClientID } from 'actions/clientAction';
import Dropdown from 'components/common/dropdown/Dropdown';

const ClientDropDown = () => {
  const clientList = useSelector((state) => state.clientList);
  const selectedClientId = useSelector((state) => state.selectedClientId);

  const formattedClient = [
    ...clientList.map((client) => ({
      value: client.client_key,
      id: client.client_id,
    })),
  ];
  const selectedClient = clientList.find(
    (client) => parseInt(client.client_id) === parseInt(selectedClientId)
  );
  const displayName = selectedClient
    ? selectedClient.display_name
    : 'Select the Client';

  const dispatch = useDispatch();

  const handleClientChange = (event) => {
    dispatch(updateClientID(event.target.id));
  };

  return (
    <Dropdown
      dropdownItems={formattedClient}
      label={displayName}
      onClick={handleClientChange}
    />
  );
};

export default ClientDropDown;
