import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';
import MappingBox from 'components/mapping/MappingBox';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyProjectPage from 'components/common/emptypage/EmptyProjectPage';
import ContinueButton from 'components/common/continueButton/continueButton';

import { getInsuranceMapping, getTransactionMapping } from 'services/mapping';
import { updateProject } from 'services/projectAnalysis';
import { getInsuranceCategories } from 'services/insurance';

import {
  pivotedInsuranceTableConfig,
  transactionMapping,
} from './columnConfigs';

import {
  PROJECT_ANALYSIS,
  TRANSACTION_MAPPING,
  INSURANCE_MAPPING,
} from 'constants/routes';
import { DATAHINTS } from 'constants/projectStatuses';
import { UNMAPPED_CATEGORY } from 'constants/insurance';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
} from 'actions/clientAction';

import { formatDateWithTime } from 'utils/common/formatter';
import * as routes from 'constants/routes';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList, selectedProject } = state;

  return { selectedClientId, clientList, selectedProject };
};

class Mapping extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navigateTo: '',
      hasError: false,
      errorMessage: '',
      isLoading: false,
      insuranceCategories: [],
      insuranceMappingData: [],
      transactionMappingData: [],
    };
  }

  async componentDidMount() {
    if (this.props.selectedClientId && this.props.selectedProject.projectId) {
      await this.getInsuranceCategories();
      await this.getMappingdata();
    }
  }

  getInsuranceCategories = async () => {
    this.setState({
      isLoading: true,
    });
    const categories = await getInsuranceCategories();
    let insuranceCategories = [];

    if (categories) {
      insuranceCategories = categories.map(
        (category) => category.category_name
      );
      insuranceCategories.push(UNMAPPED_CATEGORY);
    }

    this.setState({
      isLoading: false,
      insuranceCategories,
    });
  };

  getMappingdata = async () => {
    const { selectedClientId } = this.props;

    this.setState({
      isLoading: true,
      hasError: false,
      errorMessage: '',
    });

    try {
      const insuranceMappingData = await getInsuranceMapping(selectedClientId);
      const transactionMappingData = await getTransactionMapping(
        selectedClientId
      );

      this.setState({
        insuranceMappingData:
          this.pivotInsuranceMappingData(insuranceMappingData),
        transactionMappingData: this.rearrangeTransactionMappingData(
          transactionMappingData
        ),
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  rearrangeTransactionMappingData = (mappingData) => {
    let rearrangedData = [...mappingData];
    const lastIndex = rearrangedData.length - 1;
    let unmappedIndex = rearrangedData.findIndex(
      (data) => data.category_name === UNMAPPED_CATEGORY
    );
    let tempDataObj = {};

    if (unmappedIndex !== -1) {
      tempDataObj = rearrangedData[lastIndex];
      rearrangedData[lastIndex] = rearrangedData[unmappedIndex];
      rearrangedData[unmappedIndex] = tempDataObj;

      return rearrangedData;
    }

    return rearrangedData;
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      rowData: {},
      navigateTo: PROJECT_ANALYSIS,
    });

    this.props.updateClientID(clientId);
    this.props.clearSelectedProject();
  };

  openNewTab = (route) => {
    window.open(route, '_blank');
  };

  handleNextStep = async () => {
    const { selectedClientId, selectedProject, updateSelectedProject } =
      this.props;

    if (selectedProject.projectId) {
      try {
        const data = await updateProject(
          selectedClientId,
          selectedProject.projectId,
          {
            status: DATAHINTS.toUpperCase(),
          }
        );

        updateSelectedProject({
          ...selectedProject,
          status: DATAHINTS.toUpperCase(),
          lastUpdateDate: formatDateWithTime(data.update_date),
        });

        this.setState({
          navigateTo: routes.DATA_HINT_RULE_LIBRARY,
        });
      } catch (error) {
        const errorMessage =
          error.response.detail.data || DEFAULT_ERROR_MESSAGE;

        this.setState({
          errorMessage,
          hasError: true,
          isLoading: false,
        });
      }
    }
  };

  pivotInsuranceMappingData = (tableData) => {
    let pivotedRows = [];
    let pivotedRowObj = {
      category_name: '',
      billref: '',
      financial: '',
      other: '',
    };

    const pivotedRowKeys = Object.keys(pivotedRowObj);

    this.state.insuranceCategories.forEach((category) => {
      pivotedRowKeys.forEach((rowKey) => {
        let foundData = tableData.find(
          (mappingData) =>
            mappingData.category_name.toUpperCase() ===
              category.toUpperCase() &&
            mappingData.insurance_source.toUpperCase() === rowKey.toUpperCase()
        );

        pivotedRowObj = {
          ...pivotedRowObj,
          category_name: category,
          [rowKey]: 0,
        };

        if (foundData) {
          pivotedRowObj = {
            ...pivotedRowObj,
            category_name: category,
            [rowKey]: foundData.count,
          };
        }
      });

      pivotedRows.push(pivotedRowObj);
    });

    return pivotedRows;
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      hasError,
      isLoading,
      navigateTo,
      errorMessage,
      insuranceMappingData,
      transactionMappingData,
    } = this.state;
    const { clientList, selectedClientId, selectedProject } = this.props;
    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });
    const projectDisplayName = selectedProject.projectName || '';

    return navigateTo ? (
      <Navigate to={navigateTo} replace={true} />
    ) : (
      <>
        <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
          <div className="profile mb-3x ml-auto">
            <Dropdown
              label={displayName}
              dropdownItems={dropdownItems}
              onClick={this.handleSelectClient}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-1x">
            <h1>
              Mapping{' '}
              {projectDisplayName && <span>({projectDisplayName})</span>}
            </h1>
            {selectedProject.status && (
              <div className="badge badge-lg mr-auto ml-5x mt-3x">
                {selectedProject.status}
              </div>
            )}

            <div className="color-grey--60 mt-4x">
              Last Updated : {selectedProject.lastUpdateDate}
            </div>
          </div>
        </div>
        {isLoading && <Loader isFullScreen={true} />}
        {selectedProject.projectId ? (
          <div
            className="d-flex flex-direction-column gap-3x"
            style={{ height: 'calc(100vh - 132px)' }}
          >
            <div className="row">
              <div className="col-6">
                <MappingBox
                  key={'transactionMapping'}
                  title="Transaction Mapping"
                  columnConfig={transactionMapping}
                  tableData={transactionMappingData}
                  handleBtnClick={() => {
                    this.openNewTab(TRANSACTION_MAPPING);
                  }}
                  buttonLabel="Go to Transaction Mapping"
                />
              </div>
              <div className="col-6">
                <MappingBox
                  key={'insuranceMapping'}
                  title="Insurance Mapping"
                  columnConfig={pivotedInsuranceTableConfig}
                  tableData={insuranceMappingData}
                  handleBtnClick={() => {
                    this.openNewTab(INSURANCE_MAPPING);
                  }}
                  buttonLabel="Go to Insurance Mapping"
                />
              </div>
            </div>

            <div className="mt-auto">
              <ContinueButton onClick={this.handleNextStep} />
            </div>
          </div>
        ) : (
          <EmptyProjectPage pageName="Mapping" />
        )}

        {hasError && (
          <Toast
            title={errorMessage}
            hasError={hasError}
            handleClose={this.resetError}
          />
        )}
      </>
    );
  }
}

Mapping.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedProject: PropTypes.object,
  clearSelectedProject: PropTypes.func,
  updateSelectedProject: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
})(Mapping);
