import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Datepicker = (props) => {
  const DatePickerInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="form__control datepicker__input"
      onClick={onClick}
      onChange={onChange}
      placeholder={props.placeholder}
      ref={ref}
    ></input>
  ));

  const dateValue = props.selectedDate ? new Date(props.selectedDate) : null;

  return (
    <DatePicker
      selected={dateValue}
      onChange={props.handleCalendarValueChange}
      customInput={<DatePickerInput />}
    />
  );
};

Datepicker.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  handleCalendarValueChange: PropTypes.func,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Datepicker;
