import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SelectBox from 'components/common/select/Select';
import FormLabel from 'components/common/formLabel/FormLabel';
import SwitchButton from 'components/common/switch/SwitchButton';
import ClientDropDown from 'components/common/dropdown/clientDropDown';
import { getAlgorithms } from 'services/algorithms';
import { Notify } from 'components/common/notify/Notify';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';
import { getLocations } from 'services/location';
import Table from 'components/common/table/Table';
import { accountQueueColumnConfig } from './columnConfig';
import { getAccountQueue } from 'services/accountQueue';
import { DESCENDING_ORDER } from 'components/common/table/constants';
import { GLOBAL_PAGINATION_CONFIG } from 'constants/paginationConfig';
import config from '../../config';
import EmptyPage from 'components/common/emptypage/EmptyPage';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';
import Icon from 'components/common/icons/Icons';
import { colors } from 'constants/colors';

const AccountQueue = () => {
  const clientList = useSelector((state) => state.clientList);
  const selectedClientId = useSelector((state) => state.selectedClientId);
  const [rules, setRules] = useState([]);
  const [selectedRuleId, setSelectedRuleId] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocationValue, setSelectedLocationValue] = useState(null);
  const [inProgressAccount, setInProgressAccount] = useState(false);
  const [columnsConfig, setColumnsConfig] = useState(accountQueueColumnConfig);
  const [updatedColumnConfig, setUpdatedColumnConfig] = useState({
    field: '',
    isShownByDefault: false,
  });

  const fetchRules = async () => {
    try {
      if (!selectedClientId) {
        return;
      }
      const data = await getAlgorithms(selectedClientId);
      setRules(data);
      setSelectedRuleId(null);
    } catch (error) {
      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  const getAccountQueueTableConfig = () => {
    return {
      ...GLOBAL_PAGINATION_CONFIG,
      paginationSize: 100,
      ajaxURL:
        config.baseURL.replace(/\/$/, '') + config.endpoints.accountQueue,
      ajaxParams: {
        clientId: selectedClientId,
      },
      columnDefaults: {
        formatter: (cell) => {
          const value = cell.getValue();

          return value === null || value === undefined ? '-' : value;
        },
      },
      filterMode: 'remote',
      sortMode: 'remote',
      ajaxRequestFunc: async (_url, _config, params) => {
        try {
          if (!selectedClientId) {
            return [];
          }
          const filters = params.filter.reduce((acc, filter) => {
            return { ...acc, [filter.field]: filter.value };
          }, {});

          const lastSort =
            params.sort.length > 0 ? params.sort[params.sort.length - 1] : null;

          const sorts = lastSort
            ? { key: lastSort.field, order_clause: lastSort.dir.toUpperCase() }
            : {};

          if (selectedRuleId) {
            filters.rule_base_id = selectedRuleId;
          }
          if (selectedLocationValue) {
            filters.location_name = selectedLocationValue;
          }
          if (inProgressAccount) {
            filters.in_process_account = true;
          }

          return await getAccountQueue(selectedClientId, {
            filter: {
              ...filters,
              page: params.page,
              page_size: params.per_page,
            },
            sort: sorts,
          });
        } catch (error) {
          Notify.error({
            title: error.response?.data?.type || DEFAULT_ERROR_MESSAGE,
          });
        }
      },
      initialSort: [
        {
          column: 'create_date',
          dir: DESCENDING_ORDER,
        },
      ],
    };
  };

  const fetchLocations = async () => {
    try {
      if (!selectedClientId) {
        return;
      }
      const data = await getLocations(selectedClientId);
      setLocations(data);
      setSelectedLocationValue(null);
    } catch (error) {
      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  const handleRuleChange = async (data) => {
    const value = data.value;
    setSelectedRuleId(value);
  };

  const handleLocationChange = async (data) => {
    const value = data.value;
    setSelectedLocationValue(value);
  };

  const selectedRule = (() => {
    const selected = rules.find((rule) => rule.rule_base_id === selectedRuleId);

    return selected
      ? { label: selected.rule_name, value: selected.rule_base_id }
      : null;
  })();

  const selectedLocation = (() => {
    const selected = locations.find(
      (location) => location.location_name === selectedLocationValue
    );

    return selected
      ? { label: selected.location_name, value: selected.location_name }
      : null;
  })();

  const handleExploreButtonClick = (_, cell) => {
    const data = cell.getRow().getData();
    window.open(
      `/account_information/${data.client_id}/${data.account_number}`,
      'blank'
    );
  };
  const handleColumnSelect = (selectedColumnField) => {
    let isSelected = false;
    columnsConfig.forEach((col) => {
      if (col.field === selectedColumnField) {
        isSelected = col.isShownByDefault;
      }
    });

    setColumnsConfig((prevConfig) =>
      prevConfig.map((col) => {
        return col.field === selectedColumnField
          ? { ...col, isShownByDefault: !col.isShownByDefault }
          : col;
      })
    );
    setUpdatedColumnConfig({
      field: selectedColumnField,
      isShownByDefault: !isSelected,
    });
  };

  const selectedColumns = columnsConfig
    .filter((col) => col.isShownByDefault)
    .map((col) => col.field);

  const tableKey = [
    selectedClientId,
    selectedRuleId,
    selectedLocationValue,
    inProgressAccount,
  ].join('-');

  useEffect(() => {
    fetchRules();
    fetchLocations();
  }, [selectedClientId]);

  const client =
    clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    )?.client_key || null;

  return (
    <>
      <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
        <div className="profile mb-3x ml-auto">
          <ClientDropDown />
        </div>
      </div>
      <div>
        <h1>Account Queue</h1>
      </div>
      {!selectedClientId ? (
        <EmptyPage pageName="account queue" selectionParameters="client" />
      ) : (
        <>
          <div className="d-flex align-items-center bg-white--base p-4x">
            <span style={{ alignSelf: 'center' }}>
              <FormLabel label="Rule" />
            </span>
            <div className="col-3">
              <SelectBox
                options={rules.map((rule) => ({
                  label: rule.rule_name,
                  value: rule.rule_base_id,
                }))}
                value={selectedRule}
                placeholder="Select Rule"
                onChange={handleRuleChange}
              />
            </div>
            <span style={{ alignSelf: 'center' }}>
              <FormLabel label="Location" />
            </span>
            <div className="col-3">
              <SelectBox
                options={locations.map((location) => ({
                  label: location.location_name,
                  value: location.location_name,
                }))}
                value={selectedLocation}
                placeholder="Select Location"
                onChange={handleLocationChange}
              />
            </div>
            <button
              className="btn-has-icon"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <Icon
                icon="reset"
                size={12}
                color={colors.primary[60]}
                className="mr-2x"
                viewBox="0 0 12 12"
              />
              Reset
            </button>
            <div style={{ marginLeft: 'auto' }}>
              <SwitchButton
                onClick={() => setInProgressAccount(!inProgressAccount)}
                switchClass="switch--medium"
                dotClass="dot--medium"
                key={'OnlyActive'}
                labelText={'Show in-process accounts'}
                isBadgeVisible={false}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between p-4x">
            <div>
              {selectedRule ? (
                <h5>
                  {' '}
                  {selectedRule.label} | {client}{' '}
                </h5>
              ) : null}
            </div>
            <CustomColumnSelector
              className={'dropdown__right dropdown__account-queue-dropdown'}
              columnConfig={columnsConfig}
              handleSelect={handleColumnSelect}
              selectedColumns={selectedColumns}
            />
          </div>
          <div className="table">
            <Table
              columnConfig={columnsConfig}
              tableConfig={getAccountQueueTableConfig()}
              key={tableKey}
              updatedColumnConfig={updatedColumnConfig}
              selectedColumns={selectedColumns}
              handleExploreButtonClick={handleExploreButtonClick}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AccountQueue;
