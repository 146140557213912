import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tab = ({
  children,
  onClick,
  tabIndex,
  className,
  isSelected = false,
  isDisabled = false,
  ...props
}) => {
  const tabClassNames = classNames('tabs__tab', {
    [`${className}`]: className,
    'tabs__tab--active': isSelected,
  });

  return (
    <li
      role="tab"
      className={tabClassNames}
      onClick={onClick}
      aria-selected={isSelected}
      aria-disabled={isDisabled}
      tabIndex={tabIndex}
      {...props}
    >
      {children}
    </li>
  );
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
};

export default Tab;
