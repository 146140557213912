import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tag from 'components/common/tag/Tag';
import Icon from 'components/common/icons/Icons';
import Table from 'components/common/table/Table';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import { billReferenceTableConfig } from './configFiles/tableConfigs';
import { billReferenceColumnConfig } from './configFiles/columnConfigs';

import { getAccountBillReferenceData } from 'services/accountInformation';

const mapStateToProps = (state) => {
  const { selectedClientId, accountNumber } = state;

  return { selectedClientId, accountNumber };
};

class AccountBillReferenceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredFields: [],
      selectedColumns: [],
      updatedColumnConfig: {
        field: '',
        isShownByDefault: false,
      },
    };

    this.tableRef = React.createRef(null);
  }

  async componentDidMount() {
    await this.updateTableData();

    this.setState({
      selectedColumns: billReferenceColumnConfig
        .filter((col) => col.isShownByDefault)
        .map((col) => col.field),
    });
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.shouldFetchNewData && this.props.shouldFetchNewData) {
      await this.updateTableData();
    }
  }

  updateTableData = async () => {
    this.setState({
      data: [],
    });
    const { accountNumber, selectedClientId } = this.props;

    try {
      const data = await getAccountBillReferenceData(
        accountNumber,
        selectedClientId
      );

      this.setState({
        data,
      });
    } catch (error) {
      this.setState({
        data: [],
      });
    }
  };

  handleCategorySelect = (selectedColumnField) => {
    const { selectedColumns } = this.state;
    const selected = selectedColumns.includes(selectedColumnField);

    if (selected) {
      this.setState({
        selectedColumns: selectedColumns.filter(
          (col) => col !== selectedColumnField
        ),
      });
    }

    if (!selected) {
      this.setState({
        selectedColumns: [...selectedColumns, selectedColumnField],
      });
    }

    this.setState({
      updatedColumnConfig: {
        field: selectedColumnField,
        isShownByDefault: !selected,
      },
    });
  };

  handleDataFiltered = (filters) => {
    const filteredFields = filters.map((filter) => {
      const foundConfig = billReferenceColumnConfig.find(
        (config) => config.field === filter.field
      );

      if (!foundConfig && foundConfig.title) {
        return '';
      }

      return foundConfig.title;
    });

    this.setState({
      filteredFields,
    });
  };

  handleResetFilters = () => {
    const { tabulator } = this.tableRef.current;

    tabulator.clearHeaderFilter();
  };

  render() {
    const { data, selectedColumns, updatedColumnConfig, filteredFields } =
      this.state;

    return (
      <>
        <div className="d-flex justify-content-between align-items-end mb-4x">
          <h4 className="color-primary--base line-height-1">
            Account Bill Reference
          </h4>
          <div className="d-flex pt-2x gap-9x">
            <Tag
              maxWidthInPx={800}
              tags={filteredFields}
              resetTags={this.handleResetFilters}
            />
            <CustomColumnSelector
              columnConfig={billReferenceColumnConfig}
              handleSelect={this.handleCategorySelect}
              selectedColumns={selectedColumns}
            />
            <button
              className="btn-ghost px-2x py-1x"
              onClick={this.props.exportTable}
            >
              <Icon
                icon="export"
                className="mr-2x icon-export"
                width={10}
                height={14}
                color="#DADADA"
              />
              Export
            </button>
          </div>
        </div>

        <div className="table mb-5x">
          <Table
            data={data}
            ref={this.tableRef}
            onDataFiltered={this.handleDataFiltered}
            tableConfig={billReferenceTableConfig}
            columnConfig={billReferenceColumnConfig}
            selectedColumns={selectedColumns}
            updatedColumnConfig={updatedColumnConfig}
          />
        </div>
      </>
    );
  }
}

AccountBillReferenceTable.propTypes = {
  exportTable: PropTypes.func,
  shouldFetchNewData: PropTypes.bool,
  accountNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {})(AccountBillReferenceTable);
