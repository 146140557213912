export const etlStatusColumnConfig = [
  {
    field: 'import_user',
    title: 'User ID',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'file_name',
    title: 'File Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'create_date',
    title: 'Start Date',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'row_count',
    title: 'Rows Imported',
    isShownByDefault: true,
    isEditable: false,
  },
];
