import { removeEmptyPayloadValues } from 'utils/payload';

/**
 * TODO: Unit Test for util
 * Util function to compare two objects for changes in values
 * @param {object} obj1 - First Object
 * @param {object} obj2 - Second Object
 * @returns {Array} - Array of keys that have changed
 */
export const getObjectChanges = (obj1, obj2) => {
  // remove empty values for both objects including empty strings
  obj1 = removeEmptyPayloadValues(obj1, true);
  obj2 = removeEmptyPayloadValues(obj2, true);

  let changes = [];

  // Check if both arguments are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    throw new Error('Both arguments should be objects');
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Find all unique keys
  const allKeys = new Set([...keys1, ...keys2]);

  // Check each key
  for (const key of allKeys) {
    const value1 = obj1[key];
    const value2 = obj2[key];
    const currentPath = key;

    // Check if the key exists in both objects
    const keyInObj1 = keys1.includes(key);
    const keyInObj2 = keys2.includes(key);

    if (!keyInObj1 || !keyInObj2) {
      changes.push(currentPath);
    } else if (
      typeof value1 === 'object' &&
      typeof value2 === 'object' &&
      !Array.isArray(value1) &&
      !Array.isArray(value2)
    ) {
      changes = changes.concat(getObjectChanges(value1, value2, currentPath));
    } else if (Array.isArray(value1) && Array.isArray(value2)) {
      // Compare arrays
      if (
        value1.length !== value2.length ||
        value1.some((item, index) => item !== value2[index])
      ) {
        changes.push(currentPath);
      }
    } else if (value1 !== value2) {
      changes.push(currentPath);
    }
  }

  return changes;
};
