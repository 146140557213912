import PropTypes from 'prop-types';
import React from 'react';

import { formatDateToMonth } from 'utils/common/formatter';

// TODO: Refactor code and adjust unit tests
export const customTickFormatter = (tick, index, data) => {
  // Show the month label only for the first occurrence in that month
  if (
    index === 0 ||
    formatDateToMonth(data[index].snapshot_date) !==
      formatDateToMonth(data[index - 1].snapshot_date)
  ) {
    return formatDateToMonth(data[index].snapshot_date);
  }

  return '';
};

// TODO: Optimize code
export const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { snapshot_date, total_balance, total_count } = payload[0].payload;

    return (
      <div className="tooltip">
        <p className="date">
          {new Date(snapshot_date).toLocaleString('default', {
            month: 'long',
          })}
        </p>

        <div className="d-flex align-items-center mb-2x">
          <span className="count"></span>
          <span>Total Count: {total_count.toLocaleString()}</span>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="balance"></span>
          <span>Total Balance: {total_balance.toFixed(2)}</span>
        </div>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        snapshot_date: PropTypes.string.isRequired,
        total_balance: PropTypes.number.isRequired,
        total_count: PropTypes.number.isRequired,
      }),
    })
  ),
};
