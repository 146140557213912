import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import SelectBox from 'components/common/select/Select';
import Datepicker from 'components/common/datepicker/Datepicker';

import { PROJECT_STATUS_OPTIONS } from 'constants/projectStatuses';

const ProjectEditPage = (props) => {
  const inputClassname = classNames({
    form__control: true,
  });

  const statusOptions = PROJECT_STATUS_OPTIONS.map((option) => {
    return {
      label: option,
      value: option.toUpperCase().trim(),
    };
  });

  const {
    notes,
    status,
    onSave,
    isSaving,
    assignee,
    reviewer,
    onChange,
    projectName,
    analysisDate,
    publishDate,
    countAccount,
    balanceAccount,
    handleSelectStatus,
    updateEditPagePublishDate,
    updateEditPageAnalysisDate,
  } = props;

  const selectedStatus = statusOptions.find(
    (option) => option.value === status
  );

  return (
    <>
      <div className="d-flex justify-content-between gap-6x bg-white--base border-radius-4 has-box-shadow mb-5x">
        <div className="w-50 pt-9x pb-4x px-4x">
          <form
            action=""
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className="form-group  mb-6x">
              <label className="form__label">Project Name</label>
              <input
                className={inputClassname}
                type="text"
                name="projectName"
                value={projectName}
                onChange={onChange}
                placeholder="Enter Project Name"
              />
            </div>
            <div className="form-group mb-6x">
              <div className="row">
                <div className="col-6">
                  <label className="form__label">Analysis Date</label>
                  <Datepicker
                    selectedDate={analysisDate}
                    placeholder={'Select Analysis Date'}
                    handleCalendarValueChange={updateEditPageAnalysisDate}
                  />
                </div>
                <div className="col-6">
                  <label className="form__label">Publish Date</label>
                  <Datepicker
                    selectedDate={publishDate}
                    handleCalendarValueChange={updateEditPagePublishDate}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-6x">
              <div className="row">
                <div className="col-6">
                  <label className="form__label">Assignee</label>
                  <input
                    className={inputClassname}
                    type="text"
                    name="assignee"
                    value={assignee}
                    onChange={onChange}
                    placeholder="Enter Assignee"
                  />
                </div>
                <div className="col-6">
                  <label className="form__label">Reviewer</label>
                  <input
                    className={inputClassname}
                    type="text"
                    name="reviewer"
                    value={reviewer}
                    onChange={onChange}
                    placeholder="Enter Assignee"
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-6x">
              <div className="row">
                <div className="col-6">
                  <label className="form__label">Count of Accounts</label>
                  <input
                    className={inputClassname}
                    type="text"
                    name="countAccount"
                    value={countAccount}
                    onChange={onChange}
                    placeholder="Enter the count of accounts"
                  />
                </div>
                <div className="col-6">
                  <label className="form__label">Balance of Account</label>
                  <input
                    className={inputClassname}
                    type="text"
                    name="balanceAccount"
                    value={balanceAccount}
                    onChange={onChange}
                    placeholder="Enter the total balance of account"
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-6x">
              <div className="row">
                <div className="col-6">
                  <label className="form__label">Status</label>
                  <SelectBox
                    onChange={handleSelectStatus}
                    options={statusOptions}
                    value={selectedStatus}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="textarea__box box-shadow--none w-50">
          <h4 className="mb-5x">Notes</h4>

          <textarea
            className="h-auto font-secondary"
            value={notes}
            name="notes"
            onChange={onChange}
          />
        </div>
      </div>
      <div className="actions d-flex justify-content-end gap-2x mb-3x">
        <button className="btn btn-link" onClick={props.handleCancelEditPage}>
          Cancel
        </button>
        <button className="btn btn-primary has-loader" onClick={onSave}>
          Save Project Details
          {isSaving && <span className="spinner" />}
        </button>
      </div>
    </>
  );
};

ProjectEditPage.propTypes = {
  onSave: PropTypes.func,
  notes: PropTypes.string,
  isSaving: PropTypes.bool,
  status: PropTypes.string,
  onChange: PropTypes.func,
  assignee: PropTypes.string,
  reviewer: PropTypes.string,
  projectName: PropTypes.string,
  archiveProject: PropTypes.func,
  countAccount: PropTypes.string,
  balanceAccount: PropTypes.string,
  handleSelectStatus: PropTypes.func,
  handleCancelEditPage: PropTypes.func,
  updateEditPagePublishDate: PropTypes.func,
  updateEditPageAnalysisDate: PropTypes.func,
  publishDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  analysisDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ProjectEditPage;
