import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ValidationError } from 'yup';
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';

import Input from 'components/common/Input/Input';
import SelectBox from 'components/common/select/Select';
import FormLabel from 'components/common/formLabel/FormLabel';

import { getDataHintsCategory } from 'services/dataHints';
import {
  DATA_HINTS_FORM_FIELDS,
  DATA_HINTS_RULE_TYPE,
} from 'constants/dataHints';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';
import { SITE_DICTIONARY_TYPE_VALUE } from 'constants/siteDictionary';
import { Notify } from 'components/common/notify/Notify';
import { useNavigate } from 'react-router-dom';

const DataHintsForm = ({
  initialData,
  validationSchema,
  onSubmit,
  isSubmitting,
  isEdit,
}) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const selectedClientId = useSelector((state) => state.selectedClientId);
  const clientList = useSelector((state) => state.clientList);

  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // TODO: put new category value once created in database
      const categories = await getDataHintsCategory({
        category: SITE_DICTIONARY_TYPE_VALUE.RULE_CATEGORY,
      });

      const formattedCategories =
        categories &&
        categories.map((c) => ({
          label: c.dictionary_name,
          value: c.dictionary_value,
        }));

      setCategoryOptions(formattedCategories);
    } catch (error) {
      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const clientDropdownOptions = useMemo(() => {
    const options = [
      {
        label: 'Global',
        value: null,
      },
    ];

    // only add client to option if selected
    if (selectedClientId) {
      const selectedClient = clientList?.find(
        (c) => c.client_id === Number(selectedClientId)
      );

      if (selectedClient) {
        options.push({
          label: selectedClient.display_name,
          value: Number(selectedClientId),
        });
      }
    }

    return options;
  }, [selectedClientId, clientList]);

  const handleSubmit = async () => {
    try {
      // validate the form data according to the schema passed
      await validationSchema.validate(formData, {
        abortEarly: false,
      });

      // reset errors to be empty after validation passes
      setErrors({});

      onSubmit(formData);
    } catch (err) {
      if (err instanceof ValidationError) {
        const validationErrors = {};

        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);

        return;
      }
    }
  };

  const onClientChange = (data) => {
    const value = data.value;

    setFormData((prevState) => ({
      ...prevState,
      rule_type: value
        ? DATA_HINTS_RULE_TYPE.SPECIFIC
        : DATA_HINTS_RULE_TYPE.GENERIC,
      client_ids: value !== null ? [value] : null,
    }));
  };

  const onCategoryChange = (data) => {
    const value = data.value;

    setFormData((prevState) => ({
      ...prevState,
      rule_category: value,
    }));

    handleFormBlur({
      target: { value, name: 'rule_category' },
    });
  };

  // set form values on input field change
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // handle form validation for the specific field on blur
  const handleFormBlur = async (e) => {
    const { name, value } = e.target;
    try {
      await validationSchema.validateAt(name, { ...formData, [name]: value });
      setErrors({ ...errors, [name]: '' });
    } catch (err) {
      setErrors({ ...errors, [name]: err.message });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isGenericDataHint = formData.rule_type === DATA_HINTS_RULE_TYPE.GENERIC;

  const selectedClient = isGenericDataHint
    ? clientDropdownOptions.find((option) => option.value === null)
    : formData.client_ids?.[0] &&
      clientDropdownOptions.find(
        (option) => option.value === formData.client_ids[0]
      );

  return (
    <div className="data-hints bg-white--base">
      <div className="p-6x">
        <div className="data-hints__form">
          <Input
            label={DATA_HINTS_FORM_FIELDS.rule_name}
            value={formData.rule_name}
            name="rule_name"
            type="text"
            placeholder="Enter Name"
            required
            onBlur={handleFormBlur}
            onChange={handleFormChange}
            error={errors.rule_name}
            disabled={isEdit}
          />
          <div className="form-group">
            <FormLabel label={DATA_HINTS_FORM_FIELDS.rule_category} required />
            <SelectBox
              options={categoryOptions}
              value={categoryOptions.find(
                (c) => c.value === formData.rule_category
              )}
              placeholder="Select Category"
              isLoading={isLoading}
              isDisabled={isLoading}
              onChange={onCategoryChange}
            />
            {errors.rule_category && (
              <p className="user-form__error-msg text-sm mt-1x">
                {errors.rule_category}
              </p>
            )}
          </div>
          <div className="form-group">
            <FormLabel label={DATA_HINTS_FORM_FIELDS.client} required />
            <SelectBox
              options={clientDropdownOptions}
              placeholder="Select Client"
              onChange={onClientChange}
              value={selectedClient}
            />
            {errors.client_ids && (
              <p className="user-form__error-msg text-sm mt-1x">
                {errors.client_ids}
              </p>
            )}
          </div>
        </div>
        <div className="form-group mt-8x">
          <FormLabel label="Data Hints Definition" required />
          <textarea
            className="form__control data-hints__"
            name="rule_definition"
            value={formData.rule_definition}
            placeholder="Write data hints definition here..."
            spellCheck={false}
            onBlur={handleFormBlur}
            onChange={handleFormChange}
          />
          {errors.rule_definition && (
            <p className="user-form__error-msg text-sm mt-1x">
              {errors.rule_definition}
            </p>
          )}
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-end p-6x">
        <button
          className="btn btn-link m-1x color-primary--base"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
        <button
          className={classNames('btn btn-primary m-1x', {
            'has-loader': isSubmitting,
          })}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isEdit ? 'Update' : 'Add'}
          {isSubmitting && <span className="spinner" />}
        </button>
      </div>
    </div>
  );
};

DataHintsForm.defaultProps = {
  isSubmitting: false,
  isEdit: false,
};

DataHintsForm.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.any.isRequired,
  isSubmitting: PropTypes.bool,
  isEdit: PropTypes.bool,
};

export default DataHintsForm;
