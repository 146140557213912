import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import Card from 'components/common/card/Card';
import Item from 'components/common/item/Item';
import { Notify } from 'components/common/notify/Notify';
import EmptyPage from 'components/common/emptypage/EmptyPage';
import ClientDropDown from 'components/common/dropdown/clientDropDown';

import {
  formatCurrency,
  formatCurrencyWithoutSign,
  formatDate,
} from 'utils/common/formatter';

import {
  CLIENT_DATA_OVERVIEW_FAILURE,
  MAPPING_FAILURE,
  WORK_TRACKER_AGGREGATION_FAILURE,
  ETL_FILE_LOAD_FAILURE,
  ENGINE_EXECUTION_TRIGGERED,
  DEFAULT_ERROR_MESSAGE,
  DATAHINT_EXECUTION_FAILURE_MESSAGE,
  RULES_ENGINE_FAILURE,
} from 'constants/errorMessages';
import { UNMAPPED_CATEGORY } from 'constants/insurance';
import { getClientDataOverview, runETL } from 'services/clientDashboard';
import { getInsuranceMapping, getTransactionMapping } from 'services/mapping';
import {
  mappingDataConfig,
  clientDataOverviewDetailConfig,
} from './clientDashboardConfig';
import { getWorkTrackerAggByWeek } from 'services/workTracker';
import Icon from 'components/common/icons/Icons';
import { colors } from 'constants/colors';

import LineGraph from './LineGraph';
import BarGraph, { BarTooltip } from './BarGraph';
import Table from 'components/common/table/Table';
import { etlStatusColumnConfig } from './configFiles/columnConfig';
import { getEtlFileLoad } from 'services/etlFileLoad';
import config from '../../config';
import { DESCENDING_ORDER } from 'components/common/table/constants';
import { GLOBAL_PAGINATION_CONFIG } from 'constants/paginationConfig';
import ActionButton from 'components/common/actionButton/actionButton';
import { runValidations } from 'services/validationChecker';
import { runDataHints } from 'services/dataHints';
import { computeStatistics } from 'services/dataChooser';
import * as qaServices from 'services/qa';
import { ETL_TRIGGERED } from 'constants/successMessage';

const ClientDashboard = () => {
  const clientList = useSelector((state) => state.clientList);
  const selectedClientId = useSelector((state) => state.selectedClientId);

  const selectedClient = clientList.find(
    (client) => parseInt(client.client_id) === parseInt(selectedClientId)
  );
  const isAutoProcessingClient = selectedClient?.is_auto_processing;

  const [clientDataLoading, setClientDataLoading] = useState(false);
  const [clientDataOverview, setClientDataOverview] = useState();

  const [mappingData, setMappingData] = useState({});
  const [mappingDataLoading, setMappingDataLoading] = useState(false);

  const [workTrackerAgg, setWorkTrackerAgg] = useState([]);
  const [workTrackerAggLoading, setWorkTrackerAggLoading] = useState(false);
  const [isRulesEngineLoading, setIsRulesEngineLoading] = useState(false);

  const [isValidationCheckerLoading, setIsValidationCheckerLoading] =
    useState(false);
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(false);

  const [isDataHintLoading, setIsDataHintLoading] = useState(false);
  const [isRunETLLoading, setIsRunETLLoading] = useState(false);

  const getEtlFileloadTableConfig = () => {
    return {
      ...GLOBAL_PAGINATION_CONFIG,
      ajaxURL: config.baseURL.replace(/\/$/, '') + config.endpoints.etlFileLoad,
      ajaxParams: {
        client_id: selectedClientId,
      },
      ajaxRequestFunc: async (_url, _config, params) => {
        try {
          return await getEtlFileLoad(params);
        } catch (error) {
          Notify.error({
            title: error.response?.data?.detail || ETL_FILE_LOAD_FAILURE,
          });
        }
      },
      initialSort: [
        {
          column: 'create_date',
          dir: DESCENDING_ORDER,
        },
      ],
    };
  };

  // Fetches and formats the client overview data.
  const getClientOverview = async () => {
    try {
      setClientDataLoading(true);

      const clientOverview = await getClientDataOverview(selectedClientId);

      const formattedData = {
        ...clientOverview,
        balance_count_overview: clientOverview.balance_count_overview.map(
          (item) => ({
            ...item,
            snapshot_date: formatDate(item.snapshot_date),
          })
        ),
        data_overview: {
          ...clientOverview.data_overview,
          total_count: formatCurrencyWithoutSign(
            clientOverview.data_overview.total_count
          ),
          max_post_date: formatDate(clientOverview.data_overview.max_post_date),
          last_data_run_date: formatDate(
            clientOverview.data_overview.last_data_run_date
          ),
          total_balance: `(${formatCurrency(
            -clientOverview.data_overview.total_balance
          )})`,
        },
      };

      setClientDataOverview(formattedData);
    } catch (error) {
      const errorMessage =
        error.response.data.detail || CLIENT_DATA_OVERVIEW_FAILURE;

      Notify.error({
        title: errorMessage,
      });
    } finally {
      setClientDataLoading(false);
    }
  };

  // Fetches and processes unmapped insurance and transaction mapping data
  const getMappingData = async () => {
    try {
      setMappingDataLoading(true);

      const mappingPromises = [
        getInsuranceMapping(selectedClientId),
        getTransactionMapping(selectedClientId),
      ];

      const [insuranceMapping, transactionMapping] = await Promise.all(
        mappingPromises
      );

      const unmappedInsurance =
        insuranceMapping.find(
          (item) => item.category_name === UNMAPPED_CATEGORY
        )?.count || 0;

      const unmappedTransaction =
        transactionMapping.find(
          (item) => item.category_name === UNMAPPED_CATEGORY
        )?.count || 0;

      setMappingData({ unmappedInsurance, unmappedTransaction });
    } catch (error) {
      const errorMessage = error.response.data.detail || MAPPING_FAILURE;
      Notify.error({
        title: errorMessage,
      });
    } finally {
      setMappingDataLoading(false);
    }
  };

  const getWorkTrackerAgg = async () => {
    try {
      setWorkTrackerAggLoading(true);
      const data = await getWorkTrackerAggByWeek({
        client_id: selectedClientId,
      });
      setWorkTrackerAgg(data);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.detail
        : WORK_TRACKER_AGGREGATION_FAILURE;
      Notify.error({
        title: errorMessage,
      });
      setWorkTrackerAgg([]);
    } finally {
      setWorkTrackerAggLoading(false);
    }
  };

  const handleValidationEngine = async () => {
    try {
      setIsValidationCheckerLoading(true);
      await runValidations({ client_id: selectedClientId });

      Notify.success({
        title: ENGINE_EXECUTION_TRIGGERED,
      });
    } catch (error) {
      Notify.error({
        title: error.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsValidationCheckerLoading(false);
    }
  };

  const handleStatisticsEngine = async () => {
    try {
      setIsStatisticsLoading(true);

      await computeStatistics({
        client_id: selectedClientId,
      });

      Notify.success({
        title: ENGINE_EXECUTION_TRIGGERED,
      });
    } catch (error) {
      Notify.error({
        title: error.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsStatisticsLoading(false);
    }
  };

  const handleDataHintsEngine = async () => {
    try {
      setIsDataHintLoading(true);
      const updatePayload = {
        client_id: selectedClientId,
      };

      await runDataHints(updatePayload);

      Notify.success({
        title: ENGINE_EXECUTION_TRIGGERED,
      });
    } catch (error) {
      Notify.error({
        title: DATAHINT_EXECUTION_FAILURE_MESSAGE,
      });
    } finally {
      setIsDataHintLoading(false);
    }
  };

  const handleRulesEngine = async () => {
    setIsRulesEngineLoading(true);
    try {
      await qaServices.trashAndRunAnalysis(selectedClientId);
      Notify.success({
        title: ENGINE_EXECUTION_TRIGGERED,
      });
    } catch (err) {
      Notify.error({
        title: err.response?.data?.detail || RULES_ENGINE_FAILURE,
      });
    } finally {
      setIsRulesEngineLoading(false);
    }
  };

  const handleRunETL = async () => {
    setIsRunETLLoading(true);
    try {
      await runETL(selectedClientId);
      Notify.success({
        title: ETL_TRIGGERED,
      });
    } catch (err) {
      Notify.error({
        title: err.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsRunETLLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedClientId) return;

    getClientOverview();
    getMappingData();
    getWorkTrackerAgg();
  }, [selectedClient]);

  return (
    <>
      <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
        <div className="profile mb-3x ml-auto">
          <ClientDropDown />
        </div>
        <div className="d-flex justify-content-between align-items-center mb-1x">
          <h2>{selectedClient?.display_name || 'Client Dashboard'}</h2>
          <button
            className="btn-has-icon ml-5x mr-auto mt-3x"
            onClick={() => {
              window.location.reload(false);
            }}
          >
            <Icon icon="refresh" size={12} color="#546071" className="mr-2x" />
            Refresh
          </button>
        </div>
      </div>
      {!selectedClientId ? (
        <EmptyPage pageName="client dashboard" selectionParameters="client" />
      ) : (
        <div className="client-dashboard-main">
          <Card
            loading={clientDataLoading}
            header="Client Data Overview"
            className="col-span-9"
            contentClassName="content d-flex justify-content-between"
          >
            {clientDataOverviewDetailConfig.map((item, index) => (
              <Item
                key={index}
                label={item.label}
                value={clientDataOverview?.data_overview?.[item.key] || '-'}
                valueClassName={classNames({
                  'color-danger--base': item.key === 'total_balance',
                })}
              />
            ))}
          </Card>

          <Card
            loading={mappingDataLoading}
            className="col-span-3"
            header="Mapping"
            contentClassName="content d-flex justify-content-between"
          >
            {mappingDataConfig.map((item, index) => {
              return (
                <Item
                  key={index}
                  label={item.label}
                  value={mappingData?.[item.key] || '-'}
                />
              );
            })}
          </Card>

          <Card
            className="col-span-6"
            header="Balance/Count"
            loading={clientDataLoading}
            contentClassName="client-dashboard__graph-container"
          >
            <LineGraph
              balanceCountOverview={clientDataOverview?.balance_count_overview}
            />
          </Card>

          <Card
            className="col-span-6"
            header="Work Tracker"
            loading={workTrackerAggLoading}
            contentClassName="client-dashboard__graph-container"
          >
            <BarGraph
              data={workTrackerAgg}
              xLabel="Weeks"
              yLabel="Total work units logged"
              xDataKey="week"
              yDataKey="count"
              barColor={colors.primary[15]}
              toolTip={BarTooltip}
            />
          </Card>

          <Card className="col-span-9" header="ETL Status">
            <Table
              columnConfig={etlStatusColumnConfig}
              tableConfig={getEtlFileloadTableConfig()}
              key={selectedClientId}
            />
          </Card>

          <Card className="col-span-3" header="Actions">
            <div className="d-flex flex-direction-column gap-5x">
              <ActionButton
                title="Run ETL"
                onClick={handleRunETL}
                isLoading={isRunETLLoading}
              />
              <ActionButton
                title="Run Statistics Engine"
                disabled={!isAutoProcessingClient}
                onClick={handleStatisticsEngine}
                isLoading={isStatisticsLoading}
              />
              <ActionButton
                title="Run Validation Engine"
                disabled={!isAutoProcessingClient}
                onClick={handleValidationEngine}
                isLoading={isValidationCheckerLoading}
              />
              <ActionButton
                title="Run Data Hints Engine"
                disabled={!isAutoProcessingClient}
                onClick={handleDataHintsEngine}
                isLoading={isDataHintLoading}
              />
              <ActionButton
                title="Run Rules Engine"
                disabled={!isAutoProcessingClient}
                onClick={handleRulesEngine}
                isLoading={isRulesEngineLoading}
              />
            </div>
          </Card>
        </div>
      )}
    </>
  );
};
export default ClientDashboard;
