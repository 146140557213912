// values to be used by default in Tabulator
export const LIST_EDITOR = 'list';
export const TEXT_EDITOR = 'input';
export const ASCENDING_ORDER = 'asc';
export const CELL_ALIGN_LEFT = 'left';
export const DESCENDING_ORDER = 'desc';
export const DEFAULT_SORTER = 'string';
export const NUMERIC_SORTER = 'number';
export const CELL_ALIGN_RIGHT = 'right';
export const CELL_ALIGN_CENTER = 'center';
export const ROW_SELECTION_FORMATTER = 'rowSelection';

// Tabulator Events
export const ROW_CLICK = 'rowClick';
export const DATA_FILTERED = 'dataFiltered';
export const ROW_SELECTION_CHANGED = 'rowSelectionChanged';

export const LOG_COLUMN = 'LOG_COLUMN';
export const DATE_SORTER = 'DATE_SORTER';
export const PILL_COLUMN = 'PILL_COLUMN';
export const ERROR_COLUMN = 'ERROR_COLUMN';
export const AMOUNT_SORTER = 'AMOUNT_SORTER';
export const CHECKBOX_COLUMN = 'CHECKBOX_COLUMN';
export const CATEGORY_NAME_COLUMN = 'category_name';
export const EDIT_MODAL_COLUMN = 'EDIT_MODAL_COLUMN';
export const EDIT_BUTTON_COLUMN = 'EDIT_BUTTON_COLUMN';
export const RERUN_BUTTON_COLUMN = 'RERUN_BUTTON_COLUMN';
export const TRASH_BUTTON_COLUMN = 'TRASH_BUTTON_COLUMN';
export const CUSTOM_SUM_CALCULTOR = 'CUSTOM_SUM_CALCULTOR';
export const EXPORT_BUTTON_COLUMN = 'EXPORT_BUTTON_COLUMN';
export const EXPLORE_BUTTON_COLUMN = 'EXPLORE_BUTTON_COLUMN';
export const TOGGLE_CHECKBOX_COLUMN = 'TOGGLE_CHECKBOX_COLUMN';
export const VIEWDETAIL_BUTTON_COLUMN = 'VIEWDETAIL_BUTTON_COLUMN';

export const HIGHLIGHT_ROW_CLASSNAME = 'highlight-row';

export const defaultTableConfig = {
  maxHeight: '100%',
  layout: 'fitColumns',
  rowHeight: 28,
  renderVertical: 'virtual',
  placeholder: 'No data available',
  pagination: true,
  paginationSize: 100,
  paginationButtonCount: 5,
  paginationSizeSelector: [50, 100, 150, 200],
  paginationCounter: (pageSize, _currentRow, _currentPage, totalRows) => {
    return `Showing ${pageSize} rows of ${totalRows}`;
  },
};
