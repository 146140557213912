import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { tableConfig } from './tableConfig';
import { columnConfig } from './columnConfig';
import Table from 'components/common/table/Table';
import Loader from 'components/common/loader/Loader';
import { getWorkTrackers } from 'services/workTracker';
import { Notify } from 'components/common/notify/Notify';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

const WorkTrackerHistory = forwardRef((props, ref) => {
  const { clientId, accountId } = props;

  useImperativeHandle(ref, () => ({
    refetchData,
  }));

  const [workTrackers, setWorkTrackers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNewData, setHasNewData] = useState(false);

  const fetchWorkTrackerHistory = async (client_id, account_id) => {
    try {
      setIsLoading(true);
      const result = await getWorkTrackers({
        client_id,
        account_id,
        per_page: 7,
        page: 1,
      });
      setWorkTrackers(result);
    } catch (error) {
      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsLoading(false);
      setHasNewData((prevState) => !prevState);
    }
  };

  const refetchData = () => {
    if (!clientId || !accountId) return;
    fetchWorkTrackerHistory(clientId, accountId);
  };

  useEffect(() => {
    refetchData();
  }, [clientId, accountId]);

  return (
    <>
      {isLoading && <Loader isFullScreen={true} />}
      <div className="title mb-4x">
        <h4>History</h4>
      </div>
      <div className="content table work-tracker__table">
        <Table
          className="has-box-shadow "
          hasNewData={hasNewData}
          tableConfig={tableConfig}
          columnConfig={columnConfig}
          data={workTrackers}
        />
      </div>
    </>
  );
});

WorkTrackerHistory.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default WorkTrackerHistory;
