import * as Yup from 'yup';

export const addDataHintSchema = Yup.object().shape({
  rule_name: Yup.string().required('Rule name is required'),
  rule_category: Yup.string().required('Rule category is required'),
  rule_definition: Yup.string().required('Rule definition is required'),
  rule_type: Yup.string().optional(),
  client_ids: Yup.array()
    .of(Yup.number())
    .min(1, 'Client is required')
    .nullable(true),
});

export const updateDataHintSchema = Yup.object().shape({
  rule_category: Yup.string().optional(),
  rule_definition: Yup.string().optional(),
  client_ids: Yup.array().of(Yup.number()).optional().nullable(true),
  rule_type: Yup.string().optional(),
});
