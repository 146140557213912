import React from 'react';
import PropTypes from 'prop-types';
import { Slide, toast } from 'react-toastify';

const DefaultToast = (props) => {
  return (
    <div className="notify">
      <div className="notify__title">{props.title}</div>
      <div className="notify__actions">
        {props.hasActions ? (
          <>
            <button className="btn btn-link" onClick={props.handleReset}>
              Reset
            </button>
            <button className="btn btn-primary" onClick={props.handleSave}>
              Save
            </button>
          </>
        ) : props.cancelButton ? (
          <props.cancelButton />
        ) : !props.handleReset || !props.handleSave ? (
          <button className="btn btn-primary">Close</button>
        ) : null}
      </div>
    </div>
  );
};

DefaultToast.propTypes = {
  title: PropTypes.string,
  hasActions: PropTypes.bool,
  handleSave: PropTypes.func,
  handleClose: PropTypes.func,
  handleReset: PropTypes.func,
  cancelButton: PropTypes.elementType,
};

const defaultProps = {
  autoClose: 2000,
  position: 'top-center',
  transition: Slide,
};

export const Notify = new Proxy(
  { ...toast },
  {
    get(toastFunctions, calledFunction) {
      if (calledFunction in toastFunctions) {
        return (props) =>
          toastFunctions[calledFunction](
            <DefaultToast {...props} title={props.title} />,
            {
              ...defaultProps,
              ...props,
            }
          );
      }
    },
  }
);

// USAGE SAMPLE
/* <button onClick={() => Notify.info({
  title: "Careful You've unsaved changes",
  hasActions: true,
  handleSave: this.handleSave,
  handleReset: this.handleReset,
  hasError: false
})}>CLICK ME</button> */

// USAGE SAMPLE for Promise
// Notify
//   .promise({
//     success: 'Some success message',
//     error: 'Some error message',
//   })
//   .then()
//   .catch();
