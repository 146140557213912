import http from 'utils/http';
import config from '../config';

export async function getAllClients() {
  const url = config.endpoints.clientList;

  const { data } = await http.get(url);

  return data.data;
}

/**
 * V1 endpoint implementation with search params for filtering clients
 * @param {*} params - The params to filter the clients with
 */
export async function getClients(params = {}) {
  const url = config.endpoints.clients;

  const { data } = await http.get(url, {
    params: Object.fromEntries(
      Object.entries(params).filter(([_key, value]) => value)
    ),
  });

  return data.data;
}
