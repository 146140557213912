export const USD = 'USD';
export const LOCALE = 'en-US';
export const CURRENCY = 'currency';
export const NUMBER_TYPE = 'number';
export const STRING_TYPE = 'string';
export const DEFAULT_NULL_STRING = '-';
export const TWO_DIGIT_FORMAT = '2-digit';

export const DEFAULT_TIME_OPTIONS = {
  hour: TWO_DIGIT_FORMAT,
  minute: TWO_DIGIT_FORMAT,
};

export const customFormatTypes = {
  text: 'TEXT',
  date: 'DATE',
  state: 'STATE',
  boolean: 'BOOLEAN',
  currency: 'CURRENCY',
  noFormat: 'NO_FORMAT',
  dateWithTime: 'DATE_WITH_TIME',
  numericWithCommas: 'NUMERIC_WITH_COMMAS',
  currencyWithoutSign: 'CURRENCTY_WITHOUT_SIGN',
};
