import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import AddProjectModal from './AddProjectModal';
import ProjectEditPage from './ProjectEditPage';

import Modal from 'components/common/modal/Modal';
import Table from 'components/common/table/Table';
import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyPage from 'components/common/emptypage/EmptyPage';
import SwitchButton from 'components/common/switch/SwitchButton';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import {
  updateClientID,
  updateClientList,
  updateSelectedProject,
} from 'actions/clientAction';

import {
  getProjects,
  updateProject,
  createProject,
  exportProject,
} from 'services/projectAnalysis';

import { tableConfig } from './tableConfig';
import { columnConfig } from './columnConfig';
import * as statuses from 'constants/projectStatuses';
import { DEFAULT_ERROR_MESSAGE, MISSING_INPUT } from 'constants/errorMessages';
import { projectFormInputs } from 'constants/requiredFormInputs';
import {
  EDIT_BUTTON_COLUMN,
  EXPLORE_BUTTON_COLUMN,
} from 'components/common/table/constants';

import * as routes from 'constants/routes';

import { findMissingInput } from 'utils/payload';
import { interpolate } from 'utils/common/string';
import { formatDate, formatDateWithTime } from 'utils/common/formatter';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList } = state;

  return { selectedClientId, clientList };
};

class ProjectAnalysis extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      navigateTo: '',
      hasError: false,
      errorMessage: '',
      isLoading: false,
      categoryList: [],
      hasNewData: false,
      selectedColumns: [],
      columnConfigState: columnConfig,
      stateTrackedData: {},
      selectedRowData: {
        projectId: '',
        projectName: '',
        analysisDate: '',
        publishDate: '',
        assignee: '',
        reviewer: '',
        countAccount: null,
        balanceAccount: '',
        status: '',
        isArchived: false,
        notes: '',
      },
      isSaving: false,
      showOnlyActive: true,
      isShowingModal: false,
      isNotesEditing: false,
      isShowingEditPage: false,
      notes: '',
      modalData: {
        assignee: '',
        projectName: '',
        analysisDate: '',
      },
      updatedColumnConfig: {
        field: '',
        isShownByDefault: false,
      },
    };
  }

  async componentDidMount() {
    this.setState({
      columnConfigState: columnConfig,
      selectedColumns: columnConfig
        .filter(
          (config) =>
            config.isShownByDefault &&
            config.field !== EDIT_BUTTON_COLUMN &&
            config.field !== EXPLORE_BUTTON_COLUMN
        )
        .map((col) => col.field),
    });
    await this.getProjects();

    if (this.props.clientList.length === 0) {
      await this.updateClientList();
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedClientId !== this.props.selectedClientId) {
      await this.getProjects();
    }
  }

  getProjects = async () => {
    const { selectedClientId } = this.props;
    if (!selectedClientId) {
      return;
    }

    this.setState({
      isLoading: true,
      hasError: false,
      errorMessage: '',
    });

    try {
      const data = await getProjects(selectedClientId);

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  saveNewProject = async () => {
    const { selectedClientId } = this.props;
    const { modalData } = this.state;

    const missingInput = findMissingInput(modalData, projectFormInputs);
    if (missingInput) {
      this.setState({
        hasError: true,
        errorMessage: interpolate(MISSING_INPUT, { name: missingInput }),
      });

      return;
    }

    this.setState({
      hasError: false,
      errorMessage: '',
      isSaving: true,
    });

    try {
      await createProject(selectedClientId, modalData);
      await this.getProjects();
      this.setState({
        isSaving: false,
        isShowingModal: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        hasError: true,
        errorMessage,
        isSaving: false,
        isShowingModal: false,
      });
    }
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      rowData: {},
    });

    this.props.updateClientID(clientId);
  };

  getFilteredData = (forceApplyFilter = false) => {
    const { data, showOnlyActive } = this.state;
    const activeStatuses = [
      statuses.NEW.toUpperCase(),
      statuses.DATA.toUpperCase(),
      statuses.VALIDATION.toUpperCase(),
      statuses.MAPPING.toUpperCase(),
      statuses.DATAHINTS.toUpperCase(),
      statuses.RULES.toUpperCase(),
      statuses.QA.toUpperCase(),
      statuses.PUBLISHED.toUpperCase(),
    ];
    if (!data) {
      return [];
    }

    if (showOnlyActive || forceApplyFilter) {
      return data.filter((row) =>
        activeStatuses.includes(row.status.toUpperCase())
      );
    }

    return data;
  };

  handleOnlyActiveToggle = () => {
    this.setState({
      showOnlyActive: !this.state.showOnlyActive,
    });
  };

  handleColumnSelect = (selectedColumnField) => {
    const { selectedColumns, columnConfigState } = this.state;
    const selected = selectedColumns.includes(selectedColumnField);

    if (selected) {
      this.setState({
        selectedColumns: selectedColumns.filter(
          (col) => col !== selectedColumnField
        ),
      });
    }

    if (!selected) {
      this.setState({
        selectedColumns: [...selectedColumns, selectedColumnField],
      });
    }

    const updatedColumnConfig = {
      field: selectedColumnField,
      isShownByDefault: !selected,
    };

    const foundIndex = columnConfigState.findIndex(
      (configObj) => configObj.field === selectedColumnField
    );

    if (foundIndex !== -1) {
      const currentColumnConfig = columnConfigState[foundIndex];
      let updatedColumnConfigState = [...columnConfigState];

      updatedColumnConfigState[foundIndex] = {
        ...currentColumnConfig,
        ...updatedColumnConfig,
      };

      this.setState({
        columnConfigState: updatedColumnConfigState,
      });
    }

    this.setState({
      updatedColumnConfig,
    });
  };

  getRowDataInJSON = (rowData) => {
    return {
      projectId: rowData.project_id || '',
      projectName: rowData.project_name || '',
      analysisDate: rowData.analysis_date || '',
      publishDate: rowData.publish_date || '',
      assignee: rowData.assignee || '',
      reviewer: rowData.reviewer || '',
      countAccount: rowData.count_account || null,
      balanceAccount: rowData.balance_account || '',
      status: rowData.status || '',
      isArchived: rowData.isArchived || false,
      notes: rowData.notes || '',
    };
  };

  handleRowClick = (_, row) => {
    const rowData = row.getData();

    this.setState({
      notes: rowData.notes,
      selectedRowData: this.getRowDataInJSON(rowData),
    });
  };

  handleCancelEditingNotes = () => {
    this.setState({
      hasNewData: false,
      stateTrackedData: {},
      selectedRowData: this.state.stateTrackedData,
      isNotesEditing: false,
    });
  };

  handleEditChangeBtnClick = () => {
    this.setState({
      stateTrackedData: this.state.selectedRowData,
      isNotesEditing: true,
    });
  };

  handleSaveProjectDetails = async () => {
    const { selectedRowData } = this.state;

    const missingInput = findMissingInput(selectedRowData, projectFormInputs);
    if (missingInput) {
      this.setState({
        hasError: true,
        errorMessage: interpolate(MISSING_INPUT, { name: missingInput }),
      });

      return;
    }

    this.setState({
      isSaving: true,
    });

    try {
      await updateProject(
        this.props.selectedClientId,
        selectedRowData.projectId,
        selectedRowData
      );

      const data = await getProjects(this.props.selectedClientId);

      this.setState({
        data,
        isSaving: false,
        hasNewData: true,
        isNotesEditing: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isSaving: false,
        hasNewData: true,
        isNotesEditing: false,
      });
    }

    if (this.state.isShowingEditPage) {
      this.setState({
        isShowingEditPage: false,
      });
    }

    this.resetSelectedRow();
  };

  setNavigateTo = (navigateTo) => {
    this.setState({
      navigateTo,
    });
  };

  handleExploreButtonClick = async (_, cell) => {
    const { selectedClientId } = this.props;
    const rowData = cell.getRow().getData();
    const selectedProject = {
      status: rowData.status,
      projectId: rowData.project_id,
      projectName: rowData.project_name,
      lastUpdateDate: rowData.update_date,
    };

    this.props.updateSelectedProject(selectedProject);

    switch (rowData.status.toUpperCase()) {
      case statuses.NEW.toUpperCase(): {
        const apiPayload = {
          status: statuses.DATA.toUpperCase(),
        };

        const data = await updateProject(
          selectedClientId,
          selectedProject.projectId,
          apiPayload
        );
        this.props.updateSelectedProject({
          status: data.status,
          projectId: data.project_id,
          projectName: data.project_name,
          lastUpdateDate: formatDateWithTime(data.update_date),
        });
        this.setNavigateTo(routes.DATA_CHOOSER);

        break;
      }
      case statuses.DATA.toUpperCase():
        this.setNavigateTo(routes.DATA_CHOOSER);
        break;

      case statuses.VALIDATION.toUpperCase():
        this.setNavigateTo(routes.VALIDATION_CHECKER);
        break;

      case statuses.MAPPING.toUpperCase():
        this.setNavigateTo(routes.MAPPING);
        break;

      case statuses.DATAHINTS.toUpperCase():
        this.setNavigateTo(routes.DATA_HINT_RULE_LIBRARY);
        break;

      case statuses.QA.toUpperCase():
        this.setNavigateTo(routes.QA);
        break;

      case statuses.RULES.toUpperCase():
        this.setNavigateTo(routes.RULE_LIBRARY);
        break;

      case statuses.PUBLISHED.toUpperCase():
        this.setNavigateTo(routes.PUBLISHER);
        break;

      default:
        return;
    }
  };

  handleTableEditButtonClick = (_, cell) => {
    const rowData = cell.getRow().getData();

    this.setState({
      selectedRowData: this.getRowDataInJSON(rowData),
      isShowingEditPage: true,
    });
  };

  handleExportButtonClick = async (_, cell) => {
    const rowData = cell.getRow().getData();
    const { project_name, project_id } = rowData;

    if (!project_id) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    try {
      const now = Date.now();
      const blob = await exportProject(project_id);
      this.setState({
        isLoading: false,
      });
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${project_name}_${formatDate(now)}.xlsx`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  resetModalData = () => {
    this.setState({
      modalData: {
        projectName: '',
        assignee: '',
        analysisDate: '',
      },
    });
  };

  resetSelectedRow = () => {
    this.setState({
      selectedRowData: {
        projectId: '',
        projectName: '',
        analysisDate: '',
        publishDate: '',
        assignee: '',
        reviewer: '',
        countAccount: null,
        balanceAccount: '',
        status: '',
        isArchived: false,
        notes: '',
      },
    });
  };

  handleShowModal = () => {
    this.resetModalData();
    this.setState({
      isShowingModal: true,
    });
  };

  handleCloseModal = () => {
    this.resetModalData();
    this.setState({
      hasNewData: true,
      isShowingModal: false,
    });
  };

  handleModalDataChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      hasNewData: false,
      modalData: {
        ...this.state.modalData,
        [name]: value,
      },
    });
  };

  updateModalAnalysisDate = (selectedDate) => {
    this.setState({
      hasNewData: false,
      modalData: {
        ...this.state.modalData,
        analysisDate: selectedDate,
      },
    });
  };

  handleCloseEditPage = () => {
    this.setState({
      hasNewData: true,
      isShowingEditPage: false,
    });

    this.resetSelectedRow();
  };

  handleEditPageChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      hasNewData: false,
      selectedRowData: {
        ...this.state.selectedRowData,
        [name]: value,
      },
    });
  };

  updateEditPageAnalysisDate = (selectedDate) => {
    this.setState({
      hasNewData: false,
      selectedRowData: {
        ...this.state.selectedRowData,
        analysisDate: selectedDate,
      },
    });
  };

  updateEditPagePublishDate = (selectedDate) => {
    this.setState({
      hasNewData: false,
      selectedRowData: {
        ...this.state.selectedRowData,
        publishDate: selectedDate,
      },
    });
  };

  handleSelectStatus = (selectedOption) => {
    const { selectedRowData } = this.state;
    let publishDate = '';

    if (
      selectedRowData.status.toUpperCase() !==
        statuses.PUBLISHED.toUpperCase() &&
      selectedOption.value === statuses.PUBLISHED.toUpperCase()
    ) {
      publishDate = new Date();
    }

    this.setState({
      hasNewData: false,
      selectedRowData: {
        ...this.state.selectedRowData,
        publishDate: publishDate,
        status: selectedOption.value,
      },
    });
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      isSaving,
      hasError,
      isLoading,
      modalData,
      navigateTo,
      hasNewData,
      errorMessage,
      isNotesEditing,
      showOnlyActive,
      isShowingModal,
      selectedRowData,
      selectedColumns,
      columnConfigState,
      isShowingEditPage,
      updatedColumnConfig,
    } = this.state;
    const { clientList, selectedClientId } = this.props;

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });
    const tableData = this.getFilteredData();
    const totalActiveCount = this.getFilteredData(true).length;

    return navigateTo ? (
      <Navigate to={navigateTo} replace={true} />
    ) : (
      <>
        <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
          <div className="profile mb-3x ml-auto">
            <Dropdown
              label={displayName}
              dropdownItems={dropdownItems}
              onClick={this.handleSelectClient}
            />
          </div>
          <div className="d-flex justify-content-between align-items-end mb-3x">
            <h1>
              Project Analysis {selectedClient && <span>({displayName})</span>}
            </h1>
          </div>
        </div>

        {selectedClientId ? (
          <>
            {isLoading && <Loader isFullScreen={true} />}
            {!isShowingEditPage && (
              <div className="project-page">
                <div className="d-flex justify-content-between align-items-center bg-white--base py-2x px-4x mb-1x border-radius-4">
                  <div className="switcher-filters">
                    <SwitchButton
                      key={'OnlyActive'}
                      value={showOnlyActive}
                      labelText={'Only Active'}
                      badgeCount={totalActiveCount}
                      onClick={this.handleOnlyActiveToggle}
                    />
                  </div>
                  <CustomColumnSelector
                    className={'dropdown__right'}
                    columnConfig={columnConfig}
                    handleSelect={this.handleColumnSelect}
                    selectedColumns={selectedColumns}
                  />
                </div>

                <div
                  className="d-flex flex-direction-column"
                  style={{ height: 'calc(100vh - 190px)' }}
                >
                  <div className="table table-50vh has-box-shadow">
                    <Table
                      className="has-box-shadow"
                      data={tableData}
                      hasNewData={hasNewData}
                      tableConfig={tableConfig}
                      columnConfig={columnConfigState}
                      onRowClick={this.handleRowClick}
                      selectedColumns={selectedColumns}
                      updatedColumnConfig={updatedColumnConfig}
                      handleEditButtonClick={this.handleTableEditButtonClick}
                      handleExploreButtonClick={this.handleExploreButtonClick}
                      handleExportButtonClick={this.handleExportButtonClick}
                    />
                  </div>

                  <div className="d-flex flex-direction-column align-items-end mt-3x mb-6x">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleShowModal}
                    >
                      Add New Project
                    </button>
                  </div>

                  <div className="textarea__box table-50vh py-6x px-10x">
                    <h3 className="mb-5x color-primary--base">Notes</h3>

                    {selectedRowData.projectName ? (
                      <textarea
                        className="h-auto font-secondary"
                        readOnly={isNotesEditing ? false : true}
                        value={selectedRowData.notes || ''}
                        name="notes"
                        onChange={this.handleEditPageChange}
                      />
                    ) : (
                      <EmptyPage
                        className="empty__unstyled"
                        sectionMessage={true}
                        sectionParameters="any row"
                      />
                    )}

                    <div className="actions d-flex justify-content-end mt-4x">
                      {!isNotesEditing && selectedRowData.projectName && (
                        <button
                          className="btn btn-primary--outlined btn-outlined"
                          onClick={this.handleEditChangeBtnClick}
                        >
                          Edit
                        </button>
                      )}

                      {isNotesEditing && (
                        <>
                          <button
                            className="btn btn-link"
                            onClick={this.handleCancelEditingNotes}
                          >
                            Cancel
                          </button>

                          <button
                            className="btn btn-primary has-loader"
                            onClick={this.handleSaveProjectDetails}
                          >
                            Save
                            {isSaving && !isShowingModal && (
                              <span className="spinner" />
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {isShowingModal && (
                  <Modal onClose={this.handleCloseModal}>
                    <AddProjectModal
                      {...modalData}
                      isSaving={isSaving}
                      handleCalendarValueChange={this.updateModalAnalysisDate}
                      clientName={selectedClient.display_name || ''}
                      handleChange={this.handleModalDataChange}
                      onClose={this.handleCloseModal}
                      onSave={this.saveNewProject}
                    />
                  </Modal>
                )}
              </div>
            )}

            {isShowingEditPage && (
              <ProjectEditPage
                {...selectedRowData}
                isSaving={isSaving}
                onChange={this.handleEditPageChange}
                onSave={this.handleSaveProjectDetails}
                handleSelectStatus={this.handleSelectStatus}
                handleCancelEditPage={this.handleCloseEditPage}
                updateEditPagePublishDate={this.updateEditPagePublishDate}
                updateEditPageAnalysisDate={this.updateEditPageAnalysisDate}
              />
            )}
          </>
        ) : (
          <EmptyPage
            selectionParameters={'client'}
            pageName={'project analysis'}
          />
        )}

        {hasError && (
          <Toast
            hasError={hasError}
            title={errorMessage}
            handleClose={this.resetError}
          />
        )}
      </>
    );
  }
}

ProjectAnalysis.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  updateSelectedProject: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  updateSelectedProject,
})(ProjectAnalysis);
