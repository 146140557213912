import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  NUMERIC_SORTER,
  EDIT_BUTTON_COLUMN,
} from 'components/common/table/constants';

import { userManagementPillFormatter } from './CustomUserManagementPill';

import {
  clientCountFormatter,
  clientListTooltipRenderer,
} from './ClientCellRenderer';
import CustomEditButton from 'components/common/table/CustomEditButton.js';
import { ROLE_WEIGHTS } from 'constants/userManagement';
import { get } from 'utils/storage';
import { can } from 'utils/userManagement';
import { USER_ID, ROLE } from 'constants/storage';
import {
  EDIT_SELF,
  EDIT_USER_BY_TIER,
  EDIT_ALL_USER,
} from 'constants/permissions';

export const PUBLISHED_DATE_COLUMN = 'publish_date';

export const columnConfig = [
  {
    field: 'user_id',
    title: 'User ID',
    isShownByDefault: true,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 120,
    },
  },
  {
    field: 'uname',
    title: 'User Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'email',
    title: 'Email',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      widthGrow: 2, // takes 2/5 of the remaining space
    },
  },
  {
    field: 'fname',
    title: 'First Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'lname',
    title: 'Last Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'role_name',
    isShownByDefault: true,
    additionalConfig: {
      formatter: userManagementPillFormatter,
      resizable: false,
      title: 'Role',
      width: 120,
    },
  },
  {
    field: 'manager_name',
    title: 'Managed By',
    isShownByDefault: true,
    isEditable: false,
    tooltip: true,
  },
  {
    field: 'clients',
    title: 'Clients',
    isShownByDefault: true,
    isEditable: false,

    additionalConfig: {
      formatter: clientCountFormatter,
      tooltip: clientListTooltipRenderer,
      width: 120,
    },
  },
  {
    field: EDIT_BUTTON_COLUMN,
    isShownByDefault: true,
    additionalConfig: {
      headerSort: false,
      title: 'Action',
      resizable: false,
      cssClass: 'action-btn-cell text-center',
      width: 120,
      formatter: function (cell, formatterParams, onRendered) {
        const container = document.createElement('div');
        container.classList.add('actions-btn');
        const root = createRoot(container);

        const { role_name, user_id } = cell.getRow().getData();
        const roleWeight = ROLE_WEIGHTS[role_name] || 0;
        const currentUserRole = get(ROLE);
        const currentUserId = get(USER_ID);
        const currentUserRoleWeight = ROLE_WEIGHTS[currentUserRole];
        const canEdit = can([
          [EDIT_ALL_USER],
          [EDIT_USER_BY_TIER, () => roleWeight <= currentUserRoleWeight],
          [EDIT_SELF, () => currentUserId === user_id],
        ]);
        const isDisabled = !canEdit;
        root.render(
          <CustomEditButton
            cell={cell}
            formatterParams={formatterParams}
            onRendered={onRendered}
            disabled={isDisabled}
          />
        );

        return container;
      },
    },
  },
];

export default columnConfig;
