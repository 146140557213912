import { customFormatTypes } from 'constants/formatConfig';
import {
  LIST_EDITOR,
  NUMERIC_SORTER,
  DATE_SORTER,
} from 'components/common/table/constants';

export const INSURANCE_ID_FIELD = 'insurance_id';

export const columnConfig = [
  {
    field: INSURANCE_ID_FIELD,
    title: 'ID',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 90,
    },
  },
  {
    field: 'is_financial_insurance',
    title: 'Fx',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.boolean,
    additionalConfig: {
      width: 90,
    },
  },
  {
    field: 'is_billreference',
    title: 'Br',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.boolean,
    additionalConfig: {
      width: 90,
    },
  },
  {
    field: 'insurance_plan_code',
    title: 'Plan Code',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'insurance_plan_name',
    title: 'Plan Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'insurance_payor_code',
    title: 'Payor Code',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'insurance_payor_name',
    title: 'Payor Name',
    isShownByDefault: true,
    isEditable: false,
    tooltip: true,
  },
  {
    field: 'insurance_financial_class_code',
    title: 'FC Code',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      width: 130,
    },
  },
  {
    field: 'insurance_financial_class_name',
    title: 'FC Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      width: 140,
    },
  },
  {
    field: 'ch_insurance_category_id',
    title: 'Category ID',
    sorter: NUMERIC_SORTER,
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'category_name',
    title: 'Category',
    isShownByDefault: true,
    isEditable: true,
    editor: LIST_EDITOR,
    editorParams: {
      values: [],
      autoComplete: false,
    },
    additionalConfig: {
      cssClass: 'highlight-col',
      width: 155,
    },
  },
  {
    field: 'insurance_city',
    title: 'City',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_state',
    title: 'State',
    formatOfData: customFormatTypes.state,
    isShownByDefault: false,
    isEditable: false,
    additionalConfig: {
      width: 115,
    },
  },
  {
    field: 'insurance_zip',
    title: 'Zip',
    isShownByDefault: false,
    isEditable: false,
    additionalConfig: {
      width: 115,
    },
  },
  {
    field: 'insurance_address',
    title: 'Address',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'create_date',
    title: 'Create Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
  {
    field: 'update_date',
    title: 'Update Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
  {
    field: 'client_id',
    title: 'Client Id',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 135,
    },
  },
];

export default columnConfig;
