import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import React, { useRef, useState, useEffect } from 'react';

import avatarDefaultImage from 'assets/images/profile-icon.svg';

const Dropdown = (props) => {
  const [open, setOpen] = useState(false);

  const toggleHandler = () => {
    return setOpen(!open);
  };

  const detectOutsideClick = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  detectOutsideClick(wrapperRef);

  return (
    <>
      <div
        role="button"
        className="dropdown ml-auto"
        onClick={toggleHandler}
        ref={wrapperRef}
      >
        <div className="dropdown__header">
          <span className="avatar">
            <img src={avatarDefaultImage} alt="" />
          </span>
          <span className="dropdown__title">{props.label}</span>
        </div>

        {open && (
          <div className="dropdown__list">
            <Scrollbars style={{ height: '300px' }}>
              <ul>
                {props.dropdownItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <button onClick={props.onClick} id={item.id}>
                        <span className="profile-img">
                          <img src={avatarDefaultImage} alt="hsp image" />
                        </span>
                        {item.value}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </Scrollbars>
          </div>
        )}
      </div>
    </>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
  dropdownItems: PropTypes.array,
};

export default Dropdown;
