import { EXPLORE_BUTTON_COLUMN } from 'components/common/table/constants';
import { formatCurrency, formatDate } from 'utils/common/formatter';

export const accountQueueColumnConfig = [
  {
    field: 'account_number',
    title: 'Account Number',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_type: 'text',
      filter_key: 'Enter',
    },
  },
  {
    field: 'location_name',
    title: 'Location Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_type: 'text',
      filter_key: 'Enter',
    },
  },
  {
    field: 'discharge_date',
    title: 'Discharge Date',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      formatter: (cell, _param, _onRender) => {
        return formatDate(cell.getValue());
      },
      filter_type: 'date',
      filter_key: 'Enter',
    },
  },
  {
    field: 'total_account_balance',
    title: 'Total Account Balance',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_type: 'number',
      filter_key: 'Enter',
    },
  },
  {
    field: 'patient_balance',
    title: 'Patient Balance',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_type: 'number',
      filter_key: 'Enter',
    },
  },
  {
    field: 'bill_reference_balance',
    title: 'Bill Reference Balance',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_type: 'number',
      filter_key: 'Enter',
      formatter: (cell, _param, _onRender) => {
        return formatCurrency(cell.getValue());
      },
    },
  },
  {
    field: 'insurance_payor_name',
    title: 'Insurance Payor',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_type: 'text',
      filter_key: 'Enter',
    },
  },
  {
    field: 'insurance_financial_class_name',
    title: 'Insurance Financial Class',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_type: 'text',
      filter_key: 'Enter',
    },
  },
  {
    field: 'current_financial_class_name',
    title: 'Current Financial Class',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_type: 'text',
      filter_key: 'Enter',
    },
  },
  {
    field: 'last_work_user',
    title: 'User Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_type: 'text',
      filter_key: 'Enter',
    },
  },
  {
    field: 'exemption',
    title: 'Exemption',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_key: 'Enter',
    },
  },
  {
    field: 'rule_hit',
    title: 'Rule Hit',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      filter_key: 'Enter',
    },
  },
  {
    field: EXPLORE_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      resizable: false,
      width: 32,
      cssClass: 'action-btn-cell text-right',
    },
  },
];
