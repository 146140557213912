import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const styles = {
  menuList: (base) => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
};

const SelectBox = (props) => {
  return (
    <>
      <Select
        styles={styles}
        className={`${props.className} text-left`}
        classNamePrefix="react-select"
        isSearchable={props.isSearchable}
        options={props.options}
        components={{ Placeholder, IndicatorSeparator: () => null }}
        placeholder={props.placeholder}
        IndicatorSeparator={false}
        onInputChange={props.onInputChange}
        onChange={props.onChange}
        value={props.value}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        filterOption={props.filterOption}
      />
      {props.error ? (
        <p className="user-form__error-msg text-sm mt-1x">{props.error}</p>
      ) : null}
    </>
  );
};

SelectBox.defaultProps = {
  isDisabled: false,
  isLoading: false,
};

SelectBox.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  filterOption: PropTypes.func,
  isSearchable: PropTypes.bool,
  onInputChange: PropTypes.string,
  error: PropTypes.string,
};

export default SelectBox;
