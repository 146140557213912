import http from 'utils/http';
import config from './../config';

import { clear } from 'utils/storage';

export async function login(body) {
  const url = config.endpoints.login;

  const { data } = await http.post(url, body);

  return data.data;
}

export async function refresh(refreshToken) {
  const url = config.endpoints.refresh;

  const data = await http.post(url, {
    refresh_token: refreshToken,
  });

  return data;
}

export function logOut() {
  clear();
}
