import { customFormatTypes } from 'constants/formatConfig';
import {
  DATE_SORTER,
  NUMERIC_SORTER,
  AMOUNT_SORTER,
} from 'components/common/table/constants';

export const columnConfig = [
  {
    field: 'project_validation_check_id',
    title: 'Project Validation',
    isEditable: false,
    isShownByDefault: true,
    customSorter: NUMERIC_SORTER,
    additionalConfig: {
      headerSort: false,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'check_name',
    title: 'Check Name',
    isEditable: false,
    isShownByDefault: true,
    additionalConfig: {
      headerSort: false,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'priority',
    title: 'Priority',
    isEditable: false,
    isShownByDefault: true,
    customSorter: NUMERIC_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'project_id',
    title: 'Project ID',
    isEditable: false,
    isShownByDefault: true,
    customSorter: NUMERIC_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'count_account',
    title: 'Account Count',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.numericWithCommas,
    customSorter: NUMERIC_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'balance_account',
    title: 'Total Balance',
    isShownByDefault: true,
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'create_date',
    title: 'Create Date',
    isShownByDefault: true,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
];
