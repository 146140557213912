import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icons/Icons';
import { createRoot } from 'react-dom/client';

class CustomTrashButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className="btn-rounded-icon">
        <Icon className="icon-trash" icon="trash" size={14} color="#808285" />
      </button>
    );
  }
}

export const trashButtonFormatter = (cell, formatterParams, onRendered) => {
  const container = document.createElement('div');
  container.classList.add('actions-btn');
  const root = createRoot(container);

  root.render(
    <CustomTrashButton
      cell={cell}
      formatterParams={formatterParams}
      onRendered={onRendered}
    />
  );

  return container;
};

CustomTrashButton.propTypes = {
  onOpen: PropTypes.func,
};

export default CustomTrashButton;
