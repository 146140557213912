import React from 'react';
import PropTypes from 'prop-types';
import { RotatingLines } from 'react-loader-spinner';

const Loader = (props) => {
  return (
    <div className={props.isFullScreen ? 'full__screen__loader' : ''}>
      <RotatingLines
        strokeColor="#01263C"
        strokeWidth="5"
        animationDuration="0.75"
        width="50"
        visible={true}
        isFullScreen={props.isFullScreen}
      />
    </div>
  );
};

Loader.propTypes = {
  isFullScreen: PropTypes.bool,
};

export default Loader;
