import React from 'react';
import { createRoot } from 'react-dom/client';

import classnames from 'classnames';

import Pill from 'components/common/pill/Pill';

import { PILL_TYPES, PILL_VARIANT } from 'constants/pillConfig';

export const userManagementPillFormatter = (
  cell,
  _formatterParams,
  _onRendered
) => {
  const container = document.createElement('div');
  const root = createRoot(container);

  const roleBackground = {
    admin: 'bg-primary--15',
    manager: 'bg-lightbeige--80',
    staff: 'bg-grey--15',
  };

  const pillClassName = classnames(
    'user-management-pill',
    roleBackground[cell.getValue().toLowerCase()] || 'bg-grey--15'
  );

  root.render(
    <div
      style={{
        textTransform: 'capitalize',
      }}
    >
      <Pill
        className={pillClassName}
        label={cell.getValue()}
        type={PILL_TYPES.CHIP}
        variant={PILL_VARIANT.REGULAR}
        labelClass="color-primary--base"
      />{' '}
    </div>
  );

  return container;
};
