import { customFormatTypes } from 'constants/formatConfig';
import {
  CHECKBOX_COLUMN,
  DATE_SORTER,
  NUMERIC_SORTER,
} from 'components/common/table/constants';

export const RULE_BASE_ID = 'rule_base_id';
export const PROJECT_ID = 'project_id';

export const columnConfig = [
  {
    field: CHECKBOX_COLUMN,
    title: '',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      tooltip: false,
      headerTooltip: false,
      headerPopupIcon: '',
      formatter: 'rowSelection',
      titleFormatter: 'rowSelection',
      hozAlign: 'center',
      headerHozAlign: 'center',
      headerSort: false,
      width: 50,
      minWidth: 50,
    },
  },
  {
    field: 'rule_name',
    title: 'Rule Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'priority',
    title: 'Priority',
    formatOfData: customFormatTypes.noFormat,
    sorter: NUMERIC_SORTER,
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'rule_state',
    title: 'Rule State',
    isShownByDefault: true,
    formatOfData: customFormatTypes.state,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'rule_category',
    title: 'Category',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'create_date',
    title: 'Created Date',
    isShownByDefault: true,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
];
