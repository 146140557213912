import { rerunButtonFormatter } from './CustomRerunButton';
import { customFormatTypes } from 'constants/formatConfig';

import {
  NUMERIC_SORTER,
  CELL_ALIGN_RIGHT,
  CELL_ALIGN_CENTER,
  RERUN_BUTTON_COLUMN,
  TRASH_BUTTON_COLUMN,
  CUSTOM_SUM_CALCULTOR,
  VIEWDETAIL_BUTTON_COLUMN,
  AMOUNT_SORTER,
} from 'components/common/table/constants';

export const RULE_NAME = 'rule_name';
export const TOTAL_HITS = 'total_hits';
export const TOTAL_BALANCE = 'total_balance';
export const EXCLUSIVE_HITS = 'exclusive_hits';
export const EXCLUSIVE_BALANCE = 'exclusive_balance';
export const PROJECT_RULE_BASE_ID = 'project_rule_base_id';

export const fieldsToCheck = [
  TOTAL_HITS,
  TOTAL_BALANCE,
  EXCLUSIVE_HITS,
  EXCLUSIVE_BALANCE,
];

export const QAColumnConfig = [
  {
    field: RULE_NAME,
    title: 'Rule Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },

  {
    field: 'rule_category',
    title: 'Rule Type',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      width: 145,
    },
  },

  {
    field: TOTAL_HITS,
    title: 'Total Hits #',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.numericWithCommas,
    sorter: NUMERIC_SORTER,
    customTotalCalculator: CUSTOM_SUM_CALCULTOR,
    additionalConfig: {
      width: 150,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      hozAlign: CELL_ALIGN_RIGHT,
      headerHozAlign: CELL_ALIGN_RIGHT,
    },
  },

  {
    field: TOTAL_BALANCE,
    title: 'Total Hits $',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
    customTotalCalculator: CUSTOM_SUM_CALCULTOR,
    additionalConfig: {
      width: 150,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      hozAlign: CELL_ALIGN_RIGHT,
      headerHozAlign: CELL_ALIGN_RIGHT,
    },
  },
  {
    field: 'rank',
    title: 'Rank',
    isShownByDefault: true,
    isEditable: false,
    sorter: NUMERIC_SORTER,
    additionalConfig: {
      width: 120,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      hozAlign: CELL_ALIGN_CENTER,
      headerHozAlign: CELL_ALIGN_CENTER,
    },
  },
  {
    field: EXCLUSIVE_HITS,
    title: 'Exclusive Total #',
    isShownByDefault: true,
    formatOfData: customFormatTypes.numericWithCommas,
    sorter: NUMERIC_SORTER,
    customTotalCalculator: CUSTOM_SUM_CALCULTOR,
    isEditable: false,
    additionalConfig: {
      width: 200,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      hozAlign: CELL_ALIGN_RIGHT,
      headerHozAlign: CELL_ALIGN_RIGHT,
    },
  },
  {
    field: EXCLUSIVE_BALANCE,
    title: 'Exclusive Total $',
    isShownByDefault: true,
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
    customTotalCalculator: CUSTOM_SUM_CALCULTOR,
    isEditable: false,
    additionalConfig: {
      width: 200,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      hozAlign: CELL_ALIGN_RIGHT,
      headerHozAlign: CELL_ALIGN_RIGHT,
    },
  },
  {
    field: VIEWDETAIL_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      resizable: false,
      width: 32,
      minWidth: 32,
      cssClass: 'action-btn-cell text-center',
    },
  },
  {
    field: RERUN_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      formatter: rerunButtonFormatter,
      resizable: false,
      width: 32,
      minWidth: 32,
      cssClass: 'action-btn-cell text-center',
    },
  },
  {
    field: TRASH_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      resizable: false,
      width: 32,
      minWidth: 32,
      cssClass: 'action-btn-cell text-center',
    },
  },
];

export const aggregateColumnConfig = [
  {
    field: 'rule_category',
    title: 'Rule Type',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'total_hits',
    title: 'Total Hits #',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.numericWithCommas,
    sorter: NUMERIC_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      headerHozAlign: CELL_ALIGN_CENTER,
      hozAlign: CELL_ALIGN_CENTER,
      width: 160,
    },
  },
  {
    field: 'total_balance',
    title: 'Total Hits $',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.currencyWithoutSign,
    sorter: AMOUNT_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      width: 160,
      headerHozAlign: CELL_ALIGN_RIGHT,
      hozAlign: CELL_ALIGN_RIGHT,
    },
  },
];
