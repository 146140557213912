import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import columnConfig from './columnConfig';
import { tableConfig } from './tableConfig';

import { ADD_USER, EDIT_USER } from 'constants/routes';
import { REFRESH_PAGE_ERROR_MESSAGE } from 'constants/errorMessages';

import Toast from 'components/common/toast/Toast';
import Table from 'components/common/table/Table';
import Loader from 'components/common/loader/Loader';

import { can } from 'utils/userManagement';
import { interpolate } from 'utils/common/string';
import { getUsersWithClients } from 'services/users';
import { ADD_ALL_USER, ADD_USER_BY_TIER } from 'constants/permissions';

function UserManagement() {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const buttonClassname = classNames({
    'btn btn-primary': true,
  });

  const getData = async () => {
    try {
      setIsLoading(true);

      const usersWithClients = await getUsersWithClients();
      setTableData(usersWithClients);
    } catch (error) {
      const errorMessage =
        error.response.data.detail || REFRESH_PAGE_ERROR_MESSAGE;

      setErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const resetError = () => {
    setErrorMessage(null);
  };

  const handleEditButtonClick = (_, cell) => {
    const rowData = cell.getRow().getData();

    const editUserRoute = interpolate(EDIT_USER, { userId: rowData.user_id });

    navigate(editUserRoute);
  };

  return (
    <>
      <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
        <div className="d-flex justify-content-between align-items-end mb-3x">
          <h1>User Management</h1>

          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div>
              {can([[ADD_ALL_USER], [ADD_USER_BY_TIER]]) && (
                <Link className={buttonClassname} to={ADD_USER}>
                  Add User
                </Link>
              )}
            </div>
          </form>
        </div>
      </div>
      <div>
        <div className="table has-box-shadow mb-6x">
          <Table
            className="has-box-shadow"
            data={tableData}
            tableConfig={tableConfig}
            columnConfig={columnConfig}
            handleEditButtonClick={handleEditButtonClick}
          />
          {isLoading && <Loader isFullScreen={true} />}
        </div>
      </div>
      {!!errorMessage && (
        <Toast
          title={errorMessage}
          hasError={!!errorMessage}
          handleClose={resetError}
        />
      )}
    </>
  );
}

export default UserManagement;
