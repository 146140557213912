import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

const ActionButton = ({ onClick, title, disabled, isLoading, className }) => {
  const buttonClassNames = classNames(
    'btn-outlined btn-evergreen--outlined action-button',
    {
      [`${className}`]: className,
    }
  );

  const handleClick = async () => {
    await onClick();
  };

  return (
    <button
      className={buttonClassNames}
      onClick={handleClick}
      disabled={isLoading || disabled}
    >
      {title}
      {isLoading && <span className="spinner" />}
    </button>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

export default ActionButton;
