import { useSelector } from 'react-redux';
import { React, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { dataHintsResultColumnConfig } from './configFiles/columnConfigs';
import { dataHintsResultsTableConfig } from './configFiles/tableConfigs';

import Table from 'components/common/table/Table';
import Loader from 'components/common/loader/Loader';
import { Notify } from 'components/common/notify/Notify';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import { getMatchedDataHintRule } from 'services/accountInformation';

import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

const DataHintsResultsTable = () => {
  const selectedClientId = useSelector((state) => state.selectedClientId);
  const selectedAccountNumber = useSelector((state) => state.accountNumber);

  const [dataHintsResults, setDataHintsResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldTableRender, setShouldTableRender] = useState(false); // for force re-render of table

  const [columnsConfig, setColumnsConfig] = useState(
    dataHintsResultColumnConfig
  );

  const [updatedColumnConfig, setUpdatedColumnConfig] = useState({
    field: '',
    isShownByDefault: false,
  });

  const fetchDataHintsResults = async () => {
    if (!selectedClientId || !selectedAccountNumber) {
      return;
    }
    setIsLoading(true);
    try {
      const result = await getMatchedDataHintRule(
        selectedAccountNumber,
        selectedClientId
      );
      setDataHintsResults(result);
      setShouldTableRender((prev_state) => !prev_state);
    } catch (error) {
      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataHintsResults();
  }, [selectedClientId, selectedAccountNumber]);

  const handleColumnSelect = (selectedColumnField) => {
    let isSelected = false;
    columnsConfig.forEach((col) => {
      if (col.field === selectedColumnField) {
        isSelected = col.isShownByDefault;
      }
    });

    setColumnsConfig((prevConfig) =>
      prevConfig.map((col) => {
        return col.field === selectedColumnField
          ? { ...col, isShownByDefault: !col.isShownByDefault }
          : col;
      })
    );
    setUpdatedColumnConfig({
      field: selectedColumnField,
      isShownByDefault: !isSelected,
    });
  };

  return (
    <>
      <div className="d-flex justify-content-end align-items-center column-selector">
        {selectedClientId && (
          <CustomColumnSelector
            className={'dropdown__right'}
            columnConfig={dataHintsResultColumnConfig}
            handleSelect={handleColumnSelect}
            selectedColumns={columnsConfig
              .filter((col) => col.isShownByDefault)
              .map((col) => col.field)}
          />
        )}
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center p-5x">
          <Loader />
        </div>
      ) : (
        <div className="table">
          <Table
            className="has-box-shadow"
            data={dataHintsResults}
            hasNewData={shouldTableRender}
            tableConfig={dataHintsResultsTableConfig}
            columnConfig={dataHintsResultColumnConfig}
            updatedColumnConfig={updatedColumnConfig}
            selectedColumns={columnsConfig
              .filter((col) => col.isShownByDefault)
              .map((col) => col.field)}
          />
        </div>
      )}
    </>
  );
};

DataHintsResultsTable.propTypes = {
  shouldFetchNewData: PropTypes.bool,
};

export default DataHintsResultsTable;
