import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SelectBox from 'components/common/select/Select';

const AlgorithmModal = ({
  isEditing,
  name,
  state,
  onSave,
  onClose,
  category,
  priority,
  isSaving,
  onUpdate,
  handleSelect,
  categoryList,
  handleChange,
  ruleDefinition,
}) => {
  const inputClassname = classNames({
    form__control: true,
  });

  const modalTitle = isEditing ? 'Edit Algorithm' : 'Add New Algorithm';

  return (
    <>
      <h2 className="mb-3x">{modalTitle}</h2>

      <form
        action=""
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="form-group mb-6x">
          <label className="form__label">Name</label>
          <input
            className={inputClassname}
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            placeholder="Enter Name"
          />
        </div>
        <div className="form-group mb-6x">
          <label className="form__label">Category</label>
          <SelectBox
            options={categoryList}
            value={category}
            onChange={handleSelect}
            placeholder="Select Category"
          />
        </div>

        <div className="row mb-6x">
          <div className="col-6">
            <div className="form-group">
              <label className="form__label">Priority</label>
              <input
                className={inputClassname}
                type="number"
                name="priority"
                onChange={handleChange}
                value={priority}
                placeholder="Select Priority"
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label className="form__label">State</label>
              <input
                className={`${inputClassname} text-uppercase`}
                type="text"
                name="state"
                value={state}
                onChange={handleChange}
                placeholder="Enter State"
              />
            </div>
          </div>
        </div>

        <div className="form-group mb-6x">
          <label className="form__label">Rule Definition</label>
          <textarea
            className={inputClassname}
            name="ruleDefinition"
            onChange={handleChange}
            placeholder="Write rule definition here..."
            value={ruleDefinition}
            rows={10}
          />
        </div>

        <div className="actions d-flex gap-2x mb-3x">
          {isEditing ? (
            <button className="btn btn-primary has-loader" onClick={onUpdate}>
              Update
              {isSaving && <span className="spinner" />}
            </button>
          ) : (
            <button className="btn btn-primary has-loader" onClick={onSave} disabled={isSaving}>
              Save
              {isSaving && <span className="spinner" />}
            </button>
          )}
          <button className="btn btn-link" onClick={onClose} disabled={isSaving}>
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

AlgorithmModal.propTypes = {
  name: PropTypes.string,
  onSave: PropTypes.func,
  state: PropTypes.string,
  onClose: PropTypes.func,
  isSaving: PropTypes.bool,
  onUpdate: PropTypes.func,
  isEditing: PropTypes.bool,
  priority: PropTypes.number,
  category: PropTypes.string,
  handleSelect: PropTypes.func,
  handleChange: PropTypes.func,
  ruleDefinition: PropTypes.string,
  categoryList: PropTypes.arrayOf(PropTypes.object),
};
export default AlgorithmModal;
