import React from 'react';

import { createRoot } from 'react-dom/client';

class CustomErrorButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className="action-link color-danger--base text-underline">
        Errors
      </button>
    );
  }
}

export const showErrorFormatter = (cell, formatterParams, onRendered) => {
  const container = document.createElement('div');
  container.classList.add('actions-btn');
  const root = createRoot(container);

  root.render(
    <CustomErrorButton
      cell={cell}
      formatterParams={formatterParams}
      onRendered={onRendered}
    />
  );

  return container;
};

export default CustomErrorButton;
