import React from 'react';
import { createRoot } from 'react-dom/client';

import Icon from '../icons/Icons';

class CustomExploreButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <button className="btn-circle">
          <Icon icon="explore" size={16} color="#808285" />
        </button>
      </>
    );
  }
}

export const exploreButtonFormatter = (cell, formatterParams, onRendered) => {
  const container = document.createElement('div');
  container.classList.add('actions-btn');
  const root = createRoot(container);

  root.render(
    <CustomExploreButton
      cell={cell}
      formatterParams={formatterParams}
      onRendered={onRendered}
    />
  );

  return container;
};

export default CustomExploreButton;
