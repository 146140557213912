export const algorithmFormInputs = {
  name: 'Name',
  category: 'Category',
  priority: 'Priority',
  state: 'State',
  ruleDefinition: 'Rule Definition',
};

export const projectFormInputs = {
  projectName: 'Project Name',
  assignee: 'Assignee',
  analysisDate: 'Analysis Date',
};

export const statementFormInputs = {
  statementName: 'Statement Name',
};
