import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/common/icons/Icons';
import Toast from 'components/common/toast/Toast';

import { updatePassword } from 'services/users';

import * as errorMessages from 'constants/errorMessages';

const newPasswordKey = 'newPassword';
const reEnteredPasswordKey = 'reEnteredPassword';

class UserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: '',
      isUpdating: false,
      [newPasswordKey]: '',
      [reEnteredPasswordKey]: '',
      isPasswordVisible: false,
      isReEnteredPasswordVisible: false,
    };
  }

  togglePasswordVisibility = (field) => {
    if (field === newPasswordKey) {
      this.setState({
        isPasswordVisible: !this.state.isPasswordVisible,
      });
    }

    if (field === reEnteredPasswordKey) {
      this.setState({
        isReEnteredPasswordVisible: !this.state.isReEnteredPasswordVisible,
      });
    }
  };

  updatePasswordHandler = async () => {
    const { newPassword, reEnteredPassword } = this.state;

    if (!newPassword) {
      this.setState({
        hasError: true,
        errorMessage: errorMessages.NO_PASSWORD,
      });

      return;
    }

    if (!reEnteredPassword) {
      this.setState({
        hasError: true,
        errorMessage: errorMessages.NO_REENTERED_PASSWORD,
      });

      return;
    }

    if (newPassword !== reEnteredPassword) {
      this.setState({
        hasError: true,
        errorMessage: errorMessages.PASSWORDS_DO_NOT_MATCH,
      });

      return;
    }

    this.setState({
      isUpdating: true,
      hasError: false,
      errorMessage: '',
    });

    if (!this.props.userId) {
      return;
    }

    try {
      await updatePassword(this.props.userId, newPassword);

      this.setState({
        isUpdating: false,
      });

      this.props.onClose();
    } catch (error) {
      this.setState({
        isUpdating: false,
      });
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleCloseToast = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      hasError,
      isUpdating,
      newPassword,
      errorMessage,
      reEnteredPassword,
      isPasswordVisible,
      isReEnteredPasswordVisible,
    } = this.state;
    const passwordClassname = classNames({
      'form__control form__ontrol--password': true,
    });

    const passwordVisibilityBtnClassname = classNames({
      'icon-eye': true,
      'icon-eye--closed d-block': !isPasswordVisible,
    });
    const reEnteredVisibilityBtnClassname = classNames({
      'icon-eye': true,
      'icon-eye--closed d-block': !isReEnteredPasswordVisible,
    });

    const passwordIcon = isPasswordVisible ? 'eye' : 'eyeClosed';
    const reEnteredPasswordIcon = isReEnteredPasswordVisible
      ? 'eye'
      : 'eyeClosed';

    return (
      <>
        <h4>Change Password</h4>
        <p className="mb-5x text-lg">{this.props.email}</p>

        <form
          action=""
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div className="form-group mb-8x">
            <label className="form__label">New Password</label>
            <input
              name={newPasswordKey}
              value={newPassword}
              className={passwordClassname}
              type={!isPasswordVisible ? 'password' : 'text'}
              onChange={this.handleInputChange}
              placeholder="Enter New Password"
              autoComplete="on"
            />

            <button
              className={passwordVisibilityBtnClassname}
              type="button"
              onClick={() => this.togglePasswordVisibility(newPasswordKey)}
            >
              <Icon icon={passwordIcon} size={14} color="#A8A9AD" />
            </button>
          </div>

          <div className="form-group mb-8x">
            <label className="form__label">Re-enter New Password</label>
            <input
              name={reEnteredPasswordKey}
              value={reEnteredPassword}
              className={passwordClassname}
              type={!isReEnteredPasswordVisible ? 'password' : 'text'}
              onChange={this.handleInputChange}
              placeholder="Re-enter New Password"
              autoComplete="on"
            />

            <button
              className={reEnteredVisibilityBtnClassname}
              type="button"
              onClick={() =>
                this.togglePasswordVisibility(reEnteredPasswordKey)
              }
            >
              <Icon icon={reEnteredPasswordIcon} size={14} color="#A8A9AD" />
            </button>
          </div>

          <div className="actions d-flex justify-content-end gap-2x">
            <button className="btn btn-link" onClick={this.props.onClose}>
              Cancel
            </button>
            <button
              className="btn btn-primary has-loader"
              onClick={this.updatePasswordHandler}
            >
              Update {isUpdating && <span className="spinner" />}
            </button>
          </div>
        </form>

        {hasError && (
          <Toast
            title={errorMessage}
            hasError={hasError}
            handleClose={this.handleCloseToast}
          />
        )}
      </>
    );
  }
}

export default UserModal;

UserModal.propTypes = {
  email: PropTypes.string,
  onClose: PropTypes.func,
  onPasswordUpdate: PropTypes.func,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
