import React from 'react';
import PropTypes from 'prop-types';

const InformationCard = (props) => {
  return (
    <>
      <h6 className="rowlist__header mb-3x">{props.title}</h6>
      <ul className="rowlist__lists">
        {props.informationList.map((item, index) => {
          return (
            <li className="rowlist__item" key={index}>
              <span
                className="rowlist__label"
                style={{ width: `${props.labelWidth}px` }}
              >
                {item.label}
              </span>
              <span className="rowlist__value">{item.value}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

InformationCard.propTypes = {
  informationList: PropTypes.array,
  label: PropTypes.string,
  labelWidth: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.string,
};

export default InformationCard;
