import React from 'react';
import PropTypes from 'prop-types';

const FormLabel = ({ label, required }) => {
  return (
    <label className="form__label">
      {label} {required ? <span className="color-danger--base">*</span> : null}
    </label>
  );
};

FormLabel.defaultProps = {
  required: false,
};

FormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default FormLabel;
