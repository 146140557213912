import React from 'react';

import Icon from '../icons/Icons';
import { createRoot } from 'react-dom/client';

class CustomReplayButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className="btn-rounded-icon">
        <Icon className="icon-replay" icon="replay" size={14} color="#808285" />
      </button>
    );
  }
}

export const replayButtonFormatter = (cell, formatterParams, onRendered) => {
  const container = document.createElement('div');
  container.classList.add('actions-btn');
  const root = createRoot(container);

  root.render(
    <CustomReplayButton
      cell={cell}
      formatterParams={formatterParams}
      onRendered={onRendered}
    />
  );

  return container;
};

export default CustomReplayButton;
