import { PERMISSIONS } from 'constants/storage';
import { get } from './storage';

/**
 * Determine if required permissions for a user are met
 * @param {Array<Array<(string | function)>>} required_permissions
 * Nested array of permissions where the outer array will be checked for union and inner array will be checked for intersection to compute boolean.
 * @returns {boolean} - Returns true if the required permissions are met, otherwise false.
 */
// TODO: Unit test for util function
const can = (required_permissions) => {
  // Sample required_permissions = [['can-edit-own-user', 'can-view-user'], ['can-add-user', 'admin']];
  // Sample granted_permissions = ['can-edit-own-user', 'can-view-user'];
  // Sample required_permissions = [['can-edit-own-user', 'can-view-user', callback], ['can-edit-all-users'], ['can-edit-user-by-tier', callback]];

  const granted_permissions = get(PERMISSIONS);

  return required_permissions.some((permissions) => {
    return permissions.every((permission) => {
      if (typeof permission === 'function') return permission();

      return granted_permissions.includes(permission);
    });
  });

};

export {
  can
};
