import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { colors } from 'constants/colors';
import DataHintsForm from './DataHintsForm';
import { addDataHintSchema } from './schema';

import { addDataHints } from 'services/dataHints';

import { DATA_HINTS } from 'constants/routes';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import Icon from 'components/common/icons/Icons';
import { convertToAddDataHintPayload } from 'utils/dataHints';
import { Notify } from 'components/common/notify/Notify';

const AddDataHints = () => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, _setFormData] = useState({
    rule_name: '',
    rule_category: '',
    rule_definition: '',
    client_ids: [],
    rule_type: '',
  });

  const handleAdd = async (data) => {
    setIsSubmitting(true);
    try {
      const payload = convertToAddDataHintPayload(data);

      await addDataHints(payload);

      Notify.success({
        title: 'Data hint successfully added.',
      });
      navigate(DATA_HINTS);
    } catch (err) {
      Notify.error({
        title: err?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mt-6x">
        <button
          className="btn data-hints__back mr-8x"
          onClick={() => navigate(-1)}
        >
          <span>
            <Icon
              icon="arrowLeft"
              viewBox="0 0 16 16"
              size={12}
              color={colors.primary.base}
              className="mr-2x"
            />
          </span>
          Back
        </button>
        <h1>Add Data Hint</h1>
      </div>
      <DataHintsForm
        initialData={formData}
        onSubmit={handleAdd}
        validationSchema={addDataHintSchema}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default AddDataHints;
