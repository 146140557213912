import React from 'react';
import PropTypes from 'prop-types';

const QADeleteModal = ({ onClose, onDelete, ruleName, isDeleting }) => {
  return (
    <>
      <h3 className="mb-2x">
        Do you want to delete the rule “{ruleName}" and all it’s data?
      </h3>
      <p className="text-lg mb-5x">
        This action cannot be reverted back. Are you sure you want to delete the
        data from {ruleName}?
      </p>
      <div className="actions d-flex justify-content-end gap-2x mb-3x">
        <button className="btn btn-link" onClick={onClose}>
          Cancel
        </button>
        <button className="btn btn-danger has-loader" onClick={onDelete}>
          Delete
          {isDeleting && <span className="spinner" />}
        </button>
      </div>
    </>
  );
};

QADeleteModal.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  ruleName: PropTypes.string,
  isDeleting: PropTypes.bool,
};

export default QADeleteModal;
