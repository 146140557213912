import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { colors } from 'constants/colors';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import Icon from 'components/common/icons/Icons';
import Loader from 'components/common/loader/Loader';

import { getDataHintById, updateDataHint } from 'services/dataHints';

import DataHintsForm from './DataHintsForm';
import { updateDataHintSchema } from './schema';
import { convertToEditDataHintPayload } from 'utils/dataHints';
import { DATA_HINTS } from 'constants/routes';
import { Notify } from 'components/common/notify/Notify';

const EditDataHints = () => {
  const navigate = useNavigate();
  const { dataHintId } = useParams();

  const [dataHint, setDataHint] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchDataHint = async () => {
    setIsLoading(true);
    try {
      const dataHint = await getDataHintById(dataHintId);

      setDataHint(dataHint);
    } catch (error) {
      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (data) => {
    setIsSubmitting(true);
    try {
      const payload = convertToEditDataHintPayload(data);

      await updateDataHint(dataHintId, payload);
      Notify.success({
        title: 'Data hint successfully updated.',
      });
      navigate(DATA_HINTS);
    } catch (error) {
      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchDataHint();
  }, [dataHintId]);

  return (
    <>
      <div className="d-flex align-items-center mt-6x">
        <button
          className="btn data-hints__back mr-8x"
          onClick={() => navigate(-1)}
        >
          <span>
            <Icon
              icon="arrowLeft"
              viewBox="0 0 16 16"
              size={12}
              color={colors.primary.base}
              className="mr-2x"
            />
          </span>
          Back
        </button>
        <h1>Edit Data Hint</h1>
      </div>
      {isLoading ? (
        <Loader isFullScreen />
      ) : (
        <DataHintsForm
          initialData={dataHint}
          onSubmit={handleUpdate}
          validationSchema={updateDataHintSchema}
          isSubmitting={isSubmitting}
          isEdit
        />
      )}
    </>
  );
};

export default EditDataHints;
