import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { colors } from 'constants/colors';
import { PILL_TYPES, PILL_VARIANT } from 'constants/pillConfig';

import Icon from 'components/common/icons/Icons';

const Pill = ({
  label,
  variant,
  isSelected,
  onClick,
  onRemove,
  bgClassName,
  className,
  labelClass,
  type,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    if (onRemove) {
      onRemove();
    }
  };

  const pillClass = classnames('pill', {
    pill__chip: type === PILL_TYPES.CHIP,
    pill__outlined: variant === PILL_VARIANT.OUTLINED,
    [`${bgClassName}`]: bgClassName,
    pill__selected: isSelected,
    [`${className}`]: className,
  });

  return (
    <div role="button" onClick={handleClick} className={pillClass}>
      <p
        className={classnames({
          [`${labelClass}`]: labelClass,
        })}
      >
        {label}
      </p>
      {isSelected && (
        <button onClick={handleRemove}>
          <Icon
            icon="close"
            className="ml-2x"
            width={10}
            height={10}
            color={colors.grey[60]}
          />
        </button>
      )}
    </div>
  );
};

Pill.defaultProps = {
  isSelected: false,
  variant: PILL_VARIANT.OUTLINED,
  type: PILL_TYPES.PILL,
};

Pill.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(PILL_VARIANT)),
  type: PropTypes.oneOf(Object.values(PILL_TYPES)),
  bgClassName: PropTypes.string,
  labelClass: PropTypes.string,
};

export default Pill;
