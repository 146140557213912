import { CATEGORY_FIELD } from './columnConfigs';
import { STRING_TYPE } from 'constants/formatConfig';

export const VALUE_TO_HIGHLIGHT = 'UNMAPPED';

// index key is to uniquely identify row in the grid
export const tableConfig = {
  pagination: false,
  index: CATEGORY_FIELD,
};

export const highlightRowConfig = {
  field: CATEGORY_FIELD,
  values: [VALUE_TO_HIGHLIGHT],
  dataType: STRING_TYPE,
};
