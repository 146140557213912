
import http from 'utils/http';
import { interpolate } from 'utils/common/string';
import config from '../config';

export async function getLocations(clientId) {
  const url = interpolate(config.endpoints.locations, { clientId });
  const { data } = await http.get(url);

  return data.data;
}
