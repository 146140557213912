import {
  LOG_COLUMN,
  DATE_SORTER,
  ERROR_COLUMN,
  NUMERIC_SORTER,
} from 'components/common/table/constants';
import { customFormatTypes } from 'constants/formatConfig';

export const columnConfig = [
  {
    field: 'project_job_id',
    title: 'Job ID',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'project_job_execution_id',
    title: 'Job Execution ID',
    isShownByDefault: true,
    isEditable: false,
    sorter: NUMERIC_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'project_job_name',
    title: 'Job Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'project_job_status',
    title: 'Status',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'project_id',
    title: 'Project',
    isShownByDefault: true,
    isEditable: false,
    sorter: NUMERIC_SORTER,
  },
  {
    field: 'project_name',
    title: 'Project Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'project_job_start_time',
    title: 'Start Time',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.dateWithTime,
    customSorter: DATE_SORTER,
  },
  {
    field: 'project_job_end_time',
    title: 'End Time',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.dateWithTime,
    customSorter: DATE_SORTER,
  },
  {
    field: LOG_COLUMN,
    title: 'Actions',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerSort: false,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      width: 90,
      cssClass: 'action-logs-cell',
    },
  },
  {
    field: ERROR_COLUMN,
    title: '',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerSort: false,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      cssClass: 'pl-0x',
    },
  },
];
