import { useSelector } from 'react-redux/';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef, React } from 'react';

import Table from 'components/common/table/Table';
import EmptyPage from 'components/common/emptypage/EmptyPage';
import ClientDropDown from 'components/common/dropdown/clientDropDown';

import { getDataHints, updateDataHint } from 'services/dataHints';

import { tableConfig } from './tableConfig';
import { columnConfig } from './columnConfig';

import Loader from 'components/common/loader/Loader';
import SwitchButton from 'components/common/switch/SwitchButton';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import {
  DEFAULT_ERROR_MESSAGE,
  DATAHINT_UPDATE_FAILURE_MESSAGE,
  DATAHINT_UPDATE_RESET,
} from 'constants/errorMessages';
import { ADD_DATA_HINT, EDIT_DATA_HINT } from 'constants/routes';
import { DATAHINT_UPDATE_SUCCESS_MESSAGE } from 'constants/successMessage';
import { DATA_HINTS_FILTER, DATA_HINTS_RULE_TYPE } from 'constants/dataHints';

import { interpolate } from 'utils/common/string';
import { Notify } from 'components/common/notify/Notify';
import { dataHintHeaderConfig } from './dataHintHeaderConfig';

const DataHints = () => {
  const selectedClientId = useSelector((state) => state.selectedClientId);
  const clients = useSelector((state) => state.clientList);

  const [dataHints, setDataHints] = useState([]);
  const [selectedDataHint, setSelectedDataHint] = useState(null);
  const selectedDataHintDefinitionRef = useRef('');
  const [hasChanges, setHasChanges] = useState(false); // checks if rule-definition has changes
  const [isSubmitting, setIsSubmitting] = useState(false); // tracks for submission state
  const [tableRender, setTableRender] = useState(false); // for force re-render of table
  const [isLoading, setIsLoading] = useState(false);
  const [dataHintsCount, setDataHintsCount] = useState({});

  const selectedClient = clients.find(
    (client) => client.client_id === parseInt(selectedClientId)
  );

  const navigate = useNavigate();

  const [dataHintFilter, setDataHintFilter] = useState(
    DATA_HINTS_RULE_TYPE.ALL
  );
  const [columnsConfig, setColumnsConfig] = useState(columnConfig);
  const [updatedColumnConfig, setUpdatedColumnConfig] = useState({
    field: '',
    isShownByDefault: false,
  });

  const fetchDataHints = async () => {
    if (selectedClientId) {
      setIsLoading(true);
      try {
        const result = await getDataHints({
          client_id: selectedClientId,
          // only pass the rule_type param if the filter is not 'all'
          ...(dataHintFilter !== DATA_HINTS_RULE_TYPE.ALL && {
            rule_type: dataHintFilter,
          }),
        });
        setDataHints(result.data);
        setDataHintsCount(result.meta);
        setTableRender((prev_state) => !prev_state);
      } catch (error) {
        Notify.error({
          title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
        });
      } finally {
        setIsLoading(false);
        setSelectedDataHint(null);
      }
    }
  };

  useEffect(() => {
    fetchDataHints();
  }, [selectedClientId, dataHintFilter]);

  const handleRowClick = (_, row) => {
    const selectedRow = row.getData();
    setSelectedDataHint(selectedRow);
    selectedDataHintDefinitionRef.current = selectedRow.rule_definition;
  };

  const updateDataHintDefinitionRef = (event) => {
    setSelectedDataHint((prevState) => ({
      ...prevState,
      rule_definition: event.target.value,
    }));
    setHasChanges(selectedDataHintDefinitionRef.current !== event.target.value);
  };

  const handleUpdateBtnClick = async () => {
    try {
      setIsSubmitting(true);
      const updatePayload = {
        rule_definition: selectedDataHint.rule_definition,
      };

      await updateDataHint(selectedDataHint.data_hint_rule_id, updatePayload);
      await fetchDataHints();

      Notify.success({
        title: DATAHINT_UPDATE_SUCCESS_MESSAGE,
      });
    } catch (error) {
      Notify.error({
        title: DATAHINT_UPDATE_FAILURE_MESSAGE,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResetBtnClick = () => {
    setSelectedDataHint((dataHint) => ({
      ...dataHint,
      rule_definition: selectedDataHintDefinitionRef.current,
    }));
    setHasChanges(false);
    Notify.success({ title: DATAHINT_UPDATE_RESET });
  };

  const handleColumnSelect = (selectedColumnField) => {
    let isSelected = false;
    columnsConfig.forEach((col) => {
      if (col.field === selectedColumnField) {
        isSelected = col.isShownByDefault;
      }
    });

    setColumnsConfig((prevConfig) =>
      prevConfig.map((col) => {
        return col.field === selectedColumnField
          ? { ...col, isShownByDefault: !col.isShownByDefault }
          : col;
      })
    );
    setUpdatedColumnConfig({
      field: selectedColumnField,
      isShownByDefault: !isSelected,
    });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(selectedDataHint.rule_definition);
      Notify.success({
        title: 'Data definition successfully copied.',
      });
    } catch (error) {
      Notify.error({
        title: 'Unable to copy, error occured.',
      });
    }
  };

  const handleEditButtonClick = (_, cell) => {
    const rowData = cell.getRow().getData();

    const editDataHintsRoute = interpolate(EDIT_DATA_HINT, {
      dataHintId: rowData.data_hint_rule_id,
    });

    navigate(editDataHintsRoute);
  };

  return (
    <>
      <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
        <div className="profile mb-3x ml-auto">
          <ClientDropDown />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end mb-3x">
        <h1>
          Data Hint{' '}
          {selectedClient ? (
            <span className="data-hints__title">
              of {selectedClient?.display_name}
            </span>
          ) : null}
        </h1>
      </div>

      {!selectedClientId ? (
        <EmptyPage pageName="datahints" selectionParameters="client" />
      ) : (
        <>
          {isLoading && <Loader isFullScreen={true} />}
          <div
            className="d-flex flex-direction-column"
            style={{ height: 'calc(100vh - 143px)' }}
          >
            <div className="d-flex justify-content-between data-hints__filter-container">
              <div className="d-flex gap-3x">
                {DATA_HINTS_FILTER.map((filter) => (
                  <SwitchButton
                    badgeCount={dataHintsCount[filter.FIELD] || 0}
                    labelText={filter.LABEL}
                    hasSwitch={false}
                    className="switch-container--tabbed"
                    key={filter.VALUE}
                    value={dataHintFilter === filter.VALUE}
                    onClick={() => setDataHintFilter(filter.VALUE)}
                  />
                ))}
              </div>
              {selectedClientId ? (
                <CustomColumnSelector
                  className={'dropdown__right'}
                  columnConfig={columnsConfig}
                  handleSelect={handleColumnSelect}
                  selectedColumns={columnsConfig
                    .filter((col) => col.isShownByDefault)
                    .map((col) => col.field)}
                />
              ) : null}
            </div>
            <div className="table table-25vh has-box-shadow">
              <Table
                className="has-box-shadow"
                data={dataHints}
                hasNewData={tableRender}
                tableConfig={tableConfig}
                columnConfig={columnsConfig}
                onRowClick={handleRowClick}
                selectedColumns={columnsConfig
                  .filter((col) => col.isShownByDefault)
                  .map((col) => col.field)}
                updatedColumnConfig={updatedColumnConfig}
                handleEditButtonClick={handleEditButtonClick}
              />
            </div>

            <div className="d-flex justify-content-end align-items-center my-3x">
              <Link to={ADD_DATA_HINT} className="btn btn-primary">
                Add New Data Hint
              </Link>
            </div>

            <div className="textarea__box">
              <div className="d-flex justify-content-between align-items-center my-3x">
                <h3 className="font-secondary">Data Hint Definition</h3>
                {selectedDataHint && (
                  <button onClick={handleCopy} className="btn btn-primary">
                    Copy
                  </button>
                )}
              </div>
              {selectedDataHint ? (
                <>
                  <div className="d-flex bg-primary--10 data-hints__definition-header">
                    {dataHintHeaderConfig.map((header) => (
                      <div
                        key={header.source}
                        className="data-hints__definition-header-content px-7x py-3x"
                      >
                        <p className="text-left fw-700">{header.label}</p>
                        <p className="value fw-400">
                          {selectedDataHint[header.source]}
                        </p>
                      </div>
                    ))}
                  </div>
                  <textarea
                    className=""
                    value={selectedDataHint.rule_definition}
                    onChange={updateDataHintDefinitionRef}
                  />

                  <div className="actions d-flex justify-content-end mt-4x">
                    <button
                      className="btn btn-link"
                      onClick={handleResetBtnClick}
                      disabled={!hasChanges}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-primary has-loader"
                      onClick={handleUpdateBtnClick}
                      disabled={!hasChanges || isSubmitting}
                    >
                      Update {isSubmitting && <span className="spinner" />}
                    </button>
                  </div>
                </>
              ) : (
                <EmptyPage sectionMessage={true} sectionParameters="any row" />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DataHints;
