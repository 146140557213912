import http from 'utils/http';
import config from '../config';
import { interpolate } from 'utils/common/string';

export async function getTransactionComboByClient(clientId, params = {}) {
  const transactionComboUrl = interpolate(config.endpoints.transactionCombo, { clientId });

  const { data } = await http.get(transactionComboUrl, {
    params: params,
  });

  return data.data;
}
