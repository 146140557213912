import * as formatConfig from 'constants/formatConfig';

import { convertCurrencyToNumber } from './string';

export const formatCurrency = (currency) => {
  let numericCurrencyValue = convertCurrencyToNumber(currency);

  if (numericCurrencyValue === null) {
    return '';
  }

  if (numericCurrencyValue === 0) {
    return '$0.00';
  }

  return numericCurrencyValue.toLocaleString(formatConfig.LOCALE, {
    style: formatConfig.CURRENCY,
    currency: formatConfig.USD,
  });
};

export const formatCurrencyWithoutSign = (currency) => {
  let numericCurrencyValue = convertCurrencyToNumber(currency);

  if (numericCurrencyValue === null) {
    return '';
  }

  if (numericCurrencyValue === 0) {
    return '0.00';
  }

  return numericCurrencyValue.toLocaleString(formatConfig.LOCALE);
};

export const formatNumberToLocaleString = (value) => {
  if (value === null) {
    return null;
  }

  if (typeof value === formatConfig.STRING_TYPE) {
    const commaRemoved = value.replaceAll(',', '');
    const parsedNumber = parseFloat(commaRemoved);

    if (isNaN(parsedNumber)) {
      return null;
    }

    return parsedNumber.toLocaleString(formatConfig.LOCALE);
  }

  return value.toLocaleString(formatConfig.LOCALE);
};

export const formatDate = (date) => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);
  const dateObjTime = dateObj.getTime();
  const timeAfterAdjustingOffset =
    dateObjTime + dateObj.getTimezoneOffset() * 60000;

  const formattedDate = new Date(timeAfterAdjustingOffset);

  return formattedDate.toLocaleDateString(formatConfig.LOCALE);
};

export const formatDateWithTime = (date) => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);
  const localeDateString = dateObj.toLocaleDateString(formatConfig.LOCALE);
  const localeTimeString = dateObj.toLocaleTimeString(
    formatConfig.LOCALE,
    formatConfig.DEFAULT_TIME_OPTIONS
  );

  return `${localeDateString} ${localeTimeString}`;
};

export const getAPIDateString = (date) => {
  if (!date) {
    return null;
  }

  const dateObj = new Date(date);

  return `${dateObj.getFullYear()}-${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}`;
};

export const getAPIDateTimeString = (date) => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);

  return `${dateObj.getFullYear()}-${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}T00:00:00`;
};

export const formatNullString = (stringValue) => {
  if (!stringValue) {
    return formatConfig.DEFAULT_NULL_STRING;
  }

  return stringValue;
};

export const formatBoolean = (value) => {
  if (!value) {
    return false;
  }

  if (typeof value === 'boolean') {
    return value;
  }

  const valueInString = value.toString().toLowerCase().trim();

  switch (valueInString) {
    case '1':
    case 'true':
      return true;
    case '0':
    case 'false':
      return false;
  }
};

export const formatState = (value) => {
  if (!value) {
    return formatConfig.DEFAULT_NULL_STRING;
  }

  return value.toUpperCase();
};

export const formatAnyValue = (format, value) => {
  switch (format) {
    case formatConfig.customFormatTypes.currency:
      return formatCurrency(value);

    case formatConfig.customFormatTypes.date:
      return formatDate(value);

    case formatConfig.customFormatTypes.boolean:
      return formatBoolean(value);

    case formatConfig.customFormatTypes.currencyWithoutSign:
      return formatCurrencyWithoutSign(value);

    case formatConfig.customFormatTypes.numericWithCommas:
      return formatNumberToLocaleString(value);

    case formatConfig.customFormatTypes.state:
      return formatState(value);

    case formatConfig.customFormatTypes.noFormat:
      return value;

    case formatConfig.customFormatTypes.dateWithTime:
      return formatDateWithTime(value);

    default:
      return formatNullString(value);
  }
};

export const formatNumber = (num) => {
  if (Math.abs(num) >= 1.0e9) {
    return (num / 1.0e9).toFixed(1) + 'B';
  } else if (Math.abs(num) >= 1.0e6) {
    return (num / 1.0e6).toFixed(1) + 'M';
  } else if (Math.abs(num) >= 1.0e3) {
    return (num / 1.0e3).toFixed(1) + 'k';
  } else {
    return num;
  }
};

export const formatDateToMonth = (dateString) => {
  const date = new Date(dateString);

  return date.toLocaleString('default', { month: 'short' }); // Short month name, e.g., "Apr"
};
