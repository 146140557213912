import { ERROR, ANALYSING } from 'constants/jobResponses';

export const NEW = 'New';
export const DATA = 'Data';
export const VALIDATION = 'Validation';
export const MAPPING = 'Mapping';
export const RULES = 'Rules';
export const DATAHINTS = 'Data Hints';
export const QA = 'QA';
export const PUBLISHED = 'Published';
export const INVOICED = 'Invoiced';
export const CLOSED = 'Closed';
export const DELETED = 'Deleted';

export const PROJECT_STATUS_OPTIONS = [
  NEW,
  DATA,
  VALIDATION,
  MAPPING,
  DATAHINTS,
  RULES,
  QA,
  PUBLISHED,
  INVOICED,
  CLOSED,
  DELETED,
];

export const JOB_STATUS_MAP = {
  JOB_SUBMITTED: ANALYSING,
  JOB_FAILED: ERROR,
};
