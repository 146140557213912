import { customFormatTypes } from 'constants/formatConfig';
import {
  NUMERIC_SORTER,
  CELL_ALIGN_CENTER,
  TOGGLE_CHECKBOX_COLUMN,
} from '../common/table/constants';

export const RANK = 'rank';
export const INCLUDE_REPORT = 'include_report';
export const EXCLUDE_DATA_CONCERNS = 'exclude_data_concerns';

const tickIcon =
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.6 11.8L6.425 9.625C6.24167 9.44167 6.01667 9.35 5.75 9.35C5.48333 9.35 5.25 9.45 5.05 9.65C4.86667 9.83333 4.775 10.0667 4.775 10.35C4.775 10.6333 4.86667 10.8667 5.05 11.05L7.9 13.9C8.08333 14.0833 8.31667 14.175 8.6 14.175C8.88333 14.175 9.11667 14.0833 9.3 13.9L14.975 8.225C15.1583 8.04167 15.25 7.81667 15.25 7.55C15.25 7.28333 15.15 7.05 14.95 6.85C14.7667 6.66667 14.5333 6.575 14.25 6.575C13.9667 6.575 13.7333 6.66667 13.55 6.85L8.6 11.8ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z" fill="#055D54"/></svg>  ';

const crossIcon =
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47727 0 0 4.47727 0 10C0 15.5227 4.47727 20 10 20C15.5227 20 20 15.5227 20 10C20 4.47727 15.5227 0 10 0ZM13.37 7.91545C13.5356 7.744 13.6272 7.51436 13.6252 7.276C13.6231 7.03764 13.5275 6.80963 13.3589 6.64107C13.1904 6.47252 12.9624 6.37691 12.724 6.37484C12.4856 6.37277 12.256 6.4644 12.0845 6.63L10 8.71455L7.91545 6.63C7.83159 6.54317 7.73128 6.47392 7.62037 6.42627C7.50946 6.37863 7.39016 6.35355 7.26946 6.3525C7.14875 6.35145 7.02904 6.37445 6.91731 6.42016C6.80559 6.46587 6.70409 6.53338 6.61873 6.61873C6.53338 6.70409 6.46587 6.80559 6.42016 6.91731C6.37445 7.02904 6.35145 7.14875 6.3525 7.26946C6.35355 7.39016 6.37863 7.50946 6.42627 7.62037C6.47392 7.73128 6.54317 7.83159 6.63 7.91545L8.71455 10L6.63 12.0845C6.54317 12.1684 6.47392 12.2687 6.42627 12.3796C6.37863 12.4905 6.35355 12.6098 6.3525 12.7305C6.35145 12.8513 6.37445 12.971 6.42016 13.0827C6.46587 13.1944 6.53338 13.2959 6.61873 13.3813C6.70409 13.4666 6.80559 13.5341 6.91731 13.5798C7.02904 13.6255 7.14875 13.6486 7.26946 13.6475C7.39016 13.6465 7.50946 13.6214 7.62037 13.5737C7.73128 13.5261 7.83159 13.4568 7.91545 13.37L10 11.2855L12.0845 13.37C12.256 13.5356 12.4856 13.6272 12.724 13.6252C12.9624 13.6231 13.1904 13.5275 13.3589 13.3589C13.5275 13.1904 13.6231 12.9624 13.6252 12.724C13.6272 12.4856 13.5356 12.256 13.37 12.0845L11.2855 10L13.37 7.91545Z" fill="#E76161"/></svg>';

export const columnConfig = [
  {
    field: 'rule_name',
    title: 'Rule Name',
    isEditable: false,
    isShownByDefault: true,
  },
  {
    field: 'include_report',
    columnType: TOGGLE_CHECKBOX_COLUMN,
    title: 'Include in Report',
    isEditable: false,
    isShownByDefault: true,
    formatOfData: customFormatTypes.boolean,

    additionalConfig: {
      width: 200,
      tooltip: false,
      headerSort: false,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      hozAlign: CELL_ALIGN_CENTER,
      headerHozAlign: CELL_ALIGN_CENTER,

      formatter: 'tickCross',
      formatterParams: {
        tickElement: tickIcon,
        crossElement: crossIcon,
      },
    },
  },

  {
    field: 'exclude_data_concerns',
    title: 'Exclude Data Concerns',
    columnType: TOGGLE_CHECKBOX_COLUMN,
    formatOfData: customFormatTypes.boolean,
    isEditable: false,
    isShownByDefault: true,

    additionalConfig: {
      width: 240,
      tooltip: false,
      headerSort: false,
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      hozAlign: CELL_ALIGN_CENTER,
      headerHozAlign: CELL_ALIGN_CENTER,

      formatter: 'tickCross',
      formatterParams: {
        tickElement: tickIcon,
        crossElement: crossIcon,
      },
    },
  },
  {
    field: 'rank',
    title: 'Rank',
    isEditable: false,
    isShownByDefault: true,
    formatOfData: customFormatTypes.number,

    additionalConfig: {
      width: 120,
      tooltip: false,
      headerPopup: false,
      sorter: NUMERIC_SORTER,
      headerFilter: false,
      headerPopupIcon: '',
      hozAlign: CELL_ALIGN_CENTER,
      headerHozAlign: CELL_ALIGN_CENTER,
    },
  },
  {
    field: 'rule_category',
    title: 'Rule Type',
    isEditable: false,
    isShownByDefault: true,

    additionalConfig: {
      width: 150,
      tooltip: false,
    },
  },
];
