import React from 'react';
import PropTypes from 'prop-types';
import EmptyIcon from 'assets/images/empty-icon.svg';

const EmptyPage = (props) => {
  return (
    <>
      <div
        className={
          props.className ? props.className : 'empty-page__content p-10x'
        }
      >
        <div className="icons-wrap text-center mb-7x">
          <img src={EmptyIcon} alt="Empty Icon" />
        </div>
        {props.message ? (
          <p className="text-center text-lg color-grey--80 fw-600">
            {props.message}
          </p>
        ) : props.sectionMessage ? (
          <p className="text-center text-lg color-grey--80 fw-600">
            {`Select ${props.sectionParameters} to see the information on`}
            <br />
            this section
          </p>
        ) : (
          <p className="text-center text-lg color-grey--80 fw-600">
            {`Please select the ${props.selectionParameters} from top right corner to view`}
            <br />
            {`the ${props.pageName} information.`}
          </p>
        )}
      </div>
    </>
  );
};

EmptyPage.propTypes = {
  className: PropTypes.string,
  sectionMessage: PropTypes.bool,
  sectionParameters: PropTypes.string,
  selectionParameters: PropTypes.string,
  pageName: PropTypes.string,
  message: PropTypes.string,
};

export default EmptyPage;
