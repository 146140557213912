import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';

import { ACCOUNT_INFORMATION } from 'constants/routes';

import { updateClientID, updateAccountNumber } from 'actions/clientAction';

const AccountInformationWithLink = () => {
  const { clientId, accountNumber } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateClientID(clientId));
    dispatch(updateAccountNumber(accountNumber));
  }, [dispatch]);

  return <Navigate to={ACCOUNT_INFORMATION} replace={true} />;
};

export default AccountInformationWithLink;
