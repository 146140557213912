import React from 'react';
import PropTypes from 'prop-types';

const DeleteModal = (props) => {
  return (
    <>
      <h3 className="mb-2x">{props.heading}</h3>
      <p className="mb-5x">{props.subHeading}</p>

      <div className="actions d-flex justify-content-end gap-2x mb-3x">
        <button className="btn btn-link" onClick={props.onCancel}>
          Cancel
        </button>
        <button className="btn btn-danger has-loader" onClick={props.onDelete}>
          Delete
          {props.isDeleting && <span className="spinner" />}
        </button>
      </div>
    </>
  );
};

DeleteModal.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  heading: PropTypes.string,
  isDeleting: PropTypes.bool,
  subHeading: PropTypes.string,
};

export default DeleteModal;
