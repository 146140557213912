import config from '../config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';

export async function getDataIntegrityData(accountId, clientId) {
  const url = interpolate(config.endpoints.dataIntegrityV1, {
    accountId,
    clientId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function getRuleHitsData(accountId, clientId) {
  const url = interpolate(config.endpoints.ruleHitsV1, {
    accountId,
    clientId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function getAccountInformation(accountId, clientId) {
  const url = interpolate(config.endpoints.accountInformationV1, {
    accountId,
    clientId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function getCrownComputedDates(accountId, clientId) {
  const url = interpolate(config.endpoints.crownComputedDatesV1, {
    accountId,
    clientId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function getCrownComputedTotals(accountId, clientId) {
  const url = interpolate(config.endpoints.crownComputedTotalsV1, {
    accountId,
    clientId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function getAccountFinancialsData(accountId, clientId) {
  const url = interpolate(config.endpoints.accountFinancialsV1, {
    accountId,
    clientId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function getAccountBillReferenceData(accountId, clientId) {
  const url = interpolate(config.endpoints.accountBillReferenceV1, {
    accountId,
    clientId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function getAccountInsuranceData(accountId, clientId) {
  const url = interpolate(config.endpoints.accountInsuranceV1, {
    accountId,
    clientId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function downloadReports(accountId, tableName, clientId) {
  const url = interpolate(config.endpoints.exportAccountData, {
    accountId,
    tableName,
    clientId,
  });

  const { data } = await http.get(url, {
    responseType: 'blob',
  });

  return data;
}

export async function getCrownComputedStatistics(accountId, clientId) {
  const url = interpolate(config.endpoints.crownComputedStatistics, {
    accountId,
    clientId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function getMatchedDataHintRule(accountId, clientId) {
  const url = interpolate(config.endpoints.matchedDataHintRules, {
    accountId,
    clientId,
  });
  const { data } = await http.get(url);

  return data.data;
}
