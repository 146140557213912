import { customFormatTypes } from 'constants/formatConfig';
import {
  DATE_SORTER,
  AMOUNT_SORTER,
  NUMERIC_SORTER,
  EDIT_BUTTON_COLUMN,
  EXPORT_BUTTON_COLUMN,
  EXPLORE_BUTTON_COLUMN,
} from 'components/common/table/constants';

export const PUBLISHED_DATE_COLUMN = 'publish_date';

export const columnConfig = [
  {
    field: 'project_id',
    title: 'Id',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'project_name',
    title: 'Project Name',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.noFormat,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'analysis_date',
    title: 'Analysis Date',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: PUBLISHED_DATE_COLUMN,
    title: 'Published Date',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      width: 175,
    },
  },
  {
    field: 'count_account',
    title: 'Total Accounts',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.numericWithCommas,
    sorter: NUMERIC_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'balance_account',
    title: 'Total Balance',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'status',
    title: 'Status',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      width: 115,
    },
  },
  {
    field: 'assignee',
    title: 'Assignee',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.noFormat,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      width: 140,
    },
  },
  {
    field: 'reviewer',
    title: 'Reviewer',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.noFormat,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
      width: 140,
    },
  },
  {
    field: 'is_archived',
    title: 'Archived',
    isShownByDefault: false,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
    formatOfData: customFormatTypes.boolean,
  },
  {
    field: 'update_date',
    title: 'Update Date',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.dateWithTime,
    customSorter: DATE_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'create_date',
    title: 'Create Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: EXPLORE_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      resizable: false,
      width: 32,
      cssClass: 'action-btn-cell text-right',
    },
  },
  {
    field: EDIT_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      resizable: false,
      width: 32,
      cssClass: 'action-btn-cell text-center',
    },
  },
  {
    field: EXPORT_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      resizable: false,
      width: 32,
      cssClass: 'action-btn-cell text-center',
    },
  },
];
