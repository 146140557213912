export const FINANCIAL_PAYMENT = 'payment';
export const ALL_FINANCIAL = 'financialAll';
export const FINANCIAL_ADJUSTMENT = 'adjustment';

export const financialOptions = [
  { label: 'Show All', value: ALL_FINANCIAL },
  { label: 'Payment Only', value: FINANCIAL_PAYMENT },
  { label: 'Adjustment Only', value: FINANCIAL_ADJUSTMENT },
];

export const ALL_REVERSAL = 'allReversal';
export const NO_REVERSAL = 'noReversal';

export const reversalsOption = [
  { label: 'No', value: NO_REVERSAL },
  { label: 'All', value: ALL_REVERSAL },
];
