import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';

export async function getQAData(projectId) {
  const url = interpolate(config.endpoints.getQAData, { projectId });
  const { data } = await http.get(url);

  return data.data;
}

export async function trashAndRunAnalysis(clientId, projectId) {
  const url = config.endpoints.trashAndRunAll;
  const { data } = await http.put(url, {
    client_id: clientId,
    project_id: projectId,
  });

  return data.data;
}

export async function runSingleRule(clientId, projectId, projectRuleBaseId) {
  const url = config.endpoints.runSingleRule;
  const { data } = await http.put(url, {
    client_id: clientId,
    project_id: projectId,
    project_rule_base_id: projectRuleBaseId,
  });

  return data.data;
}

export async function deleteSingleRule(projectRuleBaseId) {
  const url = interpolate(config.endpoints.deleteSingleRule, {
    projectRuleBaseId,
  });
  const { data } = await http.delete(url);

  return data.data;
}

export async function getQARuleDetails(projectRuleBaseId) {
  const url = interpolate(config.endpoints.getQARuleDetails, {
    projectRuleBaseId,
  });
  const { data } = await http.get(url);

  return data.data;
}

export async function downloadReports(projectRuleBaseId) {
  const url = interpolate(config.endpoints.exportQARuleDetails, {
    projectRuleBaseId,
  });

  const { data } = await http.get(url, {
    responseType: 'blob',
  });

  return data;
}
