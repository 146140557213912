import {
  DATE_SORTER,
  DEFAULT_SORTER,
} from 'components/common/table/constants';
import { customFormatTypes } from 'constants/formatConfig';

export const columnConfig = [
  {
    field: 'uname',
    title: 'User Name',
    isShownByDefault: true,
    sorter: DEFAULT_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 150,
    },
  },
  {
    field: 'post_date',
    title: 'Post Date',
    isShownByDefault: true,
    sorter: DATE_SORTER,
    isEditable: false,
    formatOfData: customFormatTypes.date,
  },
  {
    field: 'major_action_name',
    title: 'Major Action',
    isShownByDefault: true,
    sorter: DEFAULT_SORTER,
    isEditable: false,
  },
  {
    field: 'minor_action_name',
    title: 'Minor Action',
    isShownByDefault: true,
    sorter: DEFAULT_SORTER,
    isEditable: false,
  },
  {
    field: 'user_note',
    title: 'Notes',
    isShownByDefault: true,
    sorter: DEFAULT_SORTER,
    isEditable: false,
    additionalConfig: {
      widthGrow: 2,
    },
  },
];
