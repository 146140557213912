import React from 'react';
import PropTypes from 'prop-types';

const ConfirmationModal = (props) => {
  return (
    <>
      <h3 className="mb-2x">{props.heading}</h3>

      <div className="actions d-flex justify-content-end gap-2x mb-3x">
        <button className="btn btn-link" onClick={props.onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary has-loader" onClick={props.onDelete}>
          Connect
          {props.isRunningAction && <span className="spinner" />}
        </button>
      </div>
    </>
  );
};

ConfirmationModal.propTypes = {
  heading: PropTypes.string,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  isRunningAction: PropTypes.bool,
};

export default ConfirmationModal;
