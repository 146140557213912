import { DESCENDING_ORDER } from 'components/common/table/constants';

export const tableConfig = {
  pagination: false,
  rowHeight: 42,
  initialSort: [
    {
      column: 'post_date',
      dir: DESCENDING_ORDER,
    },
  ],
};
