import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SwitchButton = (props) => {
  const {
    value,
    labelText,
    onClick,
    badgeCount,
    hasSwitch,
    className,
    badgeClass,
    switchClass,
    dotClass,
    isBadgeVisible,
  } = props;

  const defaultClassName = 'switch-container text-sm switcher__label';

  const updatedClassName = classNames(defaultClassName, {
    selected: value,
    [`${className}`]: className,
  });

  const badgeClassName = classNames('badge', {
    [`${badgeClass}`]: badgeClass,
  });

  const switchClassName = classNames('switch', {
    [`${switchClass}`]: switchClass,
  });

  const dotClassName = classNames('dot', {
    [`${dotClass}`]: dotClass,
  });

  return (
    <>
      <label className={updatedClassName}>
        <input
          className="switcher__checkbox"
          type="checkbox"
          onChange={onClick}
          checked={value}
        />
        {hasSwitch ? (
          <div className={switchClassName}>
            <div className={dotClassName}></div>
          </div>
        ) : null}
        <span className="switcher__text">{labelText}</span>
      {isBadgeVisible ? (
        <span className={badgeClassName}>{badgeCount}</span>
      ) : null}
      </label>
    </>
  );
};

SwitchButton.defaultProps = {
  hasSwitch: true,
  isBadgeVisible: true
};

SwitchButton.propTypes = {
  value: PropTypes.bool,
  labelText: PropTypes.string,
  onClick: PropTypes.func,
  badgeCount: PropTypes.number,
  hasSwitch: PropTypes.bool,
  className: PropTypes.string,
  badgeClass: PropTypes.string,
  switchClass: PropTypes.string,
  dotClass: PropTypes.string,
  isBadgeVisible: PropTypes.bool
};

export default SwitchButton;
