import React from 'react';

import Icon from '../icons/Icons';
import { createRoot } from 'react-dom/client';

class CustomViewDetailButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className="btn-rounded-icon">
        <Icon
          className="icon-view-detail"
          icon="viewDetail"
          size={14}
          color="#808285"
        />
      </button>
    );
  }
}

export const viewDetailButtonFormatter = (
  cell,
  formatterParams,
  onRendered
) => {
  const container = document.createElement('div');
  container.classList.add('actions-btn');
  const root = createRoot(container);

  root.render(
    <CustomViewDetailButton
      cell={cell}
      formatterParams={formatterParams}
      onRendered={onRendered}
    />
  );

  return container;
};

export default CustomViewDetailButton;
