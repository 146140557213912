import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';

export async function getPublisherData(projectId) {
  const url = interpolate(config.endpoints.getPublisherData, {
    projectId,
  });

  const { data } = await http.get(url);

  return data.data;
}

export async function downloadReports(
  projectId,
  includeReportIds = [],
  excludeDataConcernIds = []
) {
  const url = interpolate(config.endpoints.exportReport, {
    projectId,
  });

  const { data } = await http.put(
    url,
    {
      include_in_report: includeReportIds,
      exclude_data_concerns: excludeDataConcernIds,
    },
    {
      responseType: 'blob',
    }
  );

  return data;
}
