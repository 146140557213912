export const USER_ADDED = ':fname has been added as :role';
export const DATAHINT_UPDATE_SUCCESS_MESSAGE = 'Data hint updated successfully';
export const DATAHINT_EXECUTION_SUCCESS_MESSAGE =
  'Data hint executed successfully';

export const WORK_TRACKER_ADD_SUCCESS_MESSAGE =
  'Work tracker added successfully.';
export const ALGORITHM_TOAST_SUCCESS_MESSAGES = {
  ADD: 'Algorithm added successfully.',
  UPDATE: 'Algorithm updated successfully.',
  RESET: 'Rule Definition has been reset.',
  DELETE: 'Algorithm has been deleted.',
};

export const ETL_TRIGGERED = 'ETL triggered. Check Airflow for status.';
