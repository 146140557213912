import React from 'react';
import PropTypes from 'prop-types';

import { createRoot } from 'react-dom/client';
import Icon from 'components/common/icons/Icons';

import { fieldsToCheck } from './columnConfigs';

class CustomRerunButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const rowData = this.props.cell.getRow().getData();
    let shouldBeActivated = false;

    if (fieldsToCheck.findIndex((field) => !rowData[field]) !== -1) {
      shouldBeActivated = true;
    }

    const iconColor = shouldBeActivated ? '#808285' : '#a8a9ad';

    return (
      <button className="btn-rounded-icon">
        <Icon
          className="icon-replay"
          icon="replay"
          size={14}
          color={iconColor}
        />
      </button>
    );
  }
}

CustomRerunButton.propTypes = {
  cell: PropTypes.object,
};

export const rerunButtonFormatter = (cell, formatterParams, onRendered) => {
  const container = document.createElement('div');
  container.classList.add('actions-btn');
  const root = createRoot(container);

  root.render(
    <CustomRerunButton
      cell={cell}
      formatterParams={formatterParams}
      onRendered={onRendered}
    />
  );

  return container;
};

export default CustomRerunButton;
