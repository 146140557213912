import { customFormatTypes } from 'constants/formatConfig';
import {
  LIST_EDITOR,
  NUMERIC_SORTER,
  DATE_SORTER,
} from 'components/common/table/constants';

export const TRANSACTION_COMBO_ID_FIELD = 'transaction_combo_id';

export const columnConfig = [
  {
    field: TRANSACTION_COMBO_ID_FIELD,
    title: 'ID',
    sorter: NUMERIC_SORTER,
    isShownByDefault: false,
    isEditable: false,
    additionalConfig: {
      width: 90,
    },
  },
  {
    field: 'transaction_code',
    title: 'Trans Code',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      width: 160,
    },
  },
  {
    field: 'transaction_code_name',
    title: 'Trans Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'transaction_type_code',
    title: 'Type Code',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      width: 150,
    },
  },
  {
    field: 'transaction_type_name',
    title: 'Type Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      width: 160,
    },
  },
  {
    field: 'category_name',
    title: 'Category',
    isShownByDefault: true,
    isEditable: true,
    editor: LIST_EDITOR,
    editorParams: {
      values: [],
      autoComplete: false,
    },
    additionalConfig: {
      cssClass: 'highlight-col',
      width: 200,
    },
  },
  {
    field: 'ch_financial_category_id',
    title: 'Category Id',
    isShownByDefault: false,
    isEditable: false,
    additionalConfig: {
      width: 160,
    },
  },
  {
    field: 'create_date',
    title: 'Create Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      width: 160,
    },
  },
  {
    field: 'update_date',
    title: 'Update Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      width: 160,
    },
  },
  {
    field: 'update_user',
    title: 'Update User',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'update_hash',
    title: 'Update Hash',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'business_hash',
    title: 'Business Hash',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'client_id',
    title: 'Client ID',
    isShownByDefault: false,
    isEditable: false,
    sorter: NUMERIC_SORTER,
    additionalConfig: {
      width: 140,
    },
  },
];

export default columnConfig;
