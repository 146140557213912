import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';

const OTPInput = ({ length, onChange, error, shouldAutoFocus }) => {
  const inputsRef = useRef([]);
  const [otp, setOtp] = useState(Array(length).fill(''));

  // for autofocus on initial load
  useEffect(() => {
    if (shouldAutoFocus) {
      const firstInput = inputsRef.current.find((i) => Boolean(i));
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, [shouldAutoFocus]);

  // handle OTP change and call onChange passed from props
  const handleOTPChange = (otpValue) => {
    onChange(otpValue.join(''));
  };

  // function to focus on the input with the specified index
  // by default after focusing, the value are selected
  const focusInput = (index, shouldSelect = true) => {
    const activeInput = Math.max(Math.min(length - 1, index), 0);

    if (inputsRef.current[activeInput]) {
      inputsRef.current[activeInput].focus();
      if (shouldSelect) {
        // triggering select immediately after focus does not always work
        // so the need for setTimeout
        setTimeout(() => {
          inputsRef.current[activeInput].select();
        }, 0);
      }
    }
  };

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, '');

    if (value) {
      const newOtp = [...otp];
      newOtp[index] = value;
      handleOTPChange(newOtp);
      setOtp(newOtp);

      // if the active input is at last - then remove focus
      if (index === length - 1) {
        const activeInput = Math.max(Math.min(length - 1, index), 0);

        inputsRef.current[activeInput].blur();
      } else {
        // Focus the next input
        focusInput(index + 1, false);
      }
    }
  };

  const handleKeyDown = (e, index) => {
    // clear the value on the current input and
    // focus on the previous input
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      handleOTPChange(newOtp);

      setOtp(newOtp);

      // focus the previous input
      focusInput(index - 1);

      // onChange is not triggered if same value is entered
      // so, manually focusing to the next input
    } else if (e.key === otp[index]) {
      e.preventDefault();
      focusInput(index + 1);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text');

    // Prevent pasting if the clipboard data contains non-numeric values for number inputs
    if (
      pasteData &&
      pasteData.split('').some((value) => isNaN(Number(value)))
    ) {
      return;
    }

    if (pasteData.length === length) {
      const newOtp = pasteData.split('').slice(0, length);
      handleOTPChange(newOtp);
      setOtp(newOtp);
      focusInput(length - 1, false);
    }
  };

  return (
    <div className="d-flex flex-direction-column align-items-start">
      <div className="d-flex justify-content-center align-items-center gap-6x">
        {Array(length)
          .fill('')
          .map((_, index) => {
            return (
              <input
                data-cy="mfa-input"
                key={index}
                ref={(el) => (inputsRef.current[index] = el)}
                type="number"
                maxLength="1"
                value={otp[index]}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                className={classnames('otp__input', {
                  otp__error: error,
                })}
              />
            );
          })}
      </div>
      {error && <p className="user-form__error-msg text-md mt-2x">{error}</p>}
    </div>
  );
};

OTPInput.defaultProps = {
  length: 6,
  error: '',
  shouldAutoFocus: false,
};

OTPInput.propTypes = {
  length: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  shouldAutoFocus: PropTypes.bool,
};

export default OTPInput;
