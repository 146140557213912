import React from 'react';
import classNames from 'classnames';
import { Navigate } from 'react-router-dom';

import logo from 'assets/images/brand-logo.svg';

import MFA from 'components/mfa/MFA';
import Icon from 'components/common/icons/Icons';

import { login } from 'services/auth';
import * as tokenService from 'services/token';

import parseJwt from 'utils/jwt';
import * as storageService from 'utils/storage';

import { HOME } from 'constants/routes';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';
import { USERNAME, PERMISSIONS, ROLE, USER_ID } from 'constants/storage';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      username: '',
      password: '',
      hasError: false,
      errorMessage: '',
      loggedIn: false,
      isPasswordVisible: false,
      isSubmitted: false,
      mfaAction: '',
      mfaData: {},
    };
  }

  handleUsernameChange = (event) => {
    this.setState({
      username: event.target.value?.trim(),
      hasError: false,
    });
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value?.trim(),
      hasError: false,
    });
  };

  togglePasswordVisibility = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  };

  setTokens = (data) => {
    const { username } = this.state;

    const { access_token, refresh_token } = data;
    const { role, permissions, user_id } = parseJwt(access_token);
    tokenService.setTokens(access_token, refresh_token);
    storageService.set(USERNAME, username);
    storageService.set(PERMISSIONS, permissions || []);
    storageService.set(ROLE, role || '');
    storageService.set(USER_ID, user_id);
  };

  handleLoginWithMFA = async (e, otp) => {
    // passing shouldRedirect as false here
    // since, we don't want to redirect directly to dashboard
    // after MFA is verified. instead we show a modal
    const data = await this.handleLogin(e, otp, false);

    return data;
  };

  handleLogin = async (event, otp, shouldRedirect = true) => {
    event.preventDefault();

    const { username, password } = this.state;

    this.setState({
      isSubmitting: true,
      errorMessage: '',
    });

    try {
      const data = await login({ username, password, otp });

      // handle MFA actions
      if (data && data.action && !data.access_token) {
        this.setState({ mfaAction: data.action, mfaData: data });

        return;
      }

      this.setTokens(data);

      // set logged in to true which redirects to dashboard
      if (shouldRedirect) {
        this.setState({ loggedIn: true, isSubmitting: false });
      }

      return data;
    } catch (error) {
      let errorMessage = DEFAULT_ERROR_MESSAGE;

      if (error.response && error.response.data) {
        errorMessage = error.response.data.detail;
      }
      // handle Error
      this.setState({
        isSubmitting: false,
        hasError: true,
        errorMessage,
      });

      // Rethrow the error
      // This allows the error to be caught and handled in the MFA component
      // By rethrowing, we separate error handling logic between components
      // MFA component specifically handles OTP-related scenarios
      // since the way errors are displayed differ in Login and MFA
      if (otp) {
        throw error;
      }
    }
  };

  render() {
    const {
      username,
      password,
      hasError,
      isSubmitting,
      isPasswordVisible,
      errorMessage,
      loggedIn,
      mfaAction,
      mfaData,
    } = this.state;

    const disableLogin = !username || !password || hasError;

    const toggleVisibilityBtnClassname = classNames({
      'icon-eye': true,
      'icon-eye--closed d-block': !isPasswordVisible,
    });

    const icon = isPasswordVisible ? 'eye' : 'eyeClosed';

    const inputClassname = classNames({
      form__control: true,
      'is-error': hasError,
    });

    const passwordClassname = classNames({
      'form__control form__ontrol--password': true,
      'is-error': hasError,
    });

    if (loggedIn) {
      return <Navigate to={HOME} replace={true} />;
    }

    return (
      <>
        {mfaAction ? (
          <MFA mfaData={mfaData} handleLoginWithMFA={this.handleLoginWithMFA} />
        ) : (
          <main className="login__page ">
            <div className="login__left">
              <img src={logo} alt="Login button" />
            </div>
            <div className="login__right">
              <h1 className="color-primary--base">Welcome Back</h1>
              <p className="color-grey--80">Please enter your details </p>

              <form
                className="login__form mt-6x"
                onSubmit={this.handleLogin}
                autoComplete="on"
              >
                <div className="form-group mb-4x">
                  <label className="form__label">Username</label>
                  <input
                    className={inputClassname}
                    type="text"
                    value={username}
                    onChange={this.handleUsernameChange}
                    placeholder="Enter the username"
                  />
                </div>
                <div className="form-group mb-4x">
                  <label className="form__label">Password</label>
                  {!isPasswordVisible ? (
                    <input
                      className={passwordClassname}
                      type="password"
                      value={password}
                      onChange={this.handlePasswordChange}
                      placeholder="Enter the password"
                      autoComplete="on"
                    />
                  ) : (
                    <input
                      className={passwordClassname}
                      type="text"
                      value={password}
                      placeholder="Enter the password"
                      autoComplete="on"
                      onChange={this.handlePasswordChange}
                    />
                  )}
                  <button
                    type="button"
                    className={toggleVisibilityBtnClassname}
                    onClick={this.togglePasswordVisibility}
                  >
                    <Icon icon={icon} size={14} color="#57585A" />
                  </button>
                </div>

                <button
                  className="btn btn-primary w-100"
                  type="submit"
                  disabled={disableLogin}
                >
                  Login
                  {isSubmitting && <span className="spinner" />}
                </button>

                {errorMessage && (
                  <div className="error-msg text-sm mt-3x">
                    <Icon className="mr-1x" icon="warning" size={13} />
                    {errorMessage}
                  </div>
                )}
              </form>
            </div>
          </main>
        )}
      </>
    );
  }
}

export default Login;
