import config from '../config';

import http from 'utils/http';
import { interpolate } from 'utils/common/string';

// TODO: Need to write test for getClientDataOverview

/**
 * Get client data overview.
 *
 * @param {string} clientId - Client ID.
 * @returns {Promise<Object>} - Client data overview.
 */
export async function getClientDataOverview(clientId) {
  const clientDataOverviewUrl = interpolate(
    config.endpoints.clientDataOverview,
    {
      clientId,
    }
  );

  const { data } = await http.get(clientDataOverviewUrl);

  return data.data;
}

export async function runETL(clientId, body = {}) {
  const url = interpolate(config.endpoints.runETL, { clientId });

  const { data } = await http.post(url, body);

  return data;
}
