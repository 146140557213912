import React from 'react';

import { createRoot } from 'react-dom/client';

class CustomLogButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className="action-link color-primary--60 text-underline">
        Logs
      </button>
    );
  }
}

export const showLogFormatter = (cell, formatterParams, onRendered) => {
  const container = document.createElement('div');
  container.classList.add('actions-btn');
  const root = createRoot(container);

  root.render(
    <CustomLogButton
      cell={cell}
      formatterParams={formatterParams}
      onRendered={onRendered}
    />
  );

  return container;
};

export default CustomLogButton;
