import React from 'react';

import UserModal from './UserModal';
import logo from 'assets/images/brand-logo.svg';
import Table from 'components/common/table/Table';
import Toast from 'components/common/toast/Toast';
import Modal from 'components/common/modal/Modal';
import Loader from 'components/common/loader/Loader';

import * as userServices from 'services/users';

import { tableConfig } from './tableConfig';
import { columnConfig } from './columnConfig';

import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

class Users extends React.Component {
  constructor() {
    super();

    this.state = {
      data: [],
      hasError: false,
      errorMessage: '',
      isLoading: false,
      isUpdating: false,
      selectedUser: {
        userId: '',
        email: '',
      },
      isShowingModal: false,
    };
  }

  componentDidMount = async () => {
    await this.loadUsers();
  };

  loadUsers = async () => {
    this.setState({
      isLoading: true,
    });

    try {
      const data = await userServices.getUsers();

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowingModal: false,
    });
    this.resetSelectedUser();
  };

  handleEditModalClick = (_, cell) => {
    const rowData = cell.getRow().getData();
    const selectedUser = {
      userId: rowData.user_id,
      email: rowData.email,
    };

    this.setState({
      isShowingModal: true,
      selectedUser,
    });
  };

  resetSelectedUser = () => {
    this.setState({
      selectedUser: {
        userId: '',
        email: '',
      },
    });
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      data,
      hasError,
      errorMessage,
      isShowingModal,
      isLoading,
      selectedUser,
    } = this.state;
    const { userId, email } = selectedUser;

    return (
      <>
        <header className="bg-primary--base py-4x">
          <div className="container">
            <img src={logo} alt="" />
          </div>
        </header>
        <main className="pt-8x">
          <div className="container">
            <h1 className="mb-6x">Users</h1>
            <Table
              data={data}
              columnConfig={columnConfig}
              tableConfig={tableConfig}
              handleEditModalClick={this.handleEditModalClick}
            />
            {isLoading && <Loader isFullScreen={true} />}
          </div>
          {isShowingModal && (
            <Modal onClose={this.handleCloseModal}>
              <UserModal
                email={email}
                userId={userId}
                onClose={this.handleCloseModal}
              />
            </Modal>
          )}
          {hasError && (
            <Toast
              title={errorMessage}
              hasError={hasError}
              handleClose={this.resetError}
            />
          )}
        </main>
      </>
    );
  }
}

export default Users;
