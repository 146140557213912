import http from 'utils/http';
import config from '../config';

export async function rememberDevice() {
  const url = config.endpoints.rememberDevice;

  const { data } = await http.post(url);

  return data.data;
}
