import { PUBLISHED_DATE_COLUMN } from './columnConfig';
import { DESCENDING_ORDER } from 'components/common/table/constants';

export const tableConfig = {
  paginationSize: 10,
  paginationButtonCount: 5,
  paginationSizeSelector: [5, 10, 15, 20],
  selectable: 1,
  initialSort: [
    {
      column: PUBLISHED_DATE_COLUMN,
      dir: DESCENDING_ORDER,
    },
  ],
};
