import { customFormatTypes } from 'constants/formatConfig';
import { DATE_SORTER, NUMERIC_SORTER } from 'components/common/table/constants';

export const columnConfig = [
  {
    field: 'rule_name',
    title: 'Data Hints Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'version',
    title: 'Version',
    isShownByDefault: true,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 250,
    },
  },
  {
    field: 'rule_category',
    title: 'Category',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      width: 325,
    },
  },
  {
    field: 'create_date',
    title: 'Created Date',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      width: 325,
    },
  },
];
