import React from 'react';
import { createRoot } from 'react-dom/client';

import iconsLib from 'components/common/icons/iconsLib';

export const headerFormatter = (_event, column, _onRendered) => {
  const container = document.createElement('div');

  const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  iconSvg.classList.add('icon-filter-search');
  iconSvg.setAttribute('width', 16);
  iconSvg.setAttribute('height', 16);
  iconSvg.setAttribute('color', '#808285');
  iconSvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
  iconSvg.setAttribute('viewBox', '0 0 50 50');

  const iconPath = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );
  iconPath.setAttribute('d', iconsLib.search);

  iconSvg.appendChild(iconPath);

  const input = document.createElement('input');
  input.classList.add('input-filter-search');
  input.placeholder = 'Search';
  input.value = column.getHeaderFilterValue() || '';

  const columnDefinition = column.getDefinition();

  if (columnDefinition.filter_type) {
    input.setAttribute('type', columnDefinition.filter_type);
  }

  input.addEventListener('keyup', (_e) => {
    if (columnDefinition.filter_key) {
      if (columnDefinition.filter_key === _e.key) {
        column.setHeaderFilterValue(input.value);
      }
    } else {
      column.setHeaderFilterValue(input.value);
    }
  });

  container.appendChild(iconSvg);
  container.appendChild(input);

  return container;
};

class EmptyHeaderComp extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    return <div ref={this.ref} />;
  }
}

export const emptyHeaderFilter = () => {
  const container = document.createElement('div');
  const root = createRoot(container);

  root.render(<EmptyHeaderComp />);

  return container;
};
