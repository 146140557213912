import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Datepicker from 'components/common/datepicker/Datepicker';

const AddProjectModal = (props) => {
  const inputClassname = classNames({
    form__control: true,
  });

  return (
    <>
      <h2>Add New Project</h2>
      <p className="text-lg mt-neg-1x mb-5x color-grey--80">
        {props.clientName}
      </p>

      <form
        action=""
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="form-group mb-6x">
          <label className="form__label">Project Name</label>
          <input
            className={inputClassname}
            type="text"
            name="projectName"
            value={props.projectName}
            onChange={props.handleChange}
            placeholder="Enter Project Name"
          />
        </div>
        <div className="form-group mb-6x">
          <label className="form__label">Assignee</label>
          <input
            className={inputClassname}
            type="text"
            name="assignee"
            value={props.assignee}
            onChange={props.handleChange}
            placeholder="Enter Assignee"
          />
        </div>
        <div className="form-group mb-6x">
          <label className="form__label">Analysis Date</label>
          <Datepicker
            selectedDate={props.analysisDate}
            handleCalendarValueChange={props.handleCalendarValueChange}
            placeholder={'Select Analysis Date'}
          />
        </div>

        <div className="actions d-flex justify-content-end gap-2x mb-3x">
          <button className="btn btn-link" onClick={props.onClose}>
            Cancel
          </button>
          <button className="btn btn-primary has-loader" onClick={props.onSave}>
            Save {props.isSaving && <span className="spinner" />}
          </button>
        </div>
      </form>
    </>
  );
};

AddProjectModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  isSaving: PropTypes.bool,
  assignee: PropTypes.string,
  clientName: PropTypes.string,
  handleChange: PropTypes.func,
  projectName: PropTypes.string,
  analysisDate: PropTypes.string,
  handleCalendarValueChange: PropTypes.func,
};
export default AddProjectModal;
