import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';

export async function getInsuranceMapping(clientId) {
  const url = interpolate(config.endpoints.insuranceMappingDataV1, {
    clientId,
  });
  const { data } = await http.get(url);

  return data.data;
}

export async function getTransactionMapping(clientId) {
  const url = interpolate(config.endpoints.transactionMappingDataV1, {
    clientId,
  });
  const { data } = await http.get(url);

  return data.data;
}
