import http from 'utils/http';
import config from '../config';
import { interpolate } from 'utils/common/string';

export async function getAllRoles() {
  const url = config.endpoints.uroles;

  const { data } = await http.get(url);

  return data.data;
}

/**
 * Get All permissions for a role
 * @param {string | number} uroleId - Id of the role
 * @returns {Array} - An array of permissions for the role
 */
export async function getRolePermissions(uroleId) {
  const url = interpolate(config.endpoints.urolePermissions, {
    uroleId,
  });

  const { data } = await http.get(url);

  return data.data;
}
