import config from 'config';
import http from 'utils/http';
import { removeEmptyPayloadValues } from 'utils/payload';
import { getAPIDateString } from 'utils/common/formatter';
import {
  interpolate,
  removeCommasFromNumber,
  convertCurrencyToNumber,
} from 'utils/common/string';

import { NEW } from 'constants/projectStatuses';

export async function getProjects(clientId) {
  const url = interpolate(config.endpoints.projectsV1, { clientId });
  const { data } = await http.get(url);

  return data.data;
}

export async function updateProject(clientId, projectId, projectDetails) {
  const url = interpolate(config.endpoints.updateProjectV1, { projectId });
  const payload = {
    client_id: clientId,
    project_name: projectDetails.projectName,
    publish_date: getAPIDateString(projectDetails.publishDate),
    count_account: removeCommasFromNumber(projectDetails.countAccount),
    balance_account: convertCurrencyToNumber(projectDetails.balanceAccount),
    status: projectDetails.status,
    assignee: projectDetails.assignee,
    reviewer: projectDetails.reviewer,
    notes: projectDetails.notes,
    is_archived: projectDetails.isArchived,
    analysis_date: getAPIDateString(projectDetails.analysisDate),
  };

  const cleanedPayload = removeEmptyPayloadValues(payload);
  const { data } = await http.put(url, cleanedPayload);

  return data.data;
}

export async function createProject(clientId, projectDetails) {
  const url = config.endpoints.createProjectV1;
  const { data } = await http.post(url, {
    client_id: clientId,
    project_name: projectDetails.projectName,
    assignee: projectDetails.assignee,
    analysis_date: getAPIDateString(projectDetails.analysisDate),
    status: NEW.toUpperCase(),
  });

  return data.data;
}

export async function exportProject(projectId) {
  const url = interpolate(config.endpoints.exportProjectV1, { projectId });

  const { data } = await http.get(url, {
    responseType: 'blob',
  });

  return data;
}
