import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useState, useRef, useEffect, isValidElement } from 'react';

import { colors } from 'constants/colors';
import Icon from 'components/common/icons/Icons';

const Accordion = ({
  /** The content of the accordion */
  children,

  /** Specifies which element to render the accordion as. Defaults to div */
  as: Wrapper,

  /** The header of the accordion. Can be a text or a custom component */
  header,

  /** The caret of the accordion. Can be a custom component */
  caret,

  /** Additional class names to be added to the accordion */
  headerClassName,

  /** Additional class names to be added to the content */
  contentClassName,

  /** Additional class names to be added to the caret */
  caretClassName,

  /** Additional props passed down to the `Wrapper` component */
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [hasRendered, setHasRendered] = useState(false);

  const contentRef = useRef(null);

  const contentClassNames = classNames('accordion__content', {
    [`${contentClassName}`]: contentClassName,
  });
  const headerClassNames = classNames('color-primary--base text-700', {
    [`${headerClassName}`]: headerClassName,
  });
  const caretClassNames = classNames('accordion__caret', {
    'accordion__caret--open': isOpen,
    [`${caretClassName}`]: caretClassName,
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);

      // When lazy loading accordion content using isExpanded
      // the accordion content is not rendered in the DOM initially
      // set hasRendered to true so that the accordion content
      // is not removed from the DOM when it is collapsed
      setHasRendered(true);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <Wrapper className="accordion" {...rest}>
      <button className="accordion__button" onClick={handleClick}>
        {isValidElement(header) ? (
          header
        ) : (
          <h4 className={headerClassNames}>{header}</h4>
        )}

        {isValidElement(caret) ? (
          caret
        ) : (
          <div className={caretClassNames}>
            <div>
              <Icon
                icon="chevronRight"
                width={8}
                height={20}
                color={colors.primary[80]}
              />
            </div>
          </div>
        )}
      </button>
      <div
        ref={contentRef}
        className={contentClassNames}
        style={{
          // Set the height of the accordion content to the height of the content inside it
          // this allows for smooth transition of the accordion content when rendered conditionally
          height: `${height}px`,
        }}
      >
        {isValidElement(children)
          ? children
          : // Allows for lazy loading of accordion content by using renderProp pattern
            // Passes isExpanded prop to children function and the children function returns JSX
            // based on the isExpanded prop
            children({ isExpanded: isOpen || hasRendered })}
      </div>
    </Wrapper>
  );
};

Accordion.defaultProps = {
  as: 'div',
};

Accordion.propTypes = {
  as: PropTypes.string,
  caret: PropTypes.node,
  caretClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default Accordion;
