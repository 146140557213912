import React from 'react';
import store from 'store';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';

import App from './components/App';
import { persistedStore } from './store';

import init from 'init';

const root = ReactDOM.createRoot(document.getElementById('root'));

(() => {
  init();

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
})();
