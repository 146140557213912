import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Icon from 'components/common/icons/Icons';
import Toast from 'components/common/toast/Toast';
import Table from 'components/common/table/Table';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyProjectPage from 'components/common/emptypage/EmptyProjectPage';
import { VIEWDETAIL_BUTTON_COLUMN } from 'components/common/table/constants';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import { columnConfig, ACCOUNT_NUMBER } from './columnConfig';
import {
  updateClientID,
  updateClientList,
  updateAccountNumber,
  clearSelectedProject,
} from 'actions/clientAction';

import { getQARuleDetails, downloadReports } from 'services/qa';

import * as routes from 'constants/routes';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import { formatDate } from 'utils/common/formatter';

const mapStateToProps = (state) => {
  const {
    selectedClientId,
    clientList,
    selectedProject,
    selectedProjectRuleBaseId,
  } = state;

  return {
    selectedClientId,
    clientList,
    selectedProject,
    selectedProjectRuleBaseId,
  };
};

class QADetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      navigateTo: '',
      ruleDetails: {
        rule_name: '',
        total_hits: '',
        total_balance: '',
        rule_category: '',
        exclusive_hits: '',
        exclusive_balance: '',
      },
      hasError: false,
      errorMessage: '',
      isLoading: false,
      selectedColumns: [],
      columnConfig: columnConfig,
      updatedColumnConfig: {
        field: '',
        isShownByDefault: false,
      },
    };
  }

  async componentDidMount() {
    this.setState({
      selectedColumns: columnConfig
        .filter(
          (config) =>
            config.isShownByDefault && config.field !== VIEWDETAIL_BUTTON_COLUMN
        )
        .map((col) => col.field),
    });

    await this.getRuleDetailsData();
  }

  getRuleDetailsData = async () => {
    const { selectedProjectRuleBaseId } = this.props;

    if (!selectedProjectRuleBaseId) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    try {
      const response = await getQARuleDetails(selectedProjectRuleBaseId);

      if (response.details) {
        this.setState({
          ruleDetails: response.details,
        });
      }

      if (response.list) {
        this.setState({
          data: response.list,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      navigateTo: routes.PROJECT_ANALYSIS,
    });

    this.props.updateClientID(clientId);
    this.props.clearSelectedProject();
  };

  handleViewDetailButtonClick = (_, cell) => {
    const accountNumber = cell.getRow().getData()[ACCOUNT_NUMBER];
    this.props.updateAccountNumber(accountNumber);

    window.open(routes.ACCOUNT_INFORMATION, '_blank');
  };

  handleCategorySelect = (selectedColumnField) => {
    const { selectedColumns } = this.state;
    const selected = selectedColumns.includes(selectedColumnField);

    if (selected) {
      this.setState({
        selectedColumns: selectedColumns.filter(
          (col) => col !== selectedColumnField
        ),
      });
    }

    if (!selected) {
      this.setState({
        selectedColumns: [...selectedColumns, selectedColumnField],
      });
    }

    this.setState({
      updatedColumnConfig: {
        field: selectedColumnField,
        isShownByDefault: !selected,
      },
    });
  };

  handleExport = async () => {
    const { selectedProject, selectedProjectRuleBaseId } = this.props;

    if (!selectedProjectRuleBaseId) {
      return;
    }

    try {
      const now = Date.now();
      const blob = await downloadReports(selectedProjectRuleBaseId);
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `QA Details_${selectedProject.projectName}_${formatDate(now)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
      });
    }
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      data,
      hasError,
      isLoading,
      navigateTo,
      ruleDetails,
      errorMessage,
      selectedColumns,
      updatedColumnConfig,
    } = this.state;
    const {
      rule_name,
      total_hits,
      rule_category,
      total_balance,
      exclusive_hits,
      exclusive_balance,
    } = ruleDetails;
    const {
      clientList,
      selectedClientId,
      selectedProject,
      selectedProjectRuleBaseId,
    } = this.props;

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });
    const projectDisplayName = selectedProject.projectName || '';

    return navigateTo ? (
      <Navigate to={navigateTo} replace={true} />
    ) : (
      <>
        {selectedClientId && selectedProjectRuleBaseId ? (
          <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
            <div className="profile mb-3x ml-auto">
              <Dropdown
                label={displayName}
                dropdownItems={dropdownItems}
                onClick={this.handleSelectClient}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mb-1x">
              <h1>{rule_name}</h1>
            </div>

            <div className="d-flex align-items-center gap-8x bg-white--base has-box-shadow py-3x px-6x mb-4x ">
              <div>
                <h3 className="color-primary--base mb-1x">
                  {selectedClient.display_name || ''}
                </h3>
                <p className="text-xl">{projectDisplayName}</p>
              </div>
              <div className="d-flex bg-primary--2 px-6x py-7x flex-grow-1">
                <div>
                  <div className="mb-6x">
                    <h5 className="color-primary--base mb-1x">Rule Name</h5>
                    <p className="text-xl color-primary--base">{rule_name}</p>
                  </div>
                  <div>
                    <h5 className=" mb-1x">Rule Category</h5>
                    <p className="text-xl color-primary--base">
                      {rule_category}
                    </p>
                  </div>
                </div>

                <div className="seperator mx-9x"></div>

                <div className="d-flex flex-grow-xxxl-1 gap-10x">
                  <div className="d-flex flex-direction-column  flex-direction-xxxl-row">
                    <div className="mb-6x minw-150">
                      <h5 className="color-primary--base mb-1x">
                        Total Hits #
                      </h5>
                      <p className="text-xl color-primary--base">
                        {total_hits}
                      </p>
                    </div>

                    <div className="d-none d-xxxl-block seperator mx-4x"></div>

                    <div className="minw-150">
                      <h5 className="color-primary--base mb-1x">
                        Gross Total $
                      </h5>
                      <p className="text-xl color-primary--base">
                        {total_balance}
                      </p>
                    </div>
                  </div>

                  <div className="d-none d-xxxl-block seperator mx-4x"></div>

                  <div className="d-flex flex-direction-column flex-direction-xxxl-row">
                    <div className="mb-6x minw-150">
                      <h5 className="color-primary--base mb-1x">
                        Exclusive Total #
                      </h5>
                      <p className="text-xl color-primary--base">
                        {exclusive_hits}
                      </p>
                    </div>

                    <div className="d-none d-xxxl-block seperator mx-4x"></div>

                    <div className="minw-150">
                      <h5 className="color-primary--base mb-1x">Net Total $</h5>
                      <p className="text-xl color-primary--base">
                        {exclusive_balance}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isLoading && <Loader isFullScreen={true} />}

            <div className="d-flex justify-content-end pt-2x gap-3x mb-2x">
              <CustomColumnSelector
                columnConfig={columnConfig}
                selectedColumns={selectedColumns}
                handleSelect={this.handleCategorySelect}
              />
              <button
                className="btn-ghost px-2x py-1x"
                onClick={this.handleExport}
              >
                <Icon
                  icon="export"
                  className="mr-2x icon-export"
                  width={10}
                  height={14}
                  color="#DADADA"
                />
                Export
              </button>
            </div>
            <div
              className="d-flex flex-direction-column gap-3x"
              style={{ height: 'calc(100vh - 420px)' }}
            >
              <div className="table has-box-shadow">
                <Table
                  className="has-box-shadow"
                  columnConfig={columnConfig}
                  data={data}
                  selectedColumns={selectedColumns}
                  updatedColumnConfig={updatedColumnConfig}
                  handleViewDetailButtonClick={this.handleViewDetailButtonClick}
                />
              </div>
            </div>
          </div>
        ) : (
          <EmptyProjectPage customMessage="Please select a Rule from QA page." />
        )}
        {hasError && (
          <Toast
            title={errorMessage}
            hasError={hasError}
            handleClose={this.resetError}
          />
        )}
      </>
    );
  }
}

QADetails.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedProject: PropTypes.object,
  updateAccountNumber: PropTypes.func,
  clearSelectedProject: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedProjectRuleBaseId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  updateAccountNumber,
  clearSelectedProject,
})(QADetails);
