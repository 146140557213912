import React from 'react';
import PropTypes from 'prop-types';

const TagListElement = ({ label }) => {
  return <li className="tag__list__item">{label}</li>;
};

TagListElement.propTypes = {
  label: PropTypes.string,
};

class Tag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfTagsToShow: 0,
      isDropdownShown: false,
    };

    this.wrapperElement = React.createRef();
  }

  componentDidMount() {
    const numberOfTagsToShow = this.numberOfTagsToFit();
    this.setState({
      numberOfTagsToShow,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.tags.length !== prevProps.tags.length) {
      const numberOfTagsToShow = this.numberOfTagsToFit();
      this.setState({
        numberOfTagsToShow,
      });
    }
  }

  toggleDropdownShown = () => {
    this.setState({
      isDropdownShown: !this.state.isDropdownShown,
    });
  };

  numberOfTagsToFit = () => {
    const { tags, maxWidthInPx } = this.props;
    if (!tags || tags.length === 0) {
      return 0;
    }

    const longestTag = tags.reduce((acc, currValue) => {
      if (acc.length < currValue.length) {
        acc = currValue;
      }

      return acc;
    }, '');
    const tagOffsetPx = 20;
    const additionalBufferPx = 240;

    let pseudoElement = document.createElement('li');
    pseudoElement.classList.add('tag__list__item');
    pseudoElement.innerText = longestTag;
    this.wrapperElement.current.append(pseudoElement);
    const maxTabWidth =
      pseudoElement.getBoundingClientRect().width + tagOffsetPx;
    pseudoElement.remove();

    return Math.floor((maxWidthInPx - additionalBufferPx) / maxTabWidth);
  };

  render() {
    const { tags, resetTags } = this.props;
    const { numberOfTagsToShow, isDropdownShown } = this.state;

    const tagsToShow = tags.slice(0, numberOfTagsToShow);
    const hiddenNumberOfTags = tags.length - numberOfTagsToShow;
    const hiddenTags = tags.slice(numberOfTagsToShow, tags.length);

    return (
      tags &&
      tags.length > 0 && (
        <div className="tag__wrapper" ref={this.wrapperElement}>
          <label className="tag__label mr-3x">Filtered by</label>
          <ul className="tag__list gap-2x">
            {tagsToShow.map((tag, index) => {
              return <TagListElement key={index} label={tag} />;
            })}

            {hiddenNumberOfTags >= 1 && (
              <li className="tag__dropdown">
                <button
                  className="tag__list__button"
                  onClick={this.toggleDropdownShown}
                >
                  <strong>+{hiddenNumberOfTags} More</strong>
                </button>

                {isDropdownShown && (
                  <ul className="tag__dropdown__list">
                    {hiddenTags.map((tag, index) => {
                      return <TagListElement key={index} label={tag} />;
                    })}
                  </ul>
                )}
              </li>
            )}
          </ul>
          <input
            className="btn btn-link"
            onClick={resetTags}
            type="reset"
            value="Reset"
          />
        </div>
      )
    );
  }
}

Tag.propTypes = {
  resetTags: PropTypes.func,
  handleTagClick: PropTypes.func,
  maxWidthInPx: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default Tag;
