export const EDIT_USER = 'edit-user';
export const VIEW_ALL_USER = 'view-all-user';
export const VIEW_USER_BY_TIER = 'view-user-by-tier';
export const ADD_ALL_USER = 'add-all-user';
export const ADD_USER_BY_TIER = 'add-user-by-tier';
export const EDIT_ALL_USER = 'edit-all-user';
export const EDIT_USER_BY_TIER = 'edit-user-by-tier';
export const EDIT_SELF = 'edit-self';
export const ASSIGN_ROLE = 'assign-role';
export const VIEW_ALL_CLIENTS = 'view-all-clients';
export const VIEW_ASSIGNED_CLIENTS = 'view-assigned-clients';
export const CAN_BE_ASSIGNED_CLIENT = 'can-be-assigned-client';
export const CAN_BE_ASSIGNED_MANAGER = 'can-be-assigned-manager';
