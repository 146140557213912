import { customFormatTypes } from 'constants/formatConfig';
import {
  DATE_SORTER,
  NUMERIC_SORTER,
  EDIT_BUTTON_COLUMN,
} from 'components/common/table/constants';

// Need this constant since it needs to be initially sorted
export const PRIORITY_FIELD = 'priority';
export const RULE_BASE_ID = 'rule_base_id';

export const columnConfig = [
  {
    field: 'rule_base_id',
    title: 'ID',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 90,
    },
  },
  {
    field: 'client_id',
    title: 'Client Id',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 135,
    },
  },
  {
    field: 'version_id',
    title: 'Version',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 135,
    },
  },
  {
    field: PRIORITY_FIELD,
    title: 'Priority',
    isShownByDefault: true,
    isEditable: false,
    sorter: NUMERIC_SORTER,
    additionalConfig: {
      width: 135,
    },
  },
  {
    field: 'rule_name',
    title: 'Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'rule_category',
    title: 'Category',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      width: 170,
    },
  },
  {
    field: 'rule_state',
    title: 'State',
    isShownByDefault: true,
    formatOfData: customFormatTypes.state,
    isEditable: false,
    additionalConfig: {
      width: 120,
    },
  },
  {
    field: 'create_date',
    title: 'Create Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      width: 165,
    },
  },
  {
    field: 'update_date',
    title: 'Update Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      width: 165,
    },
  },
  {
    field: 'update_user',
    title: 'Update User',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: EDIT_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      resizable: false,
      width: 50,
      cssClass: 'action-btn-cell text-center',
    },
  },
];
