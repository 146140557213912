import React from 'react';

const PageNotFound = () => {
  return (
    <div
      className="main-content px-5x pb-5x d-flex flex-direction-column"
      style={{
        margin: '0px',
        width: 'auto',
      }}
    >
      <div className="empty-page__content p-10x">
        <p className="text-center text-lg color-grey--80 fw-600">
          404 Not Found
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
