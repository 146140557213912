import http from 'utils/http';
import { interpolate } from 'utils/common/string';

import config from '../config';

export async function getUsers(params = {}) {
  const url = config.endpoints.users;
  const { data } = await http.get(url, { params });

  return data.data;
}

export async function getUserById(userId) {
  const url = interpolate(config.endpoints.userDetail, { userId });
  const { data } = await http.get(url);

  return data.data;
}

export async function updatePassword(userId, password) {
  const url = interpolate(config.endpoints.updatePassword, { userId });
  const { data } = await http.put(url, {
    password,
  });

  return data.data;
}

export async function createUser(body) {
  const url = config.endpoints.users;

  const { data } = await http.post(url, { ...body });

  return data.data;
}

export async function updateUser(userId, payload) {
  const url = interpolate(config.endpoints.userDetail, { userId });

  const { data } = await http.put(url, { ...payload });

  return data.data;
}

/**
 * Fetches users with their clients.
 *
 * @returns {Promise<Object[]>} A promise that resolves to an array of users with clients.
 */
export async function getUsersWithClients() {
  const url = config.endpoints.usersWithClients;
  const { data } = await http.get(url);

  return data.data;
}
