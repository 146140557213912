const loaderSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="85" style="shape-rendering: auto; display: block; background: rgba(255, 255, 255, 0);" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g transform="rotate(0 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.7051282051282051s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(30 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.641025641025641s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(60 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.5769230769230769s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(90 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.5128205128205128s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(120 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.4487179487179487s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(150 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.3846153846153846s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(180 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.3205128205128205s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(210 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.2564102564102564s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(240 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.1923076923076923s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(270 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.1282051282051282s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(300 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="-0.0641025641025641s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g transform="rotate(330 50 50)">
  <rect fill="#01263c" height="16" width="7" ry="3.84" rx="3.5" y="22" x="46.5">
    <animate repeatCount="indefinite" begin="0s" dur="0.7692307692307692s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
  </rect>
</g><g></g></g><!-- [ldio] generated by https://loading.io --></svg>`;

export const GLOBAL_PAGINATION_CONFIG = {
  pagination: true,
  paginationMode: 'remote',
  paginationSize: 10,
  dataSendParams: {
    size: 'per_page',
  },
  ajaxResponse: (_url, _params, response) => {
    response.last_page = response.meta.total_pages;
    response.current_page = response.meta.current_page;

    return response;
  },
  dataLoaderLoading: loaderSvg,
};
