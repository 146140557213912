import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @deprecated Use Notify.success/error/info inplace of Toast
 * Returns toast components
 * @param {*} props
 * @returns {JSX} Toast
 */
const Toast = (props) => {
  const toastClassName = classNames({
    toast: true,
    'has-error': props.hasError,
  });

  return (
    <div className={toastClassName}>
      <div className="toast__title">{props.title}</div>
      <div className="toast__actions">
        {props.hasActions ? (
          <>
            <button className="btn btn-link" onClick={props.handleReset}>
              Reset
            </button>
            <button className="btn btn-primary" onClick={props.handleSave}>
              Save
            </button>
          </>
        ) : (
          <button className="btn btn-primary" onClick={props.handleClose}>
            Close
          </button>
        )}
      </div>
    </div>
  );
};

Toast.propTypes = {
  title: PropTypes.string,
  hasError: PropTypes.bool,
  hasActions: PropTypes.bool,
  handleSave: PropTypes.func,
  handleClose: PropTypes.func,
  handleReset: PropTypes.func,
};

export default Toast;
