import { customFormatTypes } from 'constants/formatConfig';

export const aggregateStatCardConfig = {
  maxInsertDate: {
    key: 'max_insert_date',
    label: 'Max Insert Date',
    format: customFormatTypes.date,
  },
  minInsertDate: {
    key: 'min_insert_date',
    label: 'Min Insert Date',
    format: customFormatTypes.date,
  },
  recordCount: {
    key: 'row_count',
    label: 'Record Count',
    format: customFormatTypes.numericWithCommas,
  },
};

export const computedStatCardConfig = {
  recordCount: {
    key: 'record_count',
    label: 'Record Count',
    format: customFormatTypes.numericWithCommas,
  },
  balanceCount: {
    key: 'balance_count',
    label: 'Balance Count',
    format: customFormatTypes.currency,
  },
};
