import { PROJECT_RULE_BASE_ID } from './columnConfigs';

export const qaTableConfig = {
  index: PROJECT_RULE_BASE_ID,
  pagination: false,
};

export const aggregateTableConfig = {
  pagination: false,
};
