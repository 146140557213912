import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../icons/Icons';
import { createRoot } from 'react-dom/client';

class CustomEditButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const buttonClass = classnames({
      'btn-link': this.props.disabled,
      'btn-circle': !this.props.disabled
    });

    return (
      <button className={buttonClass} disabled = {this.props.disabled}>
        <Icon
          className="icon-filter-search"
          icon="edit"
          size={16}
          color="#808285"
        />
      </button>
    );
  }
}

CustomEditButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export const editButtonFormatter = (cell, formatterParams, onRendered) => {
  const container = document.createElement('div');
  container.classList.add('actions-btn');
  const root = createRoot(container);

  root.render(
    <CustomEditButton
      cell={cell}
      formatterParams={formatterParams}
      onRendered={onRendered}
    />
  );

  return container;
};

export default CustomEditButton;
