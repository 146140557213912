import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';
import { removeEmptyPayloadValues } from 'utils/payload';

export async function getJobStatus(projectId) {
  const url = interpolate(config.endpoints.jobStatus, { projectId });
  const { data } = await http.get(url);

  return data.data;
}

export async function getJobData(clientId, projectId) {
  const url = config.endpoints.jobData;
  const params = removeEmptyPayloadValues(
    {
      client_id: clientId,
      project_id: projectId,
    },
    true
  );
  const { data } = await http.get(url, { params });

  return data.data;
}
