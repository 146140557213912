import React from 'react';
import PropTypes from 'prop-types';
import iconPath from './iconsLib';

const Icon = ({ width, height, size, color, icon, className, viewBox }) => {
  return (
    <svg
      className={className}
      width={width ? width : size}
      height={height ? height : size}
      preserveAspectRatio="xMidYMid meet"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={color}
        d={iconPath[icon]}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

Icon.defaultProps = {
  color: '#000000',
  viewBox: '0 0 50 50',
  className: '',
};

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
  icon: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
