import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Icon from 'components/common/icons/Icons';
import Table from 'components/common/table/Table';
import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyProjectPage from 'components/common/emptypage/EmptyProjectPage';
import ContinueButton from 'components/common/continueButton/continueButton';

import * as routes from 'constants/routes';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
} from 'actions/clientAction';

import { updateProject } from 'services/projectAnalysis';
import { getPublisherData, downloadReports } from 'services/publisher';

import {
  columnConfig,
  INCLUDE_REPORT,
  EXCLUDE_DATA_CONCERNS,
} from './columnConfig';
import { tableConfig } from './tableConfig';

import { formatDate, formatDateWithTime } from 'utils/common/formatter';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList, selectedProject } = state;

  return { selectedClientId, clientList, selectedProject };
};

class Publisher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      navigateTo: '',
      hasError: false,
      errorMessage: '',
      isLoading: false,
      hasNewData: false,
      isDownloading: false,
      includeReportIDs: [],
      excludeDataConcernsIDs: [],
    };
  }

  async componentDidMount() {
    if (this.props.selectedClientId && this.props.selectedProject.projectId) {
      await this.getPublisherData();
    }
  }

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      rowData: {},
    });

    this.props.updateClientID(clientId);
    this.setState({
      navigateTo: routes.PROJECT_ANALYSIS,
    });
  };

  handleTableToggleButtonClick = (_, cell) => {
    const rowData = cell.getRow().getData();
    const field = cell.getField();
    const foundIndex = this.state.data.findIndex(
      (elem) => elem.rule_base_id === rowData.rule_base_id
    );

    if (field === INCLUDE_REPORT) {
      let includeIDs;
      includeIDs = [...this.state.includeReportIDs];
      const newIncludeIdIndex = includeIDs.indexOf(rowData.rule_base_id);

      if (newIncludeIdIndex === -1) {
        includeIDs.push(rowData.rule_base_id);
      } else {
        const index = newIncludeIdIndex;
        includeIDs.splice(index, 1);
      }

      this.setState({
        includeReportIDs: includeIDs,
      });
    }

    if (field === EXCLUDE_DATA_CONCERNS) {
      let excludeIDs;
      excludeIDs = [...this.state.excludeDataConcernsIDs];
      const newExcludeIdIndex = excludeIDs.indexOf(rowData.rule_base_id);

      if (newExcludeIdIndex === -1) {
        excludeIDs.push(rowData.rule_base_id);
      } else {
        const index = newExcludeIdIndex;
        excludeIDs.splice(index, 1);
      }

      this.setState({
        excludeDataConcernsIDs: excludeIDs,
      });
    }

    const newRowData = {
      ...rowData,
      [field]: !rowData[field],
    };

    let newTableData = [...this.state.data];
    newTableData[foundIndex] = newRowData;

    this.setState({
      hasNewData: !this.state.hasNewData,
      data: newTableData,
    });
  };

  handleNextStep = async () => {
    const { selectedClientId, selectedProject, updateSelectedProject } =
      this.props;

    if (selectedProject.projectId) {
      try {
        // This update is just to update the update date of the project without updating anything else
        const data = await updateProject(
          selectedClientId,
          selectedProject.projectId,
          {
            projectName: selectedProject.projectName,
          }
        );

        updateSelectedProject({
          ...selectedProject,
          lastUpdateDate: formatDateWithTime(data.update_date),
        });

        this.setState({
          navigateTo: routes.STATEMENT,
        });
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DEFAULT_ERROR_MESSAGE;

        this.setState({
          errorMessage,
          hasError: false,
          isLoading: false,
        });
      }
    }
  };

  getPublisherData = async () => {
    const { selectedClientId, selectedProject } = this.props;

    if (!selectedClientId || !selectedProject.projectId) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    try {
      const data = await getPublisherData(selectedProject.projectId);
      const addedData = data.map((row) => {
        return {
          ...row,
          [INCLUDE_REPORT]: true,
          [EXCLUDE_DATA_CONCERNS]: false,
        };
      });
      const rulebaseIds = data.map((row) => row.rule_base_id);

      this.setState({
        data: addedData,
        includeReportIDs: rulebaseIds,
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: false,
        isLoading: false,
      });
    }
  };

  handleDownloadReports = async () => {
    const { selectedProject, selectedClientId } = this.props;
    const { includeReportIDs, excludeDataConcernsIDs } = this.state;
    const now = Date.now();

    if (!selectedProject.projectId) {
      return;
    }

    this.setState({
      isDownloading: true,
    });

    const blob = await downloadReports(
      selectedProject.projectId,
      includeReportIDs,
      excludeDataConcernsIDs
    );
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute(
      'download',
      `Publisher_${selectedProject.projectName}_${formatDate(now)}.xlsx`
    );
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    await updateProject(selectedClientId, selectedProject.projectId, {
      publishDate: new Date(),
    });

    this.setState({
      isDownloading: false,
    });
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      data,
      hasError,
      isLoading,
      hasNewData,
      navigateTo,
      errorMessage,
      isDownloading,
    } = this.state;
    const { clientList, selectedClientId, selectedProject } = this.props;
    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );

    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });

    const projectDisplayName = selectedProject.projectName || '';

    return navigateTo ? (
      <Navigate to={navigateTo} replace={true} />
    ) : (
      <>
        <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
          <div className="profile mb-3x ml-auto">
            <Dropdown
              label={displayName}
              dropdownItems={dropdownItems}
              onClick={this.handleSelectClient}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-1x">
            <h1>
              Publisher{' '}
              {projectDisplayName && <span>({projectDisplayName})</span>}
            </h1>
            {selectedProject.status && (
              <div className="badge badge-lg mr-auto ml-5x mt-3x">
                {selectedProject.status}
              </div>
            )}

            <div className="color-grey--60 mt-4x">
              Last Updated : {selectedProject.lastUpdateDate}
            </div>
          </div>
        </div>
        {isLoading && <Loader isFullScreen={true} />}
        {selectedProject.projectId ? (
          <div
            className="d-flex flex-direction-column"
            style={{ height: 'calc(100vh - 132px)' }}
          >
            <div className="has-box-shadow">
              <Table
                data={data}
                columnConfig={columnConfig}
                hasNewData={hasNewData}
                tableConfig={tableConfig}
                handleTableToggleButtonClick={this.handleTableToggleButtonClick}
              />
            </div>
            <div className="d-flex flex-direction-column align-items-end mt-5x">
              <button
                className="btn btn-primary has-loader"
                onClick={this.handleDownloadReports}
              >
                <Icon
                  className="mr-2x"
                  icon="download"
                  size={14}
                  color="#DADADA"
                />
                Download Excel
                {isDownloading && <span className="spinner" />}
              </button>
            </div>

            <div className="mt-auto">
              <ContinueButton onClick={this.handleNextStep} />
            </div>
          </div>
        ) : (
          <EmptyProjectPage pageName="Publisher" />
        )}

        {hasError && (
          <Toast
            title={errorMessage}
            hasError={hasError}
            handleClose={this.resetError}
          />
        )}
      </>
    );
  }
}

Publisher.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedProject: PropTypes.object,
  clearSelectedProject: PropTypes.func,
  updateSelectedProject: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
})(Publisher);
