import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import clientReducer from 'reducers/clientReducer';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, clientReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistedStore = persistStore(store);

export default store;
