export const dataHintHeaderConfig = [
  {
    source: 'data_hint_rule_id',
    label: 'ID',
  },
  {
    source: 'client_id',
    label: 'Client ID',
  },
  {
    source: 'version',
    label: 'Version',
  },
  {
    source: 'rule_name',
    label: 'Name',
  },
  {
    source: 'rule_category',
    label: 'Category',
  },
];
