import React from 'react';
import PropTypes, { string, object } from 'prop-types';
import { TabulatorFull as Tabulator } from 'tabulator-tables';

import 'tabulator-tables/dist/css/tabulator.min.css';

import { showLogFormatter } from './CustomLogButton';
import { editModalFormatter } from './CustomEditModal';
import { showErrorFormatter } from './CustomErrorButton';
import { editButtonFormatter } from './CustomEditButton';
import { trashButtonFormatter } from './CustomTrashButton';
import { exportButtonFormatter } from './CustomExportButton';
import { replayButtonFormatter } from './CustomReplayButton';
import { exploreButtonFormatter } from './CustomExploreButton';
import { viewDetailButtonFormatter } from './CustomViewDetailButton';
import { headerFormatter, emptyHeaderFilter } from './HeaderPopUp';

import icons from '../icons/iconsLib';
import * as tableConstants from './constants';
import { formatAnyValue } from 'utils/common/formatter';

import { customDateSorter, customAmountSorter, calculateSum } from './utils';
import { STRING_TYPE } from 'constants/formatConfig';

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.tableElement = React.createRef();
    this.tabulator = null;
  }

  componentDidMount() {
    if (!this.tabulator) {
      const tabulatorInitialConfig = {
        columns: this.getColumns(),
        ...tableConstants.defaultTableConfig,
        ...this.props.tableConfig,
      };
      // Only set the data attribute if the local pagination is done else ajaxUrl will handle the initial and subsequent data loading
      if (this.props.ajaxUrl && !this.props.ajaxUrl.length > 0) {
        tabulatorInitialConfig.data = this.formatData(this.props.data);
      }

      this.tabulator = new Tabulator(
        this.tableElement.current,
        tabulatorInitialConfig
      );

      this.props.onRowClick &&
        this.tabulator.on(tableConstants.ROW_CLICK, this.props.onRowClick);

      this.props.onRowSelection &&
        this.tabulator.on(
          tableConstants.ROW_SELECTION_CHANGED,
          this.props.onRowSelection
        );

      this.props.onDataFiltered &&
        this.tabulator.on(
          tableConstants.DATA_FILTERED,
          this.props.onDataFiltered
        );
    }

    this.highlightRows();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && prevProps.data.length !== this.props.data.length) {
      const formattedData = this.formatData(this.props.data);
      this.tabulator.setData(formattedData);
    }

    if (
      prevProps.hasNewData !== undefined &&
      this.props.hasNewData !== undefined &&
      prevProps.hasNewData !== this.props.hasNewData
    ) {
      const formattedData = this.formatData(this.props.data);
      this.tabulator.setData(formattedData);
    }

    if (
      prevProps.selectedColumns &&
      this.props.selectedColumns &&
      this.props.updatedColumnConfig &&
      prevProps.selectedColumns.length !== this.props.selectedColumns.length
    ) {
      const { field, isShownByDefault } = this.props.updatedColumnConfig;

      if (field) {
        const columnDefinition = this.tabulator.getColumn(field);

        isShownByDefault ? columnDefinition.show() : columnDefinition.hide();
      }
    }

    if (
      (this.props.selectedRows &&
        this.props.selectedRows.length > 0 &&
        prevProps.data.length !== this.props.data.length) ||
      (prevProps.hasNewData !== undefined &&
        this.props.hasNewData !== undefined &&
        prevProps.hasNewData !== this.props.hasNewData)
    ) {
      this.props.selectedRows &&
        this.tabulator.selectRow(this.props.selectedRows);
    }

    if (this.props.selectedRow) {
      this.tabulator.selectRow(this.props.selectedRow);
    }

    this.highlightRows();
  }

  highlightRows = () => {
    if (this.props.highlightRowConfig) {
      const { field, values, dataType } = this.props.highlightRowConfig;
      let rowsToHighlight = [];
      if (dataType === STRING_TYPE) {
        rowsToHighlight = this.tabulator
          .getRows()
          .filter((row) => values.includes(row.getData()[field].toUpperCase()));
      } else {
        rowsToHighlight = this.tabulator
          .getRows()
          .filter((row) => values.includes(row.getData()[field]));
      }

      rowsToHighlight.map((row) => {
        const rowElement = row.getElement();
        rowElement.className = `${rowElement.className} ${tableConstants.HIGHLIGHT_ROW_CLASSNAME}`;
      });
    }
  };

  formatData = (rawData) => {
    const { columnConfig } = this.props;
    const formattedData = rawData.map((row) => {
      Object.keys(row).forEach((key) => {
        const foundConfig = columnConfig.find((col) => col.field === key);
        const format =
          foundConfig && foundConfig.formatOfData
            ? foundConfig.formatOfData
            : '';
        const rowValue = row[key];

        row[key] = formatAnyValue(format, rowValue);
      });

      return row;
    });

    return formattedData;
  };

  getColumns = () => {
    const {
      cellEdited,
      columnConfig,
      categoryList,
      handleLogClick,
      handleErrorClick,
      handleEditModalClick,
      handleEditButtonClick,
      handleRerunButtonClick,
      handleTrashButtonClick,
      handleExportButtonClick,
      handleExploreButtonClick,
      handleViewDetailButtonClick,
      handleTableToggleButtonClick,
    } = this.props;
    const columns = columnConfig.map((column) => {
      const {
        field,
        title,
        sorter,
        editor,
        isEditable,
        columnType,
        customSorter,
        editorParams,
        isShownByDefault,
        additionalConfig,
        customTotalCalculator,
      } = column;

      let extraConfig = additionalConfig ? additionalConfig : {};

      if (field === tableConstants.EDIT_BUTTON_COLUMN) {
        return {
          field: '',
          formatter: editButtonFormatter,
          cellClick: handleEditButtonClick,
          tooltip: function () {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = 'Edit';

            return el;
          },
          ...extraConfig,
        };
      }

      if (field === tableConstants.EDIT_MODAL_COLUMN) {
        return {
          field: '',
          title,
          formatter: editModalFormatter,
          cellClick: handleEditModalClick,
          tooltip: function () {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = 'Edit';

            return el;
          },
          headerTooltip: function (_e, column, _onRendered) {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = column.getDefinition().title;

            return el;
          },
          ...extraConfig,
        };
      }

      if (field === tableConstants.EXPLORE_BUTTON_COLUMN) {
        return {
          field: '',
          formatter: exploreButtonFormatter,
          cellClick: handleExploreButtonClick,
          tooltip: function () {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = 'Explore';

            return el;
          },
          ...extraConfig,
        };
      }

      if (field === tableConstants.EXPORT_BUTTON_COLUMN) {
        return {
          field: '',
          formatter: exportButtonFormatter,
          cellClick: handleExportButtonClick,
          tooltip: function () {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = 'Export';

            return el;
          },
          ...extraConfig,
        };
      }

      if (field === tableConstants.LOG_COLUMN) {
        return {
          field: '',
          title,
          formatter: showLogFormatter,
          cellClick: handleLogClick,
          tooltip: function (_e, _cell, _onRendered) {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = 'Logs';

            return el;
          },
          ...extraConfig,
        };
      }

      if (field === tableConstants.ERROR_COLUMN) {
        return {
          field: '',
          formatter: showErrorFormatter,
          cellClick: handleErrorClick,
          tooltip: function (_e, _cell, _onRendered) {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = 'Errors';

            return el;
          },
          ...extraConfig,
        };
      }

      if (field === tableConstants.VIEWDETAIL_BUTTON_COLUMN) {
        return {
          field: '',
          formatter: viewDetailButtonFormatter,
          cellClick: handleViewDetailButtonClick,
          tooltip: function () {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = 'View Detail';

            return el;
          },
          ...extraConfig,
        };
      }

      if (field === tableConstants.RERUN_BUTTON_COLUMN) {
        return {
          field: '',
          formatter: replayButtonFormatter,
          cellClick: handleRerunButtonClick,
          tooltip: function () {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = 'Rerun';

            return el;
          },
          ...extraConfig,
        };
      }

      if (field === tableConstants.TRASH_BUTTON_COLUMN) {
        return {
          field: '',
          formatter: trashButtonFormatter,
          cellClick: handleTrashButtonClick,
          tooltip: function () {
            let el = document.createElement('div');
            el.classList.add('table__tooltip');
            el.innerText = 'Delete';

            return el;
          },
          ...extraConfig,
        };
      }

      if (field === tableConstants.CHECKBOX_COLUMN) {
        return {
          field: '',
          ...extraConfig,
        };
      }

      if (columnType === tableConstants.TOGGLE_CHECKBOX_COLUMN) {
        return {
          field,
          title,
          cellClick: handleTableToggleButtonClick,
          ...extraConfig,
        };
      }

      if (customTotalCalculator === tableConstants.CUSTOM_SUM_CALCULTOR) {
        extraConfig = {
          ...extraConfig,
          bottomCalc: calculateSum,
        };
      }

      if (editor === tableConstants.LIST_EDITOR) {
        editorParams.values = categoryList;
      }

      let editorConfig = isEditable
        ? {
            editor,
            editorParams: editorParams ? editorParams : {},
          }
        : {};

      let columnSorter = tableConstants.DEFAULT_SORTER;

      if (sorter) {
        columnSorter = sorter;
      }

      if (customSorter && customSorter === tableConstants.DATE_SORTER) {
        columnSorter = customDateSorter;
      }

      if (customSorter && customSorter === tableConstants.AMOUNT_SORTER) {
        columnSorter = customAmountSorter;
      }

      return {
        field,
        title,
        tooltip: function (_e, cell, _onRendered) {
          let el = document.createElement('div');
          el.classList.add('table__tooltip');
          el.innerText = cell.getValue();

          return el;
        },
        headerTooltip: function (_e, column, _onRendered) {
          let el = document.createElement('div');
          el.classList.add('table__tooltip');
          el.innerText = column.getDefinition().title;

          return el;
        },
        minWidth: '90px',
        headerPopup: headerFormatter,
        headerFilter: emptyHeaderFilter,
        headerPopupIcon: `<svg width="15" height="15" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="${icons.search}" fill="#A8A9AD"/>
        </svg>
        `,
        headerFilterFunc: 'like',
        cellEdited: cellEdited,
        sorter: columnSorter,
        visible: isShownByDefault,
        editable: isEditable,
        ...editorConfig,
        ...extraConfig,
      };
    });

    return columns;
  };

  render() {
    return <div ref={this.tableElement} />;
  }
}

export default Table;

Table.propTypes = {
  onRowClick: PropTypes.func,
  cellEdited: PropTypes.func,
  hasNewData: PropTypes.bool,
  tableConfig: PropTypes.object,
  onRowSelection: PropTypes.func,
  onDataFiltered: PropTypes.func,
  data: PropTypes.arrayOf(object),
  highlightRowConfig: PropTypes.shape({
    field: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    dataType: PropTypes.string,
  }),
  handleLogClick: PropTypes.func,
  handleErrorClick: PropTypes.func,
  handleEditModalClick: PropTypes.func,
  handleEditButtonClick: PropTypes.func,
  updatedColumnConfig: PropTypes.object,
  handleRerunButtonClick: PropTypes.func,
  handleTrashButtonClick: PropTypes.func,
  handleExportButtonClick: PropTypes.func,
  categoryList: PropTypes.arrayOf(string),
  selectedRows: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  selectedRow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columnConfig: PropTypes.arrayOf(object),
  handleExploreButtonClick: PropTypes.func,
  selectedColumns: PropTypes.arrayOf(string),
  handleViewDetailButtonClick: PropTypes.func,
  handleTableToggleButtonClick: PropTypes.func,
  ajaxUrl: PropTypes.string,
};
