import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Item = ({ label, value, className, labelClassName, valueClassName }) => {
  return (
    <div className={classNames('item', className)}>
      <h5 className={classNames('item__label', labelClassName)}>{label}</h5>
      <p className={classNames('item__value', valueClassName)}>{value}</p>
    </div>
  );
};

Item.propTypes = {
  /** The label to display */
  label: PropTypes.string.isRequired,

  /** The value associated with the label */
  value: PropTypes.any.isRequired,

  /** Additional CSS class(es) for the main container */
  className: PropTypes.string,

  /** Additional CSS class(es) for the label element */
  labelClassName: PropTypes.string,

  /** Additional CSS class(es) for the value element */
  valueClassName: PropTypes.string,
};

export default Item;
