import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import config from 'config';
import { LOGIN } from 'constants/routes';
import { events } from 'constants/trackEvents';

import * as storage from 'utils/storage';

const ProtectedRoute = ({ children }) => {
  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, config.idleLogoutTime);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    storage.clear();
  };

  const username = storage.get('username');

  if (!username) {
    return <Navigate to={LOGIN} replace={true} />;
  }

  // <Outlet /> is used to render the matching nested route component defined in Router.js
  // all the nested route there will be passed as an outlet here by react-router-dom
  return children ? children : <Outlet />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ProtectedRoute;
