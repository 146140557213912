import React from 'react';
import classNames from 'classnames';

import { createRoot } from 'react-dom/client';

export const customNegativeValueColorFormatter = (
  cell,
  _formatterParams,
  _onRendered
) => {
  const container = document.createElement('div');
  const root = createRoot(container);

  const cellValue = cell.getValue();

  const colorClassname = classNames('color-black--base', {
    'color-danger--base': cellValue.startsWith('-'),
  });

  root.render(<div className={colorClassname}>{cellValue}</div>);

  return container;
};
