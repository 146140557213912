import React from 'react';
import Table from '../common/table/Table';
import PropTypes from 'prop-types';

import { tableConfig, highlightRowConfig } from './tableConfig';

const MappingBox = (props) => {
  return (
    <>
      <div className="mapping__box bg-white--base has-box-shadow p-5x">
        <h2>{props.title}</h2>
        <Table
          data={props.tableData}
          tableConfig={tableConfig}
          columnConfig={props.columnConfig}
          highlightRowConfig={highlightRowConfig}
        />

        <div className="text-right mt-3x">
          <button
            className="btn-outlined btn-evergreen--outlined"
            onClick={props.handleBtnClick}
          >
            {props.buttonLabel}
          </button>
        </div>
      </div>
    </>
  );
};

MappingBox.propTypes = {
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  handleBtnClick: PropTypes.func,
  tableData: PropTypes.arrayOf(PropTypes.object),
  columnConfig: PropTypes.arrayOf(PropTypes.object),
};
export default MappingBox;
