import { customFormatTypes } from 'constants/formatConfig';
import {
  DATE_SORTER,
  NUMERIC_SORTER,
  EDIT_BUTTON_COLUMN,
} from 'components/common/table/constants';

// Need this constant since it needs to be initially sorted
export const RULE_BASE_ID = 'data_hint_rule_id';
export const CREATE_DATE = 'create_date';

export const columnConfig = [
  {
    field: 'data_hint_rule_id',
    title: 'ID',
    isShownByDefault: true,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 90,
    },
  },
  {
    field: 'client_id',
    title: 'Client Id',
    isShownByDefault: true,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 135,
    },
  },
  {
    field: 'version',
    title: 'Version',
    isShownByDefault: true,
    sorter: NUMERIC_SORTER,
    isEditable: false,
    additionalConfig: {
      width: 135,
    },
  },
  {
    field: 'rule_name',
    title: 'Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'rule_category',
    title: 'Category',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      width: 170,
    },
  },
  {
    field: 'create_date',
    title: 'Create Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      width: 165,
    },
  },
  {
    field: 'update_date',
    title: 'Update Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    additionalConfig: {
      width: 165,
    },
  },
  {
    field: 'update_user',
    title: 'Update User',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: EDIT_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      resizable: false,
      width: 50,
      cssClass: 'action-btn-cell text-center',
    },
  },
];
