import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Icon from 'components/common/icons/Icons';
import Toast from 'components/common/toast/Toast';
import Table from 'components/common/table/Table';
import Modal from 'components/common/modal/Modal';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyProjectPage from 'components/common/emptypage/EmptyProjectPage';

import DeleteModal from './ValidationDeleteModal';

import { tableConfig } from './tableConfig';
import { columnConfig } from './columnConfig';

import * as routes from 'constants/routes';
import { JOB_STATUS_MAP, MAPPING } from 'constants/projectStatuses';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';
import {
  ERROR,
  ANALYSING,
  JOB_COMPLETED,
  JOB_SUBMITTED,
  JOB_VALIDATION,
  NO_JOB_STARTED,
} from 'constants/jobResponses';

import { updateProject } from 'services/projectAnalysis';
import * as validationService from 'services/validationChecker';

import {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
} from 'actions/clientAction';
import { getJobStatus } from 'services/jobs';

import { formatDateWithTime } from 'utils/common/formatter';
import ContinueButton from 'components/common/continueButton/continueButton';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList, selectedProject } = state;

  return { selectedClientId, clientList, selectedProject };
};

class ValidationChecker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      navigateTo: '',
      hasError: false,
      jobStatus: null,
      errorMessage: '',
      isLoading: false,
      isDeleting: false,
      isShowingModal: false,
      isRunningValidations: false,
      transactionMappingData: [],
    };
  }

  async componentDidMount() {
    await this.getValidationCheckerData();
  }

  getValidationCheckerData = async () => {
    const { selectedProject } = this.props;

    if (selectedProject.projectId) {
      try {
        const response = await getJobStatus(selectedProject.projectId);
        const statusData = response[JOB_VALIDATION.toLowerCase()];

        if (!statusData) {
          this.setState({
            jobStatus: NO_JOB_STARTED,
          });

          return;
        }

        this.setState({
          jobStatus: statusData.job_status,
        });

        if (statusData.job_status === JOB_COMPLETED) {
          this.setState({
            isLoading: true,
          });
          const data = await validationService.getValidationCheckerData(
            selectedProject.projectId
          );

          this.setState({
            data,
            isLoading: false,
          });
        }
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DEFAULT_ERROR_MESSAGE;

        this.setState({
          errorMessage,
          hasError: true,
          isLoading: false,
        });
      }
    }

    if (this.props.clientList.length === 0) {
      await this.updateClientList();
    }
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      rowData: {},
      navigateTo: routes.PROJECT_ANALYSIS,
    });

    this.props.updateClientID(clientId);
    this.props.clearSelectedProject();
  };

  handleShowModal = () => {
    this.setState({
      isShowingModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isShowingModal: false,
    });
  };

  handleDeleteValidations = async () => {
    const { selectedClientId, selectedProject } = this.props;

    if (selectedProject.projectId) {
      this.setState({
        isDeleting: true,
      });

      try {
        await validationService.deleteValidations(
          selectedProject.projectId,
          selectedClientId
        );

        this.setState({
          data: [],
          isDeleting: false,
          isShowingModal: false,
        });
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DEFAULT_ERROR_MESSAGE;

        this.setState({
          errorMessage,
          hasError: true,
          isLoading: false,
          isDeleting: false,
          isShowingModal: false,
        });
      }
    }
  };

  handleRunValidations = async () => {
    const { selectedClientId, selectedProject } = this.props;

    this.setState({
      isRunningValidations: true,
    });

    if (selectedProject.projectId) {
      try {
        await validationService.runValidations({
          project_id: selectedProject.projectId,
          client_id: selectedClientId,
        });

        this.setState({
          jobStatus: JOB_SUBMITTED,
          isRunningValidations: false,
        });
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DEFAULT_ERROR_MESSAGE;

        this.setState({
          errorMessage,
          hasError: true,
          isRunningValidations: false,
        });
      }
    }
  };

  handleNextStep = async () => {
    const { selectedClientId, selectedProject, updateSelectedProject } =
      this.props;

    if (selectedProject.projectId) {
      try {
        const data = await updateProject(
          selectedClientId,
          selectedProject.projectId,
          {
            status: MAPPING.toUpperCase(),
          }
        );

        updateSelectedProject({
          ...selectedProject,
          status: MAPPING.toUpperCase(),
          lastUpdateDate: formatDateWithTime(data.update_date),
        });

        this.setState({
          navigateTo: routes.MAPPING,
        });
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DEFAULT_ERROR_MESSAGE;

        this.setState({
          errorMessage,
          hasError: true,
          isLoading: false,
        });
      }
    }
  };

  handleBadgeClick = () => {
    window.open(routes.JOBS, '_blank');
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      data,
      hasError,
      jobStatus,
      isLoading,
      isDeleting,
      navigateTo,
      errorMessage,
      isShowingModal,
      isRunningValidations,
    } = this.state;
    const { clientList, selectedClientId, selectedProject } = this.props;

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });

    const projectDisplayName = selectedProject.projectName || '';

    const badgeStatus =
      JOB_STATUS_MAP[jobStatus] || selectedProject?.status || '';

    const badgeClass = classNames({
      badge: true,
      error: badgeStatus === ERROR,
      highlight: badgeStatus === ANALYSING,
    });

    return navigateTo ? (
      <Navigate to={navigateTo} replace={true} />
    ) : (
      <>
        <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
          <div className="profile mb-3x ml-auto">
            <Dropdown
              label={displayName}
              dropdownItems={dropdownItems}
              onClick={this.handleSelectClient}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-1x">
            <h1>
              Validation Checker{' '}
              {projectDisplayName && <span>({projectDisplayName})</span>}
            </h1>
            {badgeStatus && (
              <div
                role={'button'}
                className={`${badgeClass} badge-lg ml-5x mt-3x`}
                onClick={this.handleBadgeClick}
              >
                {badgeStatus}
              </div>
            )}

            <button
              className="btn-has-icon ml-5x mr-auto mt-3x"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <Icon
                icon="refresh"
                size={12}
                color="#546071"
                className="mr-2x"
              />
              Refresh
            </button>

            <div className="color-grey--60 mt-4x">
              Last Updated : {selectedProject.lastUpdateDate}
            </div>
          </div>
        </div>
        {isLoading && <Loader isFullScreen={true} />}
        {selectedProject.projectId ? (
          <>
            <div className="table has-box-shadow mb-4x">
              <Table
                className="has-box-shadow"
                tableConfig={tableConfig}
                columnConfig={columnConfig}
                pagination={false}
                data={data}
              />
            </div>
            <div className="d-flex justify-content-end align-items-end gap-4x mb-4x">
              <button
                className="btn-outlined btn-danger--outlined"
                onClick={this.handleShowModal}
              >
                Delete Validations
              </button>
              <button
                className="btn btn-primary has-loader"
                onClick={this.handleRunValidations}
              >
                Run Validations
                {isRunningValidations && <span className="spinner" />}
              </button>
            </div>
            <div className="mt-auto">
              <ContinueButton onClick={this.handleNextStep} />
            </div>
          </>
        ) : (
          <EmptyProjectPage />
        )}

        {isShowingModal && (
          <Modal onClose={this.handleCloseModal}>
            <DeleteModal
              heading={`Do you want to delete all the validations for ${selectedProject.projectName}?`}
              subHeading="This action cannot be reverted back. Are you sure you want to delete all
                  the validations?"
              isDeleting={isDeleting}
              onCancel={this.handleCloseModal}
              onDelete={this.handleDeleteValidations}
            />
          </Modal>
        )}

        {hasError && (
          <Toast
            title={errorMessage}
            hasError={hasError}
            handleClose={this.resetError}
          />
        )}
      </>
    );
  }
}

ValidationChecker.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedProject: PropTypes.object,
  clearSelectedProject: PropTypes.func,
  updateSelectedProject: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
})(ValidationChecker);
