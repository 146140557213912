import { convertCurrencyToNumber } from 'utils/common/string';
import { formatNumberToLocaleString } from 'utils/common/formatter';

export const customDateSorter = (
  a,
  b,
  _aRow,
  _bRow,
  _column,
  _dir,
  _sorterParams
) => {
  let dateA = new Date(a);
  let dateB = new Date(b);

  if (!a) {
    dateA = new Date(0);
  }

  if (!b) {
    dateB = new Date(b);
  }

  return dateA.getTime() - dateB.getTime();
};

export const customAmountSorter = (
  a,
  b,
  _aRow,
  _bRow,
  _column,
  _dir,
  _sorterParams
) => {
  if (!a) {
    return 1;
  }

  if (!b) {
    return -1;
  }

  if (!a && !b) {
    return 1;
  }

  return convertCurrencyToNumber(a) - convertCurrencyToNumber(b);
};

export const calculateSum = (values) => {
  let total = 0;

  values.forEach((value) => {
    let numericValue = convertCurrencyToNumber(value);

    if (numericValue) {
      total = total + numericValue;
    }
  });

  if (total) {
    return formatNumberToLocaleString(total);
  }

  return '';
};
