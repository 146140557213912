import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';

export async function getAssociatedRulesForProject(projectId, clientId) {
  const url = interpolate(config.endpoints.getAssociatedRules, {
    projectId,
    clientId,
  });
  const { data } = await http.get(url);

  return data.data;
}

export async function connectRulesToProject(projectId, ruleBaseIds) {
  const url = interpolate(config.endpoints.connectRules, { projectId });
  const { data } = await http.put(url, {
    rule_base_ids: ruleBaseIds,
  });

  return data.data;
}
