import { EDIT_MODAL_COLUMN } from 'components/common/table/constants';

export const columnConfig = [
  {
    field: 'user_id',
    title: 'User ID',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'email',
    title: 'Email',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'fname',
    title: 'First Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: 'lname',
    title: 'Last Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
  {
    field: EDIT_MODAL_COLUMN,
    title: 'Change Password',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      headerPopup: false,
      headerFilter: false,
      headerPopupIcon: '',
    },
  },
];
