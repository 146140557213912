import React, { useState } from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';

import PropTypes from 'prop-types';
import { NO_DATA_AVAILABLE } from 'constants/app';

export const BarTooltip = ({ show = false, active, payload }) => {
  if (show && active && payload?.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '5px',
          border: '1px solid #ccc',
        }}
      >
        <p>{`Count: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const BarGraph = (props) => {
  const [barHovered, setBarHovered] = useState(false);
  const {
    data,
    xLabel,
    yLabel,
    xDataKey,
    yDataKey,
    barColor,
    toolTip: ToolTipComponent,
  } = props;

  return data.length > 0 ? (
    <ResponsiveContainer>
      <BarChart
        data={data}
        isAnimationActive={false}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 30,
        }}
        barSize={50}
      >
        <CartesianGrid horizontal={true} vertical={false} />
        <XAxis dataKey={xDataKey} angle={-30} textAnchor="end" height={70}>
          <Label value={xLabel} offset={-20} position="insideBottom" />
        </XAxis>
        <YAxis tickFormatter={(tick) => (Number.isInteger(tick) ? tick : '')}>
          <Label
            value={yLabel}
            offset={-15}
            angle={-90}
            position="insideLeft"
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip
          content={<ToolTipComponent show={barHovered} />}
          cursor={false}
          animationDuration={0}
        />
        <Bar
          dataKey={yDataKey}
          fill={barColor}
          onMouseEnter={() => setBarHovered(true)}
          onMouseLeave={() => setBarHovered(false)}
        ></Bar>
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <div className="fallback-container">{NO_DATA_AVAILABLE}</div>
  );
};

BarGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  xDataKey: PropTypes.string,
  yDataKey: PropTypes.string,
  barColor: PropTypes.string,
  toolTip: PropTypes.elementType.isRequired,
};

BarTooltip.propTypes = {
  show: PropTypes.bool,
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default BarGraph;
