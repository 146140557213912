import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import React, { useState, useRef, useEffect } from 'react';

const Popover = ({ children, content, showPopup }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [popoverStyle, setPopoverStyle] = useState({});

  const popoverRef = useRef(null);
  const triggerRef = useRef(null);

  const toggleVisibility = (visibility) => {
    setIsVisible(visibility);
  };

  useEffect(() => {
    if (isVisible && triggerRef.current && popoverRef.current) {
      // Get the bounding rectangle of the trigger element (the element that triggers the popover)
      const triggerRect = triggerRef.current.getBoundingClientRect();

      // Get the bounding rectangle of the popover element
      const popoverRect = popoverRef.current.getBoundingClientRect();

      // Calculate the initial position of the popover
      const newPopoverStyle = {
        // Position the popover above the trigger element
        top: triggerRect.top + window.scrollY - popoverRect.height,
        // Center the popover horizontally with respect to the trigger element
        left:
          triggerRect.left +
          window.scrollX +
          (triggerRect.width / 2 - popoverRect.width / 2),
      };

      // Adjust if the popover is overflowing to the left side of the viewport
      if (newPopoverStyle.left < 0) {
        newPopoverStyle.left = 16;
      }

      setPopoverStyle(newPopoverStyle);
    }
  }, [isVisible]);

  return (
    <>
      <div
        ref={triggerRef}
        onMouseEnter={() => toggleVisibility(true)}
        onMouseLeave={() => toggleVisibility(false)}
        aria-haspopup="true"
        aria-expanded={isVisible}
        aria-controls="popover-content"
      >
        {children}
      </div>
      {isVisible &&
        showPopup &&
        createPortal(
          <div
            id="popover-content"
            ref={popoverRef}
            className="popover__content"
            role="dialog"
            aria-modal="true"
            style={popoverStyle}
          >
            {content}
          </div>,
          document.body
        )}
    </>
  );
};

Popover.defaultProps = {
  showPopup: true,
};

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  showPopup: PropTypes.bool,
};

export default Popover;
