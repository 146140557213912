import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';

export async function getTransactionByClientId(clientId) {
  const url = interpolate(config.endpoints.transactionMapping, { clientId });

  const { data } = await http.get(url);

  return data;
}

export async function getFinancialCategories() {
  const url = config.endpoints.financialCategories;

  const { data } = await http.get(url);

  return data;
}

export async function updateTransactionData(clientId, updatedData) {
  const url = interpolate(config.endpoints.transactionMapping, { clientId });

  const { data } = await http.put(url, {
    client_id: clientId,
    updates: updatedData,
  });

  return data;
}

export async function downloadReports(clientId) {
  const url = interpolate(config.endpoints.exportTransaction, { clientId });

  const { data } = await http.get(url, {
    responseType: 'blob',
  });

  return data;
}
