import { POST_DATE_FIELD } from './columnConfigs';
import { ASCENDING_ORDER } from 'components/common/table/constants';

export const dataIntegrityTableConfig = {
  autoResize: false,
  pagination: false,
};

export const financialsTableConfig = {
  initialSort: [
    {
      column: POST_DATE_FIELD,
      dir: ASCENDING_ORDER,
    },
  ],
};

export const ruleHitsTableConfig = {
  autoResize: false,
  pagination: false,
};

export const dataHintsResultsTableConfig = {
  autoResize: false,
  pagination: true,
};

export const billReferenceTableConfig = {
  pagination: false,
};

export const insuranceTableConfig = {
  pagination: false,
};
