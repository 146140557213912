import http from 'utils/http';
import config from '../config';

export async function getWorkTrackerAction(params = {}) {
  const workTrackerActionUrl = config.endpoints.workTrackerAction;

  const { data } = await http.get(workTrackerActionUrl, {
    params: params,
  });

  return data.data;
}
