export const clientDataOverviewDetailConfig = [
  {
    label: 'Count',
    key: 'total_count',
  },
  {
    label: 'Balance',
    key: 'total_balance',
  },
  {
    label: 'Date of Last Data Run',
    key: 'last_data_run_date',
  },
  {
    label: 'Max Financial Post Date',
    key: 'max_post_date',
  },
];

export const mappingDataConfig = [
  { label: 'Unmapped Insurance/s', key: 'unmappedInsurance' },
  { label: 'Unmapped Transaction/s', key: 'unmappedTransaction' },
];
