import {
  DATE_SORTER,
  AMOUNT_SORTER,
  NUMERIC_SORTER,
  CELL_ALIGN_LEFT,
  CELL_ALIGN_RIGHT,
  VIEWDETAIL_BUTTON_COLUMN,
} from 'components/common/table/constants';
import { customFormatTypes } from 'constants/formatConfig';

export const ACCOUNT_NUMBER = 'account_number';

export const columnConfig = [
  {
    field: 'account_id',
    title: 'Account ID',
    isShownByDefault: true,
    formatOfData: customFormatTypes.noFormat,
    sorter: NUMERIC_SORTER,
    isEditable: false,
  },

  {
    field: 'account_number',
    title: 'Account Number',
    isShownByDefault: true,
    formatOfData: customFormatTypes.noFormat,
    sorter: NUMERIC_SORTER,
    isEditable: false,
  },

  {
    field: 'rule_category',
    title: 'Rule Category',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },

  {
    field: 'rule_name',
    title: 'Rule Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },
  {
    field: 'location_name',
    title: 'Location',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },
  {
    field: 'state',
    title: 'State',
    isShownByDefault: true,
    formatOfData: customFormatTypes.state,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },
  {
    field: 'patient_class',
    title: 'Patient Class',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },
  {
    field: 'patient_type',
    title: 'Patient Type',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },
  {
    field: 'discharge_date',
    title: 'Discharge Date',
    isShownByDefault: true,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },
  {
    field: 'is_top_hit',
    title: 'Top Hit',
    isShownByDefault: true,
    formatOfData: customFormatTypes.boolean,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },
  {
    field: 'has_data_concern',
    title: 'Data Concern',
    isShownByDefault: true,
    formatOfData: customFormatTypes.boolean,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },
  {
    field: 'has_exemption',
    title: 'Exemption',
    isShownByDefault: true,
    formatOfData: customFormatTypes.boolean,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_LEFT,
      headerHozAlign: CELL_ALIGN_LEFT,
    },
  },
  {
    field: 'total_account_balance',
    title: 'Total Account',
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      hozAlign: CELL_ALIGN_RIGHT,
      headerHozAlign: CELL_ALIGN_RIGHT,
    },
  },
  {
    field: VIEWDETAIL_BUTTON_COLUMN,
    additionalConfig: {
      headerSort: false,
      resizable: false,
      width: 32,
      minWidth: 32,
      cssClass: 'action-btn-cell text-center',
    },
  },
];
