import { DATA_HINTS_RULE_TYPE } from 'constants/dataHints';
import { removeEmptyPayloadValues } from './payload';
import {
  updateDataHintSchema,
  addDataHintSchema,
} from 'components/dataHints/schema';

export const convertToAddDataHintPayload = (dataHint) => {
  const payloadWithoutEmptyValues = removeEmptyPayloadValues(dataHint, true);

  // cast to the schema and remove any extra values
  const payloadValue = addDataHintSchema.cast(payloadWithoutEmptyValues, {
    stripUnknown: true,
  });

  return payloadValue;
};

export const convertToEditDataHintPayload = (dataHint) => {
  const payloadWithoutEmptyValues = removeEmptyPayloadValues(dataHint, true);

  if (dataHint.rule_type === DATA_HINTS_RULE_TYPE.GENERIC) {
    delete payloadWithoutEmptyValues['client_ids'];
  }

  // cast to the schema and remove any extra values
  const payloadValue = updateDataHintSchema.cast(payloadWithoutEmptyValues, {
    stripUnknown: true,
  });

  return payloadValue;
};
