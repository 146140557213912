import React from 'react';

import PropTypes from 'prop-types';

import { createRoot } from 'react-dom/client';

const TooltipList = ({ values }) => {
  return (
    <ul style={{ listStyleType: 'disc' }}>
      {values.map((item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ul>
  );
};

TooltipList.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
};

export const clientListTooltipRenderer = (_e, cell, _onRendered) => {
  const values = cell.getValue();

  const container = document.createElement('div');
  container.className = 'p-0x';

  if (!Array.isArray(values) || !values.length) {
    container.className = 'd-none';
  }

  const root = createRoot(container);

  root.render(
    <div className="color-white--base bg-black--base p-4x px-8x">
      <TooltipList values={values} />
    </div>
  );

  return container;
};

export const clientCountFormatter = (cell, _formatterParams, _onRendered) => {
  const container = document.createElement('div');
  const root = createRoot(container);

  const value = cell.getValue();
  const client_count = value ? value.length : 0;
  root.render(<span className="color-grey--base fw-400">{client_count}</span>);

  return container;
};
