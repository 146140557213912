import React from 'react';
import PropTypes from 'prop-types';
import EmptyIcon from 'assets/images/empty-icon.svg';

const EmptyProjectPage = (props) => {
  return (
    <>
      <div
        className={
          props.className ? props.className : 'empty-page__content p-10x'
        }
      >
        <div className="icons-wrap text-center mb-7x">
          <img src={EmptyIcon} alt="Empty Icon" />
        </div>
        <p className="text-center text-lg color-grey--80 fw-600">
          {props.customMessage
            ? props.customMessage
            : 'Please select a project from the Project Analysis menu.'}
        </p>
      </div>
    </>
  );
};

EmptyProjectPage.propTypes = {
  className: PropTypes.string,
  customMessage: PropTypes.string,
};

export default EmptyProjectPage;
