import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './login/Login';
import HomePage from './home/Home';
import ProtectedRoute from './common/ProtectedRoute';

import QA from './qa/QA';
import Jobs from './jobs/Jobs';
import Users from './users/Users';
import Mapping from './mapping/Mapping';
import { AddUser } from './userManagement/AddUser';
import QADetails from './qaDetails/QADetails';
import Statement from './statement/Statement';
import Publisher from './publisher/Publisher';
import DataHints from './dataHints/DataHints';
import EditUser from './userManagement/EditUser';
import Algorithms from './algorithms/Algorithms';
import AddDataHints from './dataHints/AddDataHints';
import DataChooser from './dataChooser/DataChooser';
import RuleLibrary from './ruleLibrary/RuleLibrary';
import EditDataHints from './dataHints/EditDataHints';
import PageNotFound from './common/emptypage/PageNotFound';
import UserManagement from './userManagement/UserManagement';
import ClientDashboard from './clientDashboard/ClientDashboard';
import ProjectAnalysis from './projectAnalysis/ProjectAnalysis';
import InsuranceMapping from './insuranceMapping/InsuranceMapping';
import ValidationChecker from './validationChecker/ValidationChecker';
import TransactionMapping from './transactionMapping/TransactionMapping';
import AccountInformation from './accountInformation/AccountInformation';
import DataHintsRuleLibrary from './dataHintsRuleLibrary/dataHintsRuleLibrary';
import AccountInformationWithLink from './accountInformation/AccountInformationWithLink';
import AccountQueue from './accountQueue/AccountQueue';

import * as routes from 'constants/routes';
import Layout from './common/layout/Layout';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Routes that don't need layout */}
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path="*" element={<PageNotFound />} />
        <Route
          path={routes.USERS}
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />

        {/* 
          Routes that need a layout 
        
          The nested Route setup allows all child routes
          (like USERS, INSURANCE_MAPPING, etc.) to share the same Layout and
          ProtectedRoute. The Layout component is rendered only once, wrapping all
          these routes, while the actual content changes based on the specific
          route.
        */}
        <Route
          element={
            <Layout>
              <ProtectedRoute />
            </Layout>
          }
        >
          <Route
            path={routes.INSURANCE_MAPPING}
            element={<InsuranceMapping />}
          />
          <Route
            path={routes.TRANSACTION_MAPPING}
            element={<TransactionMapping />}
          />
          <Route path={routes.CLIENT_DASHBOARD} element={<ClientDashboard />} />
          <Route path={routes.ALGORITHMS} element={<Algorithms />} />
          <Route
            path={routes.ACCOUNT_INFORMATION}
            element={<AccountInformation />}
          />
          <Route
            path={routes.ACCOUNT_INFORMATION_WITH_ACCOUNT_NO}
            element={<AccountInformationWithLink />}
          />
          <Route path={routes.JOBS} element={<Jobs />} />
          <Route path={routes.USER_MANAGEMENT} element={<UserManagement />} />
          <Route path={routes.ADD_USER} element={<AddUser />} />
          <Route path={routes.EDIT_USER} element={<EditUser />} />
          <Route path={routes.PROJECT_ANALYSIS} element={<ProjectAnalysis />} />
          <Route path={routes.DATA_CHOOSER} element={<DataChooser />} />
          <Route
            path={routes.VALIDATION_CHECKER}
            element={<ValidationChecker />}
          />
          <Route path={routes.MAPPING} element={<Mapping />} />
          <Route path={routes.RULE_LIBRARY} element={<RuleLibrary />} />
          <Route path={routes.QA} element={<QA />} />
          <Route path={routes.QA_DETAILS} element={<QADetails />} />
          <Route path={routes.PUBLISHER} element={<Publisher />} />
          <Route path={routes.STATEMENT} element={<Statement />} />
          <Route path={routes.HOME} element={<HomePage />} />

          <Route path={routes.DATA_HINTS} element={<DataHints />} />
          <Route path={routes.ADD_DATA_HINT} element={<AddDataHints />} />
          <Route path={routes.EDIT_DATA_HINT} element={<EditDataHints />} />
          <Route
            path={routes.DATA_HINT_RULE_LIBRARY}
            element={<DataHintsRuleLibrary />}
          />

          <Route path={routes.ACCOUNT_QUEUE} element={<AccountQueue />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
