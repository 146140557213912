import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import QADeleteModal from './QADeleteModal';
import Icon from 'components/common/icons/Icons';
import Toast from 'components/common/toast/Toast';
import Modal from 'components/common/modal/Modal';
import Table from 'components/common/table/Table';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyProjectPage from 'components/common/emptypage/EmptyProjectPage';

import {
  RULE_NAME,
  fieldsToCheck,
  QAColumnConfig,
  aggregateColumnConfig,
  PROJECT_RULE_BASE_ID,
} from './columnConfigs';

import { qaTableConfig, aggregateTableConfig } from './tableConfigs';

import {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
  updateSelectedProjectRuleBaseId,
} from 'actions/clientAction';

import {
  ERROR,
  JOB_QA,
  ANALYSING,
  JOB_COMPLETED,
  JOB_SUBMITTED,
  NO_JOB_STARTED,
} from 'constants/jobResponses';
import {
  PROJECT_ANALYSIS,
  PUBLISHER,
  JOBS,
  QA_DETAILS,
} from 'constants/routes';
import * as projectStatuses from 'constants/projectStatuses';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import * as qaServices from 'services/qa';
import { getJobStatus } from 'services/jobs';
import { updateProject } from 'services/projectAnalysis';

import { formatDateWithTime } from 'utils/common/formatter';
import ContinueButton from 'components/common/continueButton/continueButton';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList, selectedProject } = state;

  return { selectedClientId, clientList, selectedProject };
};

class QA extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      jobStatus: '',
      hasError: false,
      errorMessage: '',
      hasNewData: false,
      selectedRuleName: '',
      subTotal: {
        exclusive_balance: null,
        exclusive_hits: null,
        total_balance: null,
        total_hits: null,
      },
      navigateTo: '',
      isLoading: false,
      aggregatedData: [],
      isShowingModal: false,
      isDeleteTriggered: false,
      selectedProjectRuleBaseId: '',
      isTrashAndRerunTriggered: false,
    };
  }

  async componentDidMount() {
    await this.getTableData();
  }

  getTableData = async () => {
    const { selectedProject } = this.props;
    if (!selectedProject.projectId) {
      return;
    }

    try {
      const jobStatusResponse = await getJobStatus(selectedProject.projectId);
      const statusData = jobStatusResponse[JOB_QA.toLowerCase()];

      if (!statusData) {
        this.setState({
          jobStatus: NO_JOB_STARTED,
        });

        return;
      }

      this.setState({
        jobStatus: statusData.job_status,
      });

      if (statusData.job_status === JOB_COMPLETED) {
        this.setState({
          isLoading: true,
        });
        const response = await qaServices.getQAData(selectedProject.projectId);

        this.setState({
          data: response.list,
          subTotal: response.subTotal,
          aggregatedData: response.aggregates_by_type,
          isLoading: false,
        });
      }
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      rowData: {},
      navigateTo: PROJECT_ANALYSIS,
    });

    this.props.updateClientID(clientId);
    this.props.clearSelectedProject();
  };

  navigateTo = (route) => {
    this.setState({
      navigateTo: route,
    });
  };

  handleNextStep = async () => {
    const { selectedClientId, selectedProject, updateSelectedProject } =
      this.props;

    if (selectedProject.projectId) {
      try {
        const data = await updateProject(
          selectedClientId,
          selectedProject.projectId,
          {
            status: projectStatuses.PUBLISHED.toUpperCase(),
          }
        );

        updateSelectedProject({
          ...selectedProject,
          status: projectStatuses.PUBLISHED.toUpperCase(),
          lastUpdateDate: formatDateWithTime(data.update_date),
        });

        this.setState({
          navigateTo: PUBLISHER,
        });
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DEFAULT_ERROR_MESSAGE;

        this.setState({
          errorMessage,
          hasError: true,
          isLoading: false,
        });
      }
    }
  };

  handleOpenModal = () => {
    this.setState({
      hasNewData: false,
      isShowingModal: true,
    });
  };

  handleCancelAction = () => {
    this.setState({
      hasNewData: false,
      isShowingModal: false,
    });
  };

  handleConfirmationAction = async () => {
    const { selectedProjectRuleBaseId } = this.state;

    this.setState({
      isDeleteTriggered: true,
    });

    try {
      await qaServices.deleteSingleRule(selectedProjectRuleBaseId);
      await this.getTableData();

      this.setState({
        hasNewData: true,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        hasNewData: false,
        isShowingModal: false,
        isDeleteTriggered: false,
      });
    }

    this.setState({
      isShowingModal: false,
      isDeleteTriggered: false,
    });
  };

  handleTrashAndRerun = async () => {
    const { selectedClientId, selectedProject } = this.props;
    if (!selectedProject.projectId) {
      return;
    }

    this.setState({
      hasNewData: false,
      isTrashAndRerunTriggered: true,
    });

    try {
      await qaServices.trashAndRunAnalysis(
        selectedClientId,
        selectedProject.projectId
      );

      this.setState({
        hasNewData: true,
        jobStatus: JOB_SUBMITTED,
        isTrashAndRerunTriggered: false,
      });
    } catch (error) {
      this.setState({
        hasNewData: false,
        isTrashAndRerunTriggered: false,
      });
    }
  };

  handleRerunButtonClick = async (_, cell) => {
    const { selectedClientId, selectedProject } = this.props;
    const row = cell.getRow();

    if (fieldsToCheck.findIndex((field) => !row.getData()[field]) === -1) {
      return;
    }

    try {
      await qaServices.runSingleRule(
        selectedClientId,
        selectedProject.projectId,
        row.getData()[PROJECT_RULE_BASE_ID]
      );

      this.setState({
        jobStatus: JOB_SUBMITTED,
      });

      await this.getTableData();
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  handleTrashButtonClick = (_, cell) => {
    const rowData = cell.getRow().getData();

    this.setState({
      isShowingModal: true,
      ruleName: rowData[RULE_NAME],
      selectedProjectRuleBaseId: rowData[PROJECT_RULE_BASE_ID],
    });
  };

  handleViewDetailButtonClick = (_, cell) => {
    this.props.updateSelectedProjectRuleBaseId(
      cell.getRow().getData()[PROJECT_RULE_BASE_ID]
    );
    window.open(QA_DETAILS, '_blank');
  };

  handleBadgeClick = () => {
    window.open(JOBS, '_blank');
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      data,
      hasError,
      ruleName,
      jobStatus,
      isLoading,
      navigateTo,
      hasNewData,
      errorMessage,
      aggregatedData,
      isShowingModal,
      isDeleteTriggered,
      isTrashAndRerunTriggered,
    } = this.state;
    const { clientList, selectedClientId, selectedProject } = this.props;
    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });
    const projectDisplayName = selectedProject.projectName || '';

    const badgeStatus =
      projectStatuses.JOB_STATUS_MAP[jobStatus] ||
      selectedProject?.status ||
      '';

    const badgeClass = classNames({
      badge: true,
      error: badgeStatus === ERROR,
      highlight: badgeStatus === ANALYSING,
    });

    return navigateTo ? (
      <Navigate to={navigateTo} replace={true} />
    ) : (
      <>
        <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
          <div className="profile mb-3x ml-auto">
            <Dropdown
              label={displayName}
              dropdownItems={dropdownItems}
              onClick={this.handleSelectClient}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-1x">
            <h1>
              QA {projectDisplayName && <span>({projectDisplayName})</span>}
            </h1>
            {badgeStatus && (
              <div
                role={'button'}
                className={`${badgeClass} badge-lg ml-5x mt-3x`}
                onClick={this.handleBadgeClick}
              >
                {badgeStatus}
              </div>
            )}

            <button
              className="btn-has-icon ml-5x mr-auto mt-3x"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <Icon
                icon="refresh"
                size={12}
                color="#546071"
                className="mr-2x"
              />
              Refresh
            </button>
            <div className="color-grey--60 mt-4x">
              Last Updated : {selectedProject.lastUpdateDate}
            </div>
          </div>
        </div>

        {isLoading && <Loader isFullScreen={true} />}
        {selectedProject.projectId ? (
          <div
            className="d-flex flex-direction-column gap-3x"
            style={{ height: 'calc(100vh - 132px)' }}
          >
            <div className="table tabulator-calc-table has-box-shadow">
              <Table
                data={data}
                pagination={false}
                hasNewData={hasNewData}
                tableConfig={qaTableConfig}
                className="has-box-shadow"
                columnConfig={QAColumnConfig}
                hasTotalsRow={true}
                handleRerunButtonClick={this.handleRerunButtonClick}
                handleTrashButtonClick={this.handleTrashButtonClick}
                handleViewDetailButtonClick={this.handleViewDetailButtonClick}
              />
            </div>

            <div className="d-flex justify-content-end gap-4x">
              {!jobStatus || jobStatus === NO_JOB_STARTED ? (
                <button
                  className="btn btn-primary has-loader"
                  onClick={this.handleTrashAndRerun}
                >
                  Run Engine
                  {isTrashAndRerunTriggered && <span className="spinner" />}
                </button>
              ) : (
                <button
                  className="btn-outlined btn-danger--outlined has-loader"
                  onClick={this.handleTrashAndRerun}
                >
                  Trash and Re-run
                  {isTrashAndRerunTriggered && <span className="spinner" />}
                </button>
              )}
            </div>

            {aggregatedData.length > 0 && (
              <>
                <div className="white__box pt-2x pb-8x px-10x">
                  <h2 className="color-primary--base">Aggregates</h2>
                  <p>The given data is the aggregates based on the rule name</p>

                  <div className="row mt-6x">
                    <div className="col-7">
                      <div className="bg-grey--10 p-5x">
                        <Table
                          pagination={false}
                          data={aggregatedData}
                          handleViewDetailButton={this.handlee}
                          tableConfig={aggregateTableConfig}
                          columnConfig={aggregateColumnConfig}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {isShowingModal && (
              <Modal
                onOpen={this.handleOpenModal}
                onClose={this.handleCancelAction}
              >
                <QADeleteModal
                  ruleName={ruleName}
                  onClose={this.handleCancelAction}
                  onDelete={this.handleConfirmationAction}
                  isDeleting={isDeleteTriggered}
                />
              </Modal>
            )}

            <ContinueButton onClick={this.handleNextStep} />
          </div>
        ) : (
          <EmptyProjectPage pageName="QA" />
        )}

        {hasError && (
          <Toast
            title={errorMessage}
            hasError={hasError}
            handleClose={this.resetError}
          />
        )}
      </>
    );
  }
}

QA.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedProject: PropTypes.object,
  clearSelectedProject: PropTypes.func,
  updateSelectedProject: PropTypes.func,
  updateSelectedProjectRuleBaseId: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
  updateSelectedProjectRuleBaseId,
})(QA);
